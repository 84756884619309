////////// Works!! Slow with very many artefacts though
import React, { useState, useEffect, useRef, useMemo } from "react";
import ExploreArtefact from "./ExploreArtefact";
import "../css/ArtefactsList.css";

function ExploreArtefactsList({
  artefacts,
  setArtefacts,
  setIsLowConnection,
  isLowConnection,
  ...props
}) {
  const cellSize = 20; // This can be changed or passed as a prop
  const pannableContainerRef = useRef(null);

  // const [isLowConnection, setIsLowConnection] = useState(false);

  // Central box is calculated only once and not dependent on props to avoid recalculating.
  const centralBox = useMemo(() => {
    const calculatedBox = {
      x: 620 + props.pannableBoxWidth / 2 - 1280 / 2,
      y: 350 + props.pannableBoxHeight / 2 - 720 / 2,
      width: 1580,
      height: 1020,
    };
    return calculatedBox;
  }, []); // Removed dependencies here to ensure it's only calculated once

  // Ref to track if data has already been fetched
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (hasFetchedData.current) return;
    hasFetchedData.current = true;

    const fetchAndPositionArtefacts = async () => {
      // Set a timer to trigger the low connection indicator if fetching takes too long
      const lowConnectionTimer = setTimeout(() => {
        console.log("Setting low connection to true (timeout)");
        setIsLowConnection(true);
      }, 5000);

      try {
        const response = await fetch("/api/explore/artefacts");
        const data = await response.json();

        // const randomisedData = [...data].sort(() => Math.random() - 0.5);
        // const MAX_ARTEFACTS = 97;
        const MAX_ARTEFACTS = 70;

        const randomisedData = [...data]
          .sort(() => Math.random() - 0.5)
          .slice(0, MAX_ARTEFACTS);

        // const positionParams = {
        //   centerBox: centralBox,
        //   startAngle: 0, // or any desired start angle
        //   baseRadius: 450,
        //   layerIncrement: 450,
        //   spacingFactor: 8.5,
        //   avgDimension: 50,
        // };
        const positionParams = {
          centerBox: centralBox,
          startAngle: 0, // or any desired start angle
          baseRadius: 550,
          layerIncrement: 600,
          spacingFactor: 10,
          avgDimension: 50,
        };

        // Calculate positions for all artefacts
        const artefactsWithPositions = randomisedData.map((artefact, index) => {
          const position = getPositionForIndex(index, positionParams);
          return {
            ...artefact,
            coordx: position.x,
            coordy: position.y,
            // content: null, // Initialize content as null or a loading indicator
            content: artefact.content_type === "text" ? artefact.content : null,
          };
        });

        console.log("artefactsWithPositions", artefactsWithPositions);

        // Set artefacts with positions and placeholder content
        setArtefacts(artefactsWithPositions);

        // Fetch content for each artefact
        artefactsWithPositions.forEach((artefact) => {
          console.log("CHINCHIN2 artefact", artefact);
          fetchArtefactContent(artefact)
            .then((artefactWithContent) => {
              // Update the artefact's content in the state
              setArtefacts((prevArtefacts) =>
                prevArtefacts.map((a) =>
                  a.artefact_id === artefactWithContent.artefact_id
                    ? { ...a, content: artefactWithContent.content }
                    : a
                )
              );
            })
            .catch((error) => {
              console.error("Error fetching artefact content:", error);
            });
        });

        // Clear the low connection timer after initiating all fetches
        clearTimeout(lowConnectionTimer);
        setIsLowConnection(false);
        console.log("Setting low connection to false (fetch successful)");
      } catch (error) {
        console.error("Failed to fetch artefacts:", error);
      }
    };

    fetchAndPositionArtefacts();
  }, []);

  const fetchArtefactContent = async (
    artefact,
    retries = 120,
    delay = 1000
  ) => {
    let attempts = 0;
    let hasShownLowConnection = false;

    while (attempts < retries) {
      try {
        const response = await fetch(
          `/api/user/${artefact.ownerUsername}/artefact/${artefact.artefact_id}/content`
        );

        if (
          artefact.content_type === "image" ||
          artefact.content_type === "pdf"
        ) {
          const blob = await response.blob();
          const contentUrl = URL.createObjectURL(blob);
          if (attempts > 0) {
            setIsLowConnection(false); // Reset once successful
          }
          return { ...artefact, content: contentUrl };
        } else if (artefact.content_type === "website") {
          const data = await response.json();

          if (data.error) {
            console.log(`Error fetching website artefact: ${data.error}`);
            return {
              ...artefact,
              content: {
                title: "",
                ogImage: "",
                images: [],
                favicon: "",
                url: data.url,
              },
            };
          }

          if (data.hasOwnProperty("data") && typeof data.data === "string") {
            return { ...artefact, content: data.data };
          }
          // else {
          //   return { ...artefact, content: data };
          // }
          else {
            return {
              ...artefact,
              content: {
                title: data.title || "",
                ogImage: data.ogImage || "",
                images: data.images || [],
                favicon: data.favicon || "",
                url: data.url || "",
              },
            };
          }
        } else if (artefact.content_type === "text") {
          const data = await response.json();
          console.log("CHINCHIN1 data", data);
          return { ...artefact, content: data.data };
        } else {
          return artefact;
        }
      } catch (error) {
        attempts++;
        console.error(
          `Error fetching artefact content, attempt ${attempts}:`,
          error
        );

        // Show the low connection indicator after the first failed attempt
        if (attempts === 1 && !hasShownLowConnection) {
          setIsLowConnection(true);
          hasShownLowConnection = true;
        }

        if (attempts >= retries) {
          console.error("Max retries reached. Skipping this artefact.");
          return artefact;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  const getPositionForIndex = (index, params) => {
    const {
      centerBox,
      startAngle,
      baseRadius,
      layerIncrement,
      spacingFactor,
      avgDimension,
    } = params;

    let totalArtefactsAssigned = 0;
    let layer = 0;

    while (true) {
      let radius = baseRadius + layer * layerIncrement;
      let circumference = 2 * Math.PI * radius;
      let maxArtefactsPerLayer = Math.max(
        Math.floor(circumference / (avgDimension * spacingFactor)),
        1
      );

      if (index < totalArtefactsAssigned + maxArtefactsPerLayer) {
        // The artefact is in this layer
        let positionInLayer = index - totalArtefactsAssigned;
        let angleIncrement = 360 / maxArtefactsPerLayer;
        let angle = startAngle + angleIncrement * positionInLayer;

        let x = centerBox.x + Math.cos(degToRad(angle)) * radius;
        let y = centerBox.y + Math.sin(degToRad(angle)) * radius;

        return { x, y };
      } else {
        totalArtefactsAssigned += maxArtefactsPerLayer;
        layer += 1;
      }
    }
  };

  const degToRad = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  // function ConnectionIndicator() {
  //   return (
  //     <div
  //       className="low-connection"
  //       style={{
  //         position: "absolute",
  //         display: "flex",
  //         alignItems: "center",
  //         color: "orange",
  //         marginLeft: "15px",
  //         marginTop: "15px",
  //         zIndex: "1000",
  //       }}
  //     >
  //       <div
  //         style={{
  //           width: "7px",
  //           height: "7px",
  //           borderRadius: "50%",
  //           backgroundColor: "orange",
  //           marginRight: "3px",
  //           marginTop: "-1px",
  //         }}
  //       ></div>
  //       <span>Low connection</span>
  //     </div>
  //   );
  // }

  const listArtefacts = () => {
    return artefacts.map((art) => {
      if (art.content_type === "dummy") {
        // Render a hidden element for the dummy artefact
        return (
          <div
            key={art.artefact_id}
            className="dummy-box"
            style={{
              position: "absolute",
              left: art.coordx,
              top: art.coordy,
              width: "200px",
              height: "200px",
              visibility: "hidden", // Makes the element invisible but still in the layout
              pointerEvents: "none",
            }}
          ></div>
        );
      } else {
        // Render the actual artefact
        return (
          <ExploreArtefact
            key={art.artefact_id}
            art={art}
            cellSize={cellSize}
            attemptingToCloseMenu={props.attemptingToCloseMenu}
            setAttemptingToCloseMenu={props.setAttemptingToCloseMenu}
            imageDimensions={props.imageDimensions}
            setZoomedInFlag={props.setZoomedInFlag}
            setImageDimensions={props.setImageDimensions}
            isPublicView={props.isPublicView}
            onArtefactClick={props.handleArtefactClick}
            customX={art.coordx}
            customY={art.coordy}
          />
        );
      }
    });
  };

  return (
    <div ref={pannableContainerRef}>
      {/* {isLowConnection && <ConnectionIndicator />} */}
      {listArtefacts()}
    </div>
  );
}

export default ExploreArtefactsList;
