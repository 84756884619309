import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import {
  BsFillHouseFill,
  BsPersonCircle,
  BsFillGrid3X3GapFill,
} from "react-icons/bs";
import { IoIosRefresh } from "react-icons/io";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "../css/NavBar.css";
import SettingsModal from "./Modals/SettingsModal";
import FriendsModal from "./Modals/FriendsModal";

function NavBar_2(props) {
  const [activeModal, setActiveModal] = useState(null);
  const [hasNotifications, setHasNotifications] = useState(false);

  const handleCloseModal = () => {
    setActiveModal(null);
  };

  const handleFilterChange = (newFilter) => {
    props.setFilter(newFilter);
  };

  const handleRefresh = () => {
    window.location.reload(); // Trigger a full page refresh
  };

  const navigate = useNavigate();

  // const logoutClick = (e) => {
  //   e.preventDefault();
  //   fetch("/api/signout", {
  //     method: "POST",
  //     credentials: "same-origin",
  //   }).then((response) => {
  //     if (response.status === 200) {
  //       props.setLoggedIn(false);
  //       props.setLoggedInUsername("");
  //       props.setOg_username("");
  //       navigate("/");
  //     } else if (response.status === 400) {
  //       props.setLoggedIn(false);
  //       props.setLoggedInUsername("");
  //       props.setOg_username("");
  //       navigate("/");
  //     } else if (response.status === 500) {
  //       throw new Error("Signout response was not ok");
  //     }
  //   });
  // };

  const logoutClick = async (e) => {
    setActiveModal(null);
    e.preventDefault();

    try {
      const response = await fetch("/api/signout", {
        method: "POST",
        credentials: "same-origin",
      });

      if (response.status === 200) {
        console.log("response.status", response.status);

        // Successfully signed out
        props.setLoggedIn(false);
        props.setLoggedInUsername("");
        props.setOg_username("");
        navigate("/");
      } else if (response.status === 400) {
        console.log("response.status", response.status);

        // Bad request: handle this case differently
        console.error("Bad request during signout. Please try again.");
        // Optionally, show an error message to the user instead of logging them out
      } else if (response.status === 500) {
        console.log("response.status", response.status);

        // Internal Server Error
        throw new Error("Signout response was not ok");
      }
    } catch (error) {
      // Handle any other errors (e.g., network issues)
      console.error("An error occurred during signout:", error);
    }
  };

  const handleRequestsNotification = (hasRequests) => {
    setHasNotifications(hasRequests);
  };

  useEffect(() => {
    // Function to check if any modal is visible in the DOM
    const isModalOpen = () => {
      return !!document.querySelector(".modal.show");
    };

    // Function to handle the Enter key press
    const handleEnterPress = (event) => {
      // Check if the pressed key is Enter (keyCode 13 or event.key === "Enter")
      if (event.key === "Enter") {
        event.preventDefault();
        // setActiveModal(null);
        // Ensure it's not active in form fields or text inputs and no modal is visible
        const activeElement = document.activeElement;
        if (
          activeElement.tagName !== "INPUT" &&
          activeElement.tagName !== "TEXTAREA" &&
          activeElement.getAttribute("contenteditable") !== "true" &&
          !isModalOpen() // Check if no modal is currently visible in the DOM
        ) {
          // Trigger the screen centering function if no modal is open
          if (typeof props.centerScreenHandler === "function") {
            props.centerScreenHandler(); // Call the screen centering function
          }
        }
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleEnterPress);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleEnterPress);
    };
  }, [props.centerScreenHandler]);

  useEffect(() => {
    // Function to handle keydown events
    const handleKeyDown = (event) => {
      // Check if the pressed key is the Escape key
      if (event.key === "Escape" || event.key === "AltGraph") {
        // Close the modal by setting the activeModal to null
        setActiveModal(null);
      }
    };

    // Attach the keydown event listener to the window
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const keysPressed = new Set(); // Using Set to store the currently pressed keys

    const handleKeyDown = (event) => {
      // Add the current key to the set and check for Caps Lock separately
      if (event.getModifierState("CapsLock")) {
        keysPressed.add("capslock");
      }
      keysPressed.add(event.key.toLowerCase());

      // Check if all desired keys are pressed simultaneously
      const requiredKeys = new Set(["capslock", "b", "l", "e", "y", "a"]);
      const isAllKeysPressed = [...requiredKeys].every((key) =>
        keysPressed.has(key)
      );

      if (isAllKeysPressed) {
        alert("Hey there, hope you're enjoying Showcase - Benjamin Belay");
        keysPressed.clear(); // Clear the set after the alert to prevent repeated alerts
      }
    };

    const handleKeyUp = (event) => {
      // Remove the released key from the set and check for Caps Lock separately
      keysPressed.delete(event.key.toLowerCase());

      // Handle the Caps Lock key separately, as it doesn't behave like other keys
      if (!event.getModifierState("CapsLock")) {
        keysPressed.delete("capslock");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <div
      className="navbar-container"
      style={{ height: "0vh", marginTop: "44px" }}
    >
      <Navbar
        className="navbar-sub-container"
        style={{
          alignItems: "center",
          position: "fixed",
          zIndex: 1,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Nav
          className="nav-whole"
          style={{
            marginTop: "0px",
            marginBottom: "2px",
            height: "47px",
            padding: "2px 5px",
            borderRadius: "10px",
            pointerEvents: "auto",
            userSelect: "none",
          }}
        >
          <div className="spacing-container-6" style={{ width: "1.2vw" }}></div>

          <div className="plus-button-icon" style={{ marginTop: "0px" }}>
            <Button
              variant="none"
              disabled={props.isPublicView}
              style={{
                height: "45px",
                width: "57px",
                borderRadius: "12%",
                // backgroundColor: "#E4E4E4",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                fontSize: "20px",
                marginTop: "-11px",
                border: "none",
                color: "#CFD2CF",
                cursor: "pointer",
                textDecoration: "none",
                userSelect: "none",
                outline: "none",
                boxShadow: "none",
              }}
              // onClick={() => alert("Feature not available yet :)")}
              onClick={handleRefresh}
            >
              {/* <BsFillGrid3X3GapFill size={30} color="#9D9D9D" /> */}

              <IoIosRefresh size={30} color="#c9c9c9" />
              <p
                style={{
                  marginTop: "-3px",
                  marginLeft: "-8px",
                  color: "#9D9D9D",
                  fontSize: "15px",
                }}
                className="reload-text"
              >
                Reload
              </p>
            </Button>
          </div>

          <div className="spacing-container-1" style={{ width: "4vw" }}></div>
          <div>
            <Nav.Link
              href="/explore"
              className="explore-group"
              onClick={() => setActiveModal("explore")}
              style={{ textAlign: "center", marginTop: "28px" }}
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
            >
              <img
                src={process.env.PUBLIC_URL + "/exploreIcon2.svg"}
                style={{ width: "55%", height: "55%" }}
              />{" "}
              <br />{" "}
              <p
                className="explore-text"
                style={{
                  marginTop: "-3px",
                  color: "#9D9D9D",
                  fontSize: "15px",
                }}
              >
                Explore
              </p>
            </Nav.Link>
          </div>

          <div className="spacing-container-2" style={{ width: "4vw" }}></div>
          <div
            className="plus-button-icon"
            style={{ marginTop: "0.33vw", outline: "none" }}
          >
            <NavLink
              to="/mypage"
              className="nav-link"
              style={{ userSelect: "none", outline: "none" }}
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
            >
              <Button
                className="add-icon"
                variant="none"
                disabled={props.isPublicView}
                draggable="false"
                style={{
                  marginTop: "-20px",
                  height: "45px",
                  width: "51px",
                  // borderRadius: "12%",
                  // backgroundColor: "#E4E4E4",
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  // fontSize: "20px",
                  // border: "none",
                  // color: "#CFD2CF",
                  cursor: "pointer",
                  textDecoration: "none",
                  userSelect: "none",
                  outline: "none",
                  boxShadow: "none",
                  marginLeft: "-14px",
                }}
              >
                <BsFillHouseFill size={48} color="#c9c9c9" />
                <p
                  style={{
                    marginTop: "-4px",
                    marginLeft: "-23px",
                    color: "#9D9D9D",
                    fontSize: "15px",
                    textDecoration: "none",
                    userSelect: "none",
                  }}
                  className="explore-text"
                >
                  {/* Home */}
                </p>
              </Button>
            </NavLink>
          </div>

          <div className="spacing-container-3" style={{ width: "4vw" }}></div>
          <div>
            <Nav.Link
              href=""
              className="friends-group"
              style={{
                textAlign: "center",
                marginTop: "29px",
                position: "relative",
              }}
              onClick={() => setActiveModal("friends")}
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
            >
              {hasNotifications && (
                <span
                  className="notification-dot"
                  style={{
                    position: "absolute",
                    top: "6px",
                    // right: "5px",
                    right: "22%",
                    height: "8px",
                    width: "8px",
                    backgroundColor: "red",
                    // backgroundColor: "#FF8225",
                    borderRadius: "50%",
                  }}
                ></span>
              )}
              <img
                src={process.env.PUBLIC_URL + "/friends-icon2.svg"}
                style={{
                  width: "65%",
                  height: "65%",
                }}
              />{" "}
              <br />{" "}
              <p
                className="friends-text"
                style={{
                  marginTop: "-6px",
                  color: "#9D9D9D",
                  fontSize: "15px",
                }}
              >
                Friends
              </p>
            </Nav.Link>
          </div>

          <div className="spacing-container-4" style={{ width: "4vw" }}></div>
          <div>
            <Button
              variant="link"
              className="profile-button"
              onClick={() => setActiveModal("profile")}
              style={{
                fontSize: "30px",
                color: "#CFD2CF",
                textDecoration: "none",
                marginTop: "-3px",
              }}
            >
              <BsPersonCircle />
            </Button>
          </div>
          <div className="spacing-container-4" style={{ width: "0.5vw" }}></div>
        </Nav>

        <div
          className="center-button-container"
          style={{
            position: "absolute",
            left: "107%",
            transform: "translateX(-50%)",
            top: "16px",
            // pointerEvents: "auto",
            pointerEvents: props.isScreenCentered ? "none" : "auto",
            opacity: props.isScreenCentered ? 0 : 1,
            transition: "opacity 0.5s ease-in-out",
            zIndex: 2,
          }}
        >
          <button
            style={{
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
              outline: "none",
            }}
            onClick={props.centerScreenHandler}
          >
            <MdOutlineFilterCenterFocus size={30} color="#9D9D9D" />
          </button>
        </div>
      </Navbar>

      <SettingsModal
        show={activeModal === "profile"}
        handleClose={handleCloseModal}
        logoutClick={logoutClick}
        loggedInUsername={props.loggedInUsername}
        setLoggedInUsername={props.setLoggedInUsername}
        og_username={props.og_username}
        setOg_username={props.setOg_username}
        loggedInFullname={props.loggedInFullname}
        setLoggedInFullname={props.setLoggedInFullname}
      />

      <FriendsModal
        show={activeModal === "friends"}
        handleClose={handleCloseModal}
        loggedInUsername={props.loggedInUsername}
        onRequestsNotification={handleRequestsNotification}
      />
    </div>
  );
}

export default NavBar_2;
