import React, { useState, useEffect, useRef, useCallback } from "react";
import ContextMenuArtefacts from "./ContextMenus/ContextMenuArtefacts";
import Artefact from "./Artefact";
import "../css/ArtefactsList.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { deleteArtefact } from "../api/deleteArtefact";
import { updateArtefactName } from "../api/updateArtefactName";

function ArtefactsList(props) {
  const artefacts = props.artefacts;

  const cellSize = 20; // This can be changed or passed as a prop

  const [attemptingToCloseMenu, setAttemptingToCloseMenu] = useState(false);

  const [contextMenuOpened, setContextMenuOpened] = useState(false); // Track context menu state

  const pannableContainerRef = useRef(null);

  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
    artefact_id: null,
  });

  // const [editingArtefactId, setEditingArtefactId] = useState(null);

  const handleEditArtefact = (artefactId) => {
    props.setEditingArtefactId(artefactId);
  };

  // const editArtefactName = (artefactId, newName, newContent = null) => {
  //   const updatedArtefacts = artefacts.map((artefact) =>
  //     artefact.artefact_id === artefactId
  //       ? {
  //           ...artefact,
  //           artefact_name: newName,
  //           // ...(newContent
  //           //   ? { content: JSON.stringify({ bodyText: newContent }) }
  //           //   : {}),
  //           ...(newContent !== undefined
  //             ? { content: JSON.stringify({ bodyText: newContent }) }
  //             : {}),
  //         }
  //       : artefact
  //   );
  //   props.setArtefacts(updatedArtefacts);
  //   props.setEditingArtefactId(null); // Exit editing mode

  //   let contentType = null;
  //   if (newContent) {
  //     const artefact = updatedArtefacts.find(
  //       (art) => art.artefact_id === artefactId
  //     );
  //     if (artefact && artefact.content_type === "text") {
  //       contentType = "text";
  //     }
  //   }

  //   updateArtefactName(
  //     props.loggedInUsername,
  //     artefactId,
  //     newName,
  //     JSON.stringify({ bodyText: newContent }),
  //     contentType
  //   )
  //     .then((data) => {
  //       console.log("Artefact name updated successfully", data);
  //       // Handle successful update
  //     })
  //     .catch((error) => {
  //       console.error("Error updating artefact name:", error);
  //       // Handle error
  //     });
  // };

  const editArtefactName = (artefactId, newName, newContent) => {
    const updatedArtefacts = artefacts.map((artefact) =>
      artefact.artefact_id === artefactId
        ? {
            ...artefact,
            artefact_name: newName,
            ...(newContent !== undefined
              ? { content: JSON.stringify({ bodyText: newContent }) }
              : {}),
          }
        : artefact
    );
    props.setArtefacts(updatedArtefacts);
    props.setEditingArtefactId(null); // Exit editing mode

    let contentType = null;
    if (newContent !== undefined) {
      const artefact = updatedArtefacts.find(
        (art) => art.artefact_id === artefactId
      );
      if (artefact && artefact.content_type === "text") {
        contentType = "text";
      }
    }

    updateArtefactName(
      props.loggedInUsername,
      artefactId,
      newName,
      JSON.stringify({ bodyText: newContent }),
      contentType
    )
      .then((data) => {
        console.log("Artefact name updated successfully", data);
        // Handle successful update
      })
      .catch((error) => {
        console.error("Error updating artefact name:", error);
        // Handle error
      });
  };

  const handleMoveArtefact = (artefactId) => {
    // Find the artefact by id from the artefacts list
    console.log("Attempting to move artefact with ID:", artefactId);
    console.log("Current artefacts list:", artefacts);
    const artefactToMove = artefacts.find(
      (art) => art.artefact_id === artefactId
    );
    if (!artefactToMove) {
      console.error("Artefact not found");
      return;
    }
    console.log("Artefact to move:", artefactToMove);

    let transformedArtefactData = {
      artefactId: artefactToMove.artefact_id,
      artefactName: artefactToMove.artefact_name,
      type: artefactToMove.content_type, // 'image', 'website', or 'text'
    };

    switch (artefactToMove.content_type) {
      case "image":
        transformedArtefactData.imageURL = artefactToMove.content;
        break;
      case "website":
        transformedArtefactData.websiteURL = artefactToMove.content;
        break;
      case "text":
        try {
          const textContent = JSON.parse(artefactToMove.content);
          transformedArtefactData.heading = textContent.heading;
          transformedArtefactData.bodyText = textContent.bodyText;
        } catch (error) {
          console.error("Error parsing text artefact content:", error);
          return;
        }
        break;
      case "pdf":
        transformedArtefactData.pdfURL = artefactToMove.content;
        break;
      default:
        console.error(
          "Unsupported artefact type:",
          artefactToMove.content_type
        );
        return;
    }

    props.setArtefactData(transformedArtefactData);

    console.log("tutu", transformedArtefactData);

    const remainingArtefacts = artefacts.filter(
      (art) => art.artefact_id !== artefactId
    );

    props.setArtefacts(remainingArtefacts); // Assuming you have a state setter for artefacts
    console.log("Updated artefacts list:", remainingArtefacts);

    props.handleCreateButtonClick();
    props.setPlaceArtefactsTrigger(true);
  };

  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const [longPressTriggered, setLongPressTriggered] = useState(false);

  const handleTouchStart = (event, artId, isPublic) => {
    setLongPressTriggered(false);
    // Set a timeout to open the context menu after 1.5 seconds
    const timeoutId = setTimeout(() => {
      // setContextMenuOpened(true);
      setLongPressTriggered(true);
      handleRightClick(event, artId, isPublic);
      // setContextMenuOpened(true);
      // setTimeout(() => setContextMenuOpened(true), 100);
    }, 400);

    setLongPressTimeout(timeoutId);
  };

  const handleTouchEnd = (event) => {
    // Check if the context menu was opened and prevent it from being closed immediately
    if (longPressTriggered) {
      event.preventDefault();
      return;
    }

    // Clear the long press timeout if the touch ends before the context menu opens
    clearTimeout(longPressTimeout);
    setLongPressTimeout(null);
  };

  const handleTouchCancel = () => {
    // Clear the long press timeout if the touch is canceled
    clearTimeout(longPressTimeout);
    setLongPressTimeout(null);
  };

  const handleRightClick = (event, artId, isPublic) => {
    if (!props.isOwner) {
      event.preventDefault();
      return;
    }
    event.preventDefault();

    if (longPressTriggered) {
      event.preventDefault(); // Prevent any further event handling after long press
    }

    let x, y;

    const artefact = artefacts.find((art) => art.artefact_id === artId);

    if (event.type === "touchstart" || event.type === "touchend") {
      const touch = event.changedTouches[0];
      x = touch.clientX;
      y = touch.clientY;
    } else if (event.type === "contextmenu") {
      x = event.clientX;
      y = event.clientY;
    } else {
      return;
    }

    const rect = pannableContainerRef.current.getBoundingClientRect();
    // const relativeX = x - rect.left;
    // const relativeY = y - rect.top;

    const relativeX = (x - rect.left) / props.zoomLevel; // props.zoomLevel is the current zoom level
    const relativeY = (y - rect.top) / props.zoomLevel;

    setContextMenuState({
      visible: true,
      x: relativeX,
      y: relativeY,
      artefact_id: artId,
      isPublic: isPublic,
      sizing_choice: artefact.sizing_choice,
    });
    setTimeout(() => {
      document.addEventListener("click", disableClick);
    }, 50);

    // Re-enable clicks after a short delay
    setTimeout(() => {
      document.removeEventListener("click", disableClick);
    }, 1000);
  };

  const disableClick = (event) => {
    event.preventDefault();
  };

  const hideContextMenu = () => {
    setContextMenuState({
      visible: false,
      x: 0,
      y: 0,
      artefact_id: null,
    });
    setContextMenuOpened(false);
  };

  const handleClickOutside = useCallback((event) => {
    const contextMenu = document.querySelector(".context-menu-wrapper");
    if (contextMenu && contextMenu.contains(event.target)) {
      return;
    }
    setAttemptingToCloseMenu(true);
    hideContextMenu();
  }, []);

  useEffect(() => {
    return;
  }, [props.setArtefacts, handleMoveArtefact]);

  // useEffect(() => {
  //   if (contextMenuState.visible) {
  //     // If the context menu is visible, attach the event listener
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     // If the context menu is hidden, remove the event listener
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }
  //   // setAttemptingToCloseMenu(false);

  //   // Cleanup event listener on unmount
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [contextMenuState.visible, handleClickOutside]);

  const handleTouchMove = (event) => {
    // Hide the context menu on touch move
    if (contextMenuState.visible) {
      hideContextMenu();
    }
  };

  useEffect(() => {
    if (contextMenuState.visible) {
      // Attach the touchmove event listener when the context menu is visible
      document.addEventListener("touchmove", handleTouchMove);
      // Attach the mousedown event listener
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove the event listeners when the context menu is hidden
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextMenuState.visible, handleClickOutside]);

  const updateArtefactPublicity = (
    artefact_id,
    newPublicityStatus,
    newCoords
  ) => {
    const updatedArtefacts = artefacts.map((art) =>
      art.artefact_id === artefact_id
        ? { ...art, is_public: newPublicityStatus, ...newCoords }
        : art
    );
    props.setArtefacts(updatedArtefacts);
  };

  const handleDeleteArtefact = async (artefact_id) => {
    try {
      const success = await deleteArtefact(props.loggedInUsername, artefact_id);

      if (success) {
        const updatedArtefacts = artefacts.filter(
          (art) => art.artefact_id !== artefact_id
        );

        props.setArtefacts(updatedArtefacts);

        hideContextMenu();
      } else {
        console.error("Failed to delete artefact via API");
      }
    } catch (error) {
      console.error("Failed to delete artefact: ", error);
    }
  };

  const updateArtefactSize = (artefactId, newSize) => {
    const updatedArtefacts = artefacts.map((artefact) =>
      artefact.artefact_id === artefactId
        ? { ...artefact, sizing_choice: newSize }
        : artefact
    );
    props.setArtefacts(updatedArtefacts);
  };

  const listArtefacts = () => {
    const artefactsJSX = [];
    console.log("artefacts in artefactslist: ", artefacts);
    if (artefacts != null) {
      if (artefacts && Array.isArray(artefacts)) {
        artefacts.forEach((art) => {
          // const { coordx_private: x, coordy_private: y } = art;
          // if (x === undefined || y === undefined) {
          //   return; // skip this artefact if no coordinates
          // }
          const x = props.isPublicView ? art.coordx_public : art.coordx_private;
          const y = props.isPublicView ? art.coordy_public : art.coordy_private;

          console.log("props.isPublicView", props.isPublicView);
          console.log(
            `Rendering berg artefact ${art.artefact_id} at x: ${x}, y: ${y}`
          );

          // Skip artefacts without valid coordinates for the current view
          if (x === undefined || y === undefined || x === null || y === null) {
            return;
          }

          artefactsJSX.push(
            <CSSTransition
              key={art.artefact_id}
              timeout={300}
              classNames="artefact-transition"
            >
              <Artefact
                key={art.artefact_id}
                art={art}
                cellSize={cellSize}
                handleTouchStart={(e) =>
                  handleTouchStart(e, art.artefact_id, art.is_public)
                }
                handleTouchEnd={handleTouchEnd}
                // handleTouchCancel={handleTouchCancel}
                handleRightClick={handleRightClick}
                attemptingToCloseMenu={attemptingToCloseMenu}
                setAttemptingToCloseMenu={setAttemptingToCloseMenu}
                imageDimensions={props.imageDimensions}
                setImageDimensions={props.setImageDimensions}
                isPublicView={props.isPublicView}
                zoomedInFlag={props.zoomedInFlag}
                setZoomedInFlag={props.setZoomedInFlag}
                onArtefactClick={props.handleArtefactClick}
                isEditing={props.editingArtefactId === art.artefact_id}
                editArtefactName={editArtefactName}
                loggedInUsername={props.loggedInUsername}
                isOwner={props.isOwner}
                contextMenuOpened={contextMenuOpened}
                setContextMenuOpened={setContextMenuOpened}
                pannableBoxHeight={props.pannableBoxHeight}
                pannableBoxWidth={props.pannableBoxWidth}
                placingMode={props.placingMode}
              />
            </CSSTransition>
          );
        });
      } else {
        console.error(
          "Expected artefacts to be an array but received:",
          artefacts
        );
      }
    }
    return artefactsJSX;
  };

  return (
    <div ref={pannableContainerRef}>
      {/* <TransitionGroup>{listArtefacts()}</TransitionGroup> */}
      {/* <TransitionGroup>
        {artefacts && listArtefacts().map((i) => i)}
      </TransitionGroup> */}
      {listArtefacts()}

      <ContextMenuArtefacts
        isVisible={contextMenuState.visible}
        x={contextMenuState.x}
        y={contextMenuState.y}
        loggedInUsername={props.loggedInUsername}
        contextMenuStateArtefactId={contextMenuState.artefact_id}
        contextMenuStateSizingChoice={contextMenuState.sizing_choice}
        updateArtefactSize={updateArtefactSize}
        hideContextMenu={hideContextMenu}
        handleDeleteArtefact={handleDeleteArtefact}
        publicityState={contextMenuState.isPublic}
        setArtefacts={props.setArtefacts}
        artefacts={artefacts}
        updateArtefactPublicity={updateArtefactPublicity}
        isPublicView={props.isPublicView}
        onMoveArtefact={handleMoveArtefact}
        onEditArtefact={handleEditArtefact}
        isFetching={props.isFetching}
      />
    </div>
  );
}

export default ArtefactsList;
