import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import VerifyEmail from "./pages/VerifyEmail";
import Home from "./pages/Home";
import CheckIsPageOwn from "./components/CheckIsPageOwn";
import Collection from "./pages/Collection";
import MyPage from "./pages/MyPage";
import OtherUserPageCollection from "./pages/OtherUserPageCollection";
import OtherUserPage from "./pages/OtherUserPage";
import ExplorePage from "./pages/ExplorePage";
import SharelinkView from "./pages/SharelinkView";
import Analytics from "./pages/Analytics";
import TermsConditions from "./pages/Terms-Conditions";
import PrivacyPolicy from "./pages/Privacy-Policy";
import SessionManager from "./api/SessionManager";
import "./css/App.css"; // Include your CSS transitions

export default function App() {
  // const [loggedIn, setLoggedIn] = useState(false);
  const [loggedIn, setLoggedIn] = useState(null);

  const [loggedInUsername, setLoggedInUsername] = useState("");

  const [submittedForm, setSubmittedForm] = useState(false);
  const [placingMode, setPlacingMode] = useState(false);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [placeArtefactsTrigger, setPlaceArtefactsTrigger] = useState(false);
  const [centerScreen, setCenterScreen] = useState(false);
  const [isScreenCentered, setIsScreenCentered] = useState(true);
  const [collectionRefresh, setCollectionRefresh] = useState(false);
  // const [artefactRefresh, setArtefactRefresh] = useState(false);
  const [artefactRefresh, setArtefactRefresh] = useState({
    refresh: false,
    artefactId: null, // or set an initial artefactId if you have one
  });

  const [isFetching, setIsFetching] = useState(false);

  const [sharelinkUsername, setSharelinkUsername] = useState("");

  const [og_username, setOg_username] = useState("");

  const [loggedInFullname, setLoggedInFullname] = useState([]);

  const [collectionData, setCollectionData] = useState({
    collectionName: "",
    collectionId: "",
  });
  const [artefactData, setArtefactData] = useState({
    artefactName: "",
    imageURL: "",
    type: "",
  });
  const [filter, setFilter] = useState("ALL"); // can be 'ALL' or 'PUBLIC_ONLY'

  const [isPublicView, setIsPublicView] = useState(false);

  const [privacySettingsVersion, setPrivacySettingsVersion] = useState(0);

  const handlePrivacySettingsChanged = () => {
    setPrivacySettingsVersion((prev) => prev + 1);
  };

  const centerScreenHandler = () => {
    setCenterScreen(true);
    setIsScreenCentered(true);
  };

  const handleCreateButtonClick = () => {
    setPlacingMode(true);
  };

  useEffect(() => {
    fetch("/api/check_signin")
      .then((response) => response.json())
      .then((j) => {
        setLoggedIn(j.loggedIn);
        setLoggedInUsername(j.username);
        setOg_username(j.username);
        setLoggedInFullname(j.full_name);
      });
  }, []);

  useEffect(() => {
    setIsPublicView(false);

    return;
  }, [loggedIn]);

  console.log("og_username oh yeaaaa", og_username);
  console.log("loggedInFullname oh yeaaaa", loggedInFullname);

  return (
    <BrowserRouter>
      <SessionManager
        setLoggedIn={setLoggedIn}
        setLoggedInUsername={setLoggedInUsername}
      >
        {/* <Routes> */}
        <RoutesWithTransitions>
          <Route
            path="/"
            element={
              <Home
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                setArtefactData={setArtefactData}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                og_username={og_username}
                artefactData={artefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
              />
            }
          />
          {/* This lets you send people Home-1, Home-2 with set examples rather than just sending a page with info */}
          <Route
            path="/home-:id"
            element={
              <Home
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                setArtefactData={setArtefactData}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                og_username={og_username}
                artefactData={artefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
              />
            }
          />
          <Route
            path="/signin"
            element={
              <Signin
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                setLoggedInFullname={setLoggedInFullname}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
              />
            }
          />
          <Route
            path="/signup"
            element={
              <Signup
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                setLoggedInFullname={setLoggedInFullname}
                setOg_username={setOg_username}
              />
            }
          />
          <Route
            path="/verify-signup"
            element={
              <VerifyEmail
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                setLoggedInUsername={setLoggedInUsername}
                setOg_username={setOg_username}
                setLoggedInFullname={setLoggedInFullname}
                // Pass other necessary props
              />
            }
          />
          <Route
            path="/collection/:id"
            element={
              <Collection
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                artefactData={artefactData}
                setArtefactData={setArtefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                og_username={og_username}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
                collectionRefresh={collectionRefresh}
                setCollectionRefresh={setCollectionRefresh}
                artefactRefresh={artefactRefresh}
                setArtefactRefresh={setArtefactRefresh}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
                privacySettingsVersion={privacySettingsVersion}
                handlePrivacySettingsChanged={handlePrivacySettingsChanged}
              />
            }
          />
          <Route
            path="/mypage"
            element={
              <MyPage
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                artefactData={artefactData}
                setArtefactData={setArtefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                og_username={og_username}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
                collectionRefresh={collectionRefresh}
                setCollectionRefresh={setCollectionRefresh}
                artefactRefresh={artefactRefresh}
                setArtefactRefresh={setArtefactRefresh}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
                privacySettingsVersion={privacySettingsVersion}
                handlePrivacySettingsChanged={handlePrivacySettingsChanged}
              />
            }
          />
          <Route path="/artefact/:id" />
          <Route
            path="/user/:urlUsername/collection/:collectionId"
            element={
              <CheckIsPageOwn
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                artefactData={artefactData}
                setArtefactData={setArtefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                og_username={og_username}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
                collectionRefresh={collectionRefresh}
                setCollectionRefresh={setCollectionRefresh}
                artefactRefresh={artefactRefresh}
                setArtefactRefresh={setArtefactRefresh}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
              />
            }
          />
          <Route
            path="/user/:urlUsername"
            element={
              <CheckIsPageOwn
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                artefactData={artefactData}
                setArtefactData={setArtefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                og_username={og_username}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
                collectionRefresh={collectionRefresh}
                setCollectionRefresh={setCollectionRefresh}
                artefactRefresh={artefactRefresh}
                setArtefactRefresh={setArtefactRefresh}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
              />
            }
          />

          <Route
            path="/explore"
            element={
              <ExplorePage
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                artefactData={artefactData}
                setArtefactData={setArtefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                og_username={og_username}
                setOg_username={setOg_username}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
              />
            }
          />
          <Route
            path="/share/:linkId"
            element={
              <SharelinkView
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                setArtefactData={setArtefactData}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                og_username={og_username}
                setOg_username={setOg_username}
                // collectionData={collectionData}
                // setCollectionData={setCollectionData}
                artefactData={artefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
              />
            }
          />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* </Routes> */}
        </RoutesWithTransitions>
      </SessionManager>
    </BrowserRouter>
  );
}

function RoutesWithTransitions({ children }) {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.pathname} classNames="fade" timeout={500}>
        <Routes location={location}>{children}</Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

// // // src/App.js

// // import React, { useEffect } from "react";

// // export default function App() {
// //   useEffect(() => {
// //     // Redirect to the static error_500.html page in the public folder
// //     window.location.href = "/error_500.html";
// //   }, []);

// //   return null; // Render nothing while redirecting
// // }

// src/App.js

// import React from "react";

// export default function App() {
//   return (
//     <>
//       {/* Embedding the CSS styles */}
//       <style>
//         {`
//           /* Global Styles */
//           body {
//             font-family: 'Raleway', sans-serif;
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;
//             align-items: center;
//             height: 100vh;
//             margin: 0;
//             padding: 0;
//             text-align: center;
//             background-color: #f4f4f4;
//             background-image: url('/Gradient2.png');
//             background-size: cover;
//             background-position: 50px center;
//             background-repeat: no-repeat;
//           }

//           .maintenance-container {
//             margin-top: 50px; /* Add some spacing from the top */
//             background-color: #eeeeee; /* Light gray background color */
//             border-radius: 10px; /* Rounded corners */
//             padding: 0px 20px 20px 20px; /* Add some padding inside the container */
//             box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
//             max-width: 80%; /* Ensure it doesn't stretch too wide */
//           }

//           .maintenance-message h1 {
//             font-size: 2.7em;
//             font-weight: 700; /* Bold */
//             color: #333;
//             white-space: no-wrap;
//           }

//           .maintenance-message p {
//             font-size: 1.3em;
//             font-weight: 400; /* Regular */
//             color: #666;
//           }

//           .p-tags {
//           font-size: 15px !important;
//           }

//           .logo-container {
//             position: absolute;
//             bottom: 10px;
//             margin-left: 70px;
//             display: flex;
//             flex-direction: row; /* Align items in a row */
//             align-items: center;
//             justify-content: center;
//           }

//           .logo-container img {
//             max-width: 100%;
//             height: auto;
//           }

//           .logo-icon {
//             margin-right: 10px; /* Add some spacing between the logos */
//             height: 48px !important;
//           }

//           .logo-showcase {
//             height: 70px !important; /* Set specific height for the showcase logo */
//           }

//           /* Responsive Adjustments (Optional) */
//           @media (max-width: 768px) {
//             .maintenance-container {
//               max-width: 70%;
//               margin-left: 90px;
//               width: 500px;
//               padding: 10px;
//               white-space: no-wrap;
//             }

//             .maintenance-message h1 {
//               font-size: 2em;
//             }

//             .maintenance-message p {
//               font-size: 1em;
//               white-space: no-wrap;
//             }

//              .logo-container img {
//             // max-width: 100%;
//             // height: auto;
//             margin-left: 90px;
//           }

//             .logo-icon {
//               height: 40px !important;
//               margin-right: -85px;
//             }

//             .logo-showcase {
//               height: 60px !important;
//             }
//           }
//         `}
//       </style>

//       {/* Maintenance Message Container */}
//       <div className="maintenance-container">
//         <div className="maintenance-message">
//           <h1>Maintenance Break</h1>
//           <p>
//             Sorry, the servers are under maintenance. Please try again soon.
//           </p>
//           <p className="p-tags">
//             Email us for any queries at: contact@showcase-app.co
//           </p>
//         </div>
//       </div>

//       {/* Logo Container at the Bottom */}
//       <div className="logo-container">
//         <img src="/logo_icon.png" alt="Logo Icon" className="logo-icon" />
//         <img
//           src="/logo_showcase.png"
//           alt="Logo Showcase"
//           className="logo-showcase"
//         />
//       </div>
//     </>
//   );
// }
