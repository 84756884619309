import React, { useEffect, useState, useRef } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { FiLink } from "react-icons/fi";
import TreeBreadCrumb from "./TreeBreadCrumb";
import {
  useParams,
  useNavigate,
  useLocation,
  useMatch,
} from "react-router-dom"; // Import useNavigate
import "../css/ProfileBox.css";
import { fetchFollowersCount, fetchFolloweesCount } from "../api/followsApi";
import { updateDescription } from "../api/updateDescription";
import { fetchDescription } from "../api/fetchDescription";
import copy from "copy-to-clipboard";
import { MdOutlineModeEdit } from "react-icons/md";
import FollowButton from "./FollowButton";
import logoIcon from "../logo_icon.png";
import logoShowcase from "../logo_showcase.png";
import { BiSolidLockAlt } from "react-icons/bi";
import { TbWorld } from "react-icons/tb";

export default function ProfileBox({
  loggedInUsername,
  currentCollection,
  isOwner,
  og_username,
  followingButtonExplore,
  sharelinkUsername,
  setSharelinkUsername,
  sharelinkFullname,
  setSharelinkFullname,
  privacySettingsVersion,
  loggedInFullname,
  pannableBoxHeight,
  pannableBoxWidth,
  homePageFlag,
}) {
  const profileBoxTop = pannableBoxHeight / 2; // Center vertically
  const profileBoxLeft = pannableBoxWidth / 2;

  const effectiveUsername = sharelinkUsername || loggedInUsername;

  const [newFullname, setNewFullname] = useState("");

  console.log("currentcol col col", currentCollection);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`/api/user/${effectiveUsername}/details`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin", // Adjust this based on your authentication setup
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        setNewFullname(data.full_name); // Set the full name in the state
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (effectiveUsername) {
      fetchUserDetails();
    }
  }, [effectiveUsername, loggedInFullname]);

  // const effectiveFullname = loggedInFullname || sharelinkFullname;
  const effectiveFullname = newFullname || sharelinkFullname;

  // const effectiveUsername = loggedInUsername || og_username;
  // const effectiveUsername = sharelinkUsername ? og_username : loggedInUsername;

  console.log(
    "Effective Username 3:",
    effectiveUsername,
    loggedInFullname,
    og_username,
    sharelinkUsername,
    loggedInUsername,
    effectiveFullname
  );

  const isDifferentUser = loggedInUsername !== og_username;

  // const [newFullname, setNewFullname] = useState("");

  console.log(
    "Effective Username lour:",
    effectiveUsername,
    sharelinkUsername,
    loggedInUsername
  );
  console.log("currentCollection:", currentCollection);
  // const effectiveUsername = sharelinkUsername ? og_username : loggedInUsername;

  const [expanded, setExpanded] = useState(false);
  const [visitedCollections, setVisitedCollections] = useState([]);
  const [forwardCollections, setForwardCollections] = useState([]); // New state for forward navigation
  const [showAdditionalText, setShowAdditionalText] = useState(false);
  const [showAdditionalBox, setShowAdditionalBox] = useState(false);
  const [initialMousePos, setInitialMousePos] = useState({ x: 0, y: 0 });

  const navigate = useNavigate();
  const location = useLocation();

  const isOnMyPage = location.pathname === "/mypage";
  const isOnExplorePage = location.pathname === "/explore";
  const isOnUserPage = useMatch("/user/:username");
  const showPrivacyStatus = isOnMyPage || isOnUserPage;

  const [followersCount, setFollowersCount] = useState(0);
  const [followeesCount, setFolloweesCount] = useState(0);

  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState("");
  const [tempDescription, setTempDescription] = useState(description);
  const [loadingDescription, setLoadingDescription] = useState(true);
  const [descriptionVisible, setDescriptionVisible] = useState(false); // New state for fade-in effect

  const [followStatuses, setFollowStatuses] = useState({});

  const [searchResults, setSearchResults] = useState([]);

  const [shareLink, setShareLink] = useState(""); // State for storing generated share link
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const handleFollow = async (username) => {
    try {
      const currentStatus = followStatuses[username] || "none";
      let apiEndpoint;
      let method;

      if (currentStatus === "none" || currentStatus === "rejected") {
        apiEndpoint = `/api/user/${username}/follow`;
        method = "POST";
      } else if (currentStatus === "pending") {
        apiEndpoint = `/api/user/${username}/cancel_follow`;
        method = "DELETE";
      } else if (currentStatus === "approved") {
        apiEndpoint = `/api/user/${username}/unfollow`;
        method = "DELETE";
      }

      const response = await fetch(apiEndpoint, {
        method: method,
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        // Fetch the updated follow status
        const followDataResponse = await fetch(
          `/api/user/${username}/follow_status`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "same-origin",
          }
        );
        const followData = await followDataResponse.json();
        setFollowStatuses((prevStatuses) => ({
          ...prevStatuses,
          [username]: followData.status,
        }));
      } else {
        console.error("Failed to update follow status");
      }
    } catch (error) {
      console.error("Error in follow/unfollow:", error);
    }
  };

  console.log("Current Collection oh my goshh:", currentCollection);

  const [isShareLinkLoaded, setIsShareLinkLoaded] = useState(false);
  const [accountPublic, setAccountPublic] = useState(false);

  // useEffect(() => {
  //   const prefetchShareLink = async () => {
  //     try {
  //       const response = await fetch(
  //         `/api/create_sharelink/user/${effectiveUsername}/collection/${currentCollection.collection_id}`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             collection_id: currentCollection.collection_id,
  //           }),
  //         }
  //       );
  //       const data = await response.json();
  //       if (response.ok && data.link_id) {
  //         const fullShareLink = `${window.location.origin}/share/${data.link_id}`;
  //         setShareLink(fullShareLink);
  //         setIsShareLinkLoaded(true);
  //         setAccountPublic(data.account_public);
  //       } else {
  //         console.error("Failed to pre-fetch share link:", data.error);
  //       }
  //     } catch (error) {
  //       console.error("Error pre-fetching share link:", error);
  //     }
  //   };

  //   if (isOwner && currentCollection) {
  //     prefetchShareLink();
  //   }
  // }, [effectiveUsername, currentCollection, isOwner, accountPublic]);

  useEffect(() => {
    const prefetchShareLink = async () => {
      try {
        const response = await fetch(
          `/api/create_sharelink/user/${effectiveUsername}/collection/${currentCollection.collection_id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              collection_id: currentCollection.collection_id,
            }),
          }
        );
        const data = await response.json();
        if (response.ok && data.link_id) {
          const fullShareLink = `${window.location.origin}/share/${data.link_id}`;
          setShareLink(fullShareLink);
          setIsShareLinkLoaded(true);
          // Remove or comment out the next line
          // setAccountPublic(data.account_public);
        } else {
          console.error("Failed to pre-fetch share link:", data.error);
        }
      } catch (error) {
        console.error("Error pre-fetching share link:", error);
      }
    };

    if (isOwner && currentCollection) {
      prefetchShareLink();
    }
  }, [effectiveUsername, currentCollection, isOwner]);

  useEffect(() => {
    console.log("privvv", privacySettingsVersion);
    const fetchPrivacySettings = async () => {
      try {
        const response = await fetch(
          `/api/user/${effectiveUsername}/privacy_settings`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "same-origin",
            cache: "no-cache",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch privacy settings");
        }

        const data = await response.json();
        setAccountPublic(data.account_public);
      } catch (error) {
        console.error("Error fetching privacy settings:", error);
      }
    };

    if (effectiveUsername) {
      fetchPrivacySettings();
    }
  }, [effectiveUsername, privacySettingsVersion]);

  const handleCopyShareLink = (e) => {
    e.stopPropagation();
    if (shareLink) {
      const successful = copy(shareLink);
      if (successful) {
        console.log("Text copied to clipboard successfully!");
        setShowCopiedMessage(true);

        setTimeout(() => {
          setShowCopiedMessage(false);
        }, 1500);
      } else {
        console.error("Failed to copy.");
        alert("Failed to copy.");
      }
    } else {
    }
  };

  const { username_viewing } = useParams();

  useEffect(() => {
    if (effectiveUsername) {
      fetch(`/api/search/${effectiveUsername}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && Array.isArray(data.results)) {
            setSearchResults(data.results || []);
            data.results.forEach((user) => {
              fetch(`/api/user/${user.username}/follow_status`, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
                credentials: "same-origin",
              })
                .then((response) => response.json())
                .then((followData) => {
                  setFollowStatuses((prev) => ({
                    ...prev,
                    [user.username]: followData.status,
                  }));
                });
            });
          } else {
            console.error("Expected 'results' to be an array, but got:", data);
            setSearchResults([]);
          }
        })
        .catch((error) => {
          console.error("An error occurred while fetching data: ", error);
        });
    } else {
      setSearchResults([]);
    }
  }, [effectiveUsername]);

  const toggleEditMode = (event) => {
    event.stopPropagation();
    setEditMode(!editMode);
    setTempDescription(description);
  };

  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    if (newDescription.length <= 110) {
      setTempDescription(newDescription);
    }
  };

  useEffect(() => {
    const initializeDescription = async () => {
      setLoadingDescription(true);
      const fetchedDescription = await fetchDescription(effectiveUsername);
      if (fetchedDescription) {
        setDescription(fetchedDescription);
        setDescriptionVisible(true);
      }
    };

    initializeDescription();
  }, [effectiveUsername]);

  const handleSubmit = async () => {
    const result = await updateDescription(tempDescription);

    console.log("777 Result:", result);

    if (result) {
      setDescription(tempDescription);
      setEditMode(false);
      setDescriptionVisible(true);
    } else {
      // Update failed, handle the error (e.g., show a message to the user)
      console.error("Error updating description:", result.error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const followersCount = await fetchFollowersCount(effectiveUsername);
      const followeesCount = await fetchFolloweesCount(effectiveUsername);
      setFollowersCount(followersCount);
      setFolloweesCount(followeesCount);
    };

    fetchData();

    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [effectiveUsername]);

  const textAreaRef = useRef(null);
  const profileBoxRef = useRef(null);

  useEffect(() => {
    if (editMode && textAreaRef.current) {
      textAreaRef.current.focus(); // Auto-focus the textarea when edit mode is enabled
      textAreaRef.current.setSelectionRange(
        textAreaRef.current.value.length,
        textAreaRef.current.value.length
      );
    }
  }, [editMode]);

  const handleExpand = () => {
    setExpanded(!expanded);
    setShowAdditionalText(!showAdditionalText);
    setShowAdditionalBox(!showAdditionalBox);
    if (expanded) {
      cancelEditMode();
    }
  };

  const cancelEditMode = () => {
    setEditMode(false);
    setTempDescription(description);
  };

  const handleMouseDown = (e) => {
    setInitialMousePos({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = (e) => {
    const threshold = 5;
    if (
      Math.abs(e.clientX - initialMousePos.x) < threshold &&
      Math.abs(e.clientY - initialMousePos.y) < threshold
    ) {
      handleExpand();
    }
  };

  useEffect(() => {
    if (
      currentCollection &&
      !visitedCollections.includes(currentCollection.collection_id)
    ) {
      setVisitedCollections([
        ...visitedCollections,
        currentCollection.collection_id,
      ]);
      setForwardCollections([]); // Clear the forward stack whenever we navigate to a new collection
    }
  }, [currentCollection]);

  const [collectionsTree, setCollectionsTree] = useState(null);
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);

  useEffect(() => {
    const fetchBranchToRoot = async () => {
      try {
        const response = await fetch(
          `/api/user/${effectiveUsername}/collection/${currentCollection.collection_id}/branch_to_root`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "same-origin",
          }
        );
        const data = await response.json();
        if (response.ok) {
          console.log("Branch data:", data);
          setBreadcrumbPath(data.data); // Ensure data.data contains the breadcrumb path
        } else {
          console.error("Error fetching branch to root:", data.error);
          setBreadcrumbPath([
            {
              collection_id: currentCollection.collection_id,
              collection_name: currentCollection.collection_name,
            },
          ]);
        }
      } catch (error) {
        console.error("Error fetching branch to root:", error);
        setBreadcrumbPath([
          {
            collection_id: currentCollection.collection_id,
            collection_name: currentCollection.collection_name,
          },
        ]);
      }
    };

    if (currentCollection && effectiveUsername) {
      fetchBranchToRoot();
    }
  }, [currentCollection, effectiveUsername]);

  const findPathToCollection = (node, targetCollectionId, currentPath = []) => {
    if (!node) return null;

    currentPath.push({
      collection_id: node.collection_id,
      collection_name: node.collection_name,
    });

    if (node.collection_id === targetCollectionId) {
      return currentPath;
    }

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        const result = findPathToCollection(child, targetCollectionId, [
          ...currentPath,
        ]);
        if (result) {
          return result;
        }
      }
    }

    currentPath.pop();
    return null;
  };

  // useEffect(() => {
  //   const updateBreadcrumbPath = () => {
  //     console.log(
  //       "Current Collection and tree:",
  //       currentCollection,
  //       collectionsTree
  //     );
  //     if (currentCollection && collectionsTree) {
  //       console.log("here");
  //       const rootNode = {
  //         collection_id: collectionsTree.root.collection_id,
  //         collection_name: collectionsTree.root.collection_name,
  //         children: collectionsTree.children,
  //       };
  //       const path = findPathToCollection(
  //         rootNode,
  //         currentCollection.collection_id
  //       );
  //       if (path) {
  //         console.log("Path to currentCollection:", path);
  //         setBreadcrumbPath(path);
  //       } else {
  //         console.error(
  //           "Path to currentCollection not found in collectionsTree"
  //         );
  //         setBreadcrumbPath([
  //           {
  //             collection_id: currentCollection.collection_id,
  //             collection_name: currentCollection.collection_name,
  //           },
  //         ]);
  //       }
  //     }
  //   };

  //   updateBreadcrumbPath();
  // }, [currentCollection, collectionsTree]);

  useEffect(() => {
    const updateBreadcrumbPath = () => {
      console.log(
        "Current Collection and tree:",
        currentCollection,
        collectionsTree
      );

      if (currentCollection) {
        if (collectionsTree) {
          console.log("CollectionsTree is available");
          const rootNode = {
            collection_id: collectionsTree.root.collection_id,
            collection_name: collectionsTree.root.collection_name,
            children: collectionsTree.children,
          };
          const path = findPathToCollection(
            rootNode,
            currentCollection.collection_id
          );
          if (path) {
            console.log("Path to currentCollection:", path);
            setBreadcrumbPath(path);
          } else {
            console.error(
              "Path to currentCollection not found in collectionsTree"
            );
            setBreadcrumbPath([
              {
                collection_id: currentCollection.collection_id,
                collection_name: currentCollection.collection_name,
              },
            ]);
          }
        } else {
          console.log("CollectionsTree is not available");
          // If collectionsTree is falsy, directly set the breadcrumb path
          console.log("cur therefore is ", currentCollection);
          setBreadcrumbPath([
            {
              collection_id: currentCollection.collection_id,
              collection_name: currentCollection.collection_name,
            },
          ]);
        }
      }
    };

    updateBreadcrumbPath();
  }, [currentCollection, collectionsTree]);

  const [canGoForward, setCanGoForward] = useState(false);

  useEffect(() => {
    const checkForwardNavigation = () => {
      setCanGoForward(
        window.history.state !== null && window.history.length > 1
      );
    };

    checkForwardNavigation();
  }, [location]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleForward = () => {
    if (canGoForward) {
      navigate(1);
    }
  };

  const [visibility, setVisibility] = useState("hidden");

  useEffect(() => {
    let timeoutId;

    if (showAdditionalBox) {
      setVisibility("visible");
    } else {
      timeoutId = setTimeout(() => {
        setVisibility("hidden");
      }, 300);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showAdditionalBox]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div>
      <div
        ref={profileBoxRef}
        className={`profile-box-container ${
          expanded ? "expanded" : ""
        } no-select`}
        // className={`profile-box-container ${expanded ? "expanded" : "expanded"}`}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{
          top: `${profileBoxTop}px`, // Use dynamic value for top
          left: `${profileBoxLeft}px`,
          zIndex: 10,
        }}
        // onClick={handleExpand}
      >
        {/* Optional showcase logo */}
        {/* <div
        // className="logo-container no-select"
        className={`logo-container no-select ${
          expanded ? "show" : "hide-logo"
        }`}
        style={{
          display: "flex",
          flexDirection: "column", // Stack the content vertically
          alignItems: "center", // Center horizontally
          position: "fixed", // Fixes the logo to the bottom of the page
          bottom: "4px", // Adjust positioning from the bottom
          left: "50%", // Horizontally center
          transform: "translateX(-50%)", // Ensure it's centered properly
          zIndex: 1000, // Ensure it stays on top of other content
          pointerEvents: "none", // Prevent interaction with the logo
          opacity: expanded ? 1 : 0, // Control visibility with opacity
          visibility: expanded ? "visible" : "hidden", // Control visibility
          transition: "opacity 0.7s ease, visibility 0.7s ease",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-152px",
            // marginLeft: "-190px",
            // marginLeft: "250px",
          }}
        >
          <img
            src={logoIcon}
            style={{
              height: "13px",
              marginTop: "2px",
              marginLeft: "2px",
              opacity: 0.55,
            }}
            alt="Logo Icon"
            className="logo-icon"
          />
          <img
            src={logoShowcase}
            style={{
              height: "15px",
              marginTop: "0px",
              marginLeft: "2px",
              opacity: 0.3,
            }}
            alt="Logo Showcase"
            className="logo-showcase"
          />
        </div>
      </div> */}
        {/* {showPrivacyStatus && ( */}
        <div
          className="account-privacy-status"
          style={{
            position: "absolute",
            color: "#bbb",
            top: "6px",
            // left: "0",
            marginLeft: expanded ? "-200px" : "0%",
            // fontSize: "14px",
            fontSize: expanded ? "8px" : "14px",
            display: "flex",
            // alignItems: "center", // Align icon and text
            //  opacity: expanded ? 1 : 0,
            transition:
              "margin-left 0.5s ease-in-out, font-size 0.5s ease-in-out",
          }}
        >
          {accountPublic ? (
            <>
              <TbWorld
                style={{
                  marginBottom: "0px",
                  marginRight: "2px",
                  marginRight: expanded ? "1px" : "2px",

                  // marginTop: "2px",
                  marginTop: expanded ? "2px" : "2px",
                  scale: expanded ? "0.9" : "0.9",
                  transition:
                    "margin-right 0.5s ease-in-out,  margin-top 0.5s ease-in-out",
                }}
                // size={14}
              />{" "}
              Public Account
            </>
          ) : (
            <>
              <BiSolidLockAlt
                style={{
                  marginBottom: "-2px",
                  marginRight: "2px",
                  marginRight: expanded ? "1px" : "2px",

                  // marginTop: "2px",
                  marginTop: expanded ? "2px" : "2px",
                  scale: expanded ? "0.8" : "1",
                  transition:
                    "margin-right 0.5s ease-in-out,  margin-top 0.5s ease-in-out",
                }}
                // size={14}
              />{" "}
              Private Account
            </>
          )}
        </div>
        {/* )} */}
        <h1
          className="profile-box no-select"
          style={{
            color: "black",
            margin: 0,
            fontSize: "27px",
            textAlign: "center",
            // transform: expanded ? "scale(0.7) translate(0px, -77px)" : "scale(1)",
            transform: expanded
              ? `scale(0.7) translate(0px, ${
                  isDifferentUser ? "-90px" : "-77px"
                })`
              : "scale(1)",
            textAlign: "center",
            transition: "transform 0.5s ease-in-out",
            // marginTop: "57px",
            marginTop: isDifferentUser ? "57px" : "57px",
            userSelect: "none",
            fontWeight: "400",
          }}
        >
          {effectiveFullname
            ? effectiveFullname
            : searchResults && searchResults.length > 0
            ? searchResults[0].full_name
            : null}

          {console.log(
            "Username from URL wowowow 2:",
            effectiveFullname,
            loggedInUsername,
            og_username,
            currentCollection
          )}

          {/* {user.full_name} */}
          {console.log(
            "Username from URL wowowow:",
            loggedInUsername,
            og_username,
            currentCollection
          )}
        </h1>
        <div
          className={`username-fade-in ${expanded ? "show" : ""} no-select`}
          style={{
            position: "absolute",
            top: "155px",
            left: "50%", // Position from the left side
            transform: "translateX(-50%)", // Center the text
            textAlign: "center",
            opacity: expanded ? 1 : 0,
            transition: expanded
              ? "opacity 0.7s ease-in"
              : "opacity 0.4s ease-out",
            // marginTop: "-123px",
            marginTop: isDifferentUser ? "-124px" : "-113px",
            marginLeft: "-1px", // Reset marginLeft to 0
            fontSize: "9px",
            color: "#777777",
            // userSelect: "none",
            cursor: "default",
          }}
        >
          @{effectiveUsername}
        </div>
        <div
          className="clamped-text description-box no-select"
          // onMouseDown={(e) => e.stopPropagation()}
          // onMouseUp={(e) => e.stopPropagation()}
          onMouseDown={(e) => {
            if (!editMode) handleMouseDown(e);
          }}
          onMouseUp={(e) => {
            if (!editMode) handleMouseUp(e);
          }}
          style={{
            width: "250px",
            height: "30px",
            backgroundColor: "#dddddd",
            position: "absolute",
            top: "10px",
            left: "268px",
            borderRadius: "4px",
            // padding: "5px", // Added padding
            padding: "2px",
            // padding: "2px 2px 2px 2px",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Space out the textarea and buttons
            opacity: showAdditionalBox ? 1 : 0,
            visibility: visibility,
            transition:
              "opacity 0.3s ease-in, opacity 0.3s ease-out, transform 1s ease-in-out",
            // transform: `translate(-110px, -30px) ${
            //   showAdditionalBox ? "" : "translate(-50%, -50%)"
            // }`,
            transform: `translate(-252px, 97px) ${
              showAdditionalBox ? "" : "translate(-15%, -30%)"
            }`,
            fontSize: "9px",
            zIndex: 10,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center horizontally
            justifyContent: "center", // Center vertically
            textAlign: "center", // Center text horizontally
          }}
          autoFocus
        >
          {isOwner && !description && !editMode && (
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "15%", // Center the div horizontally
                right: "15%", // Center the div horizontally
                bottom: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 11, // Ensure this is on top of other content
                color: "#999",
                fontSize: "10px",
                width: "70%", // Set the width to 70% of its container
                margin: "0 auto", // Center the div within the parent
              }}
            >
              Add a description
            </div>
          )}

          {editMode ? (
            <div
              onMouseDown={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
            >
              <textarea
                ref={textAreaRef}
                className="no-select"
                value={tempDescription}
                onChange={handleDescriptionChange}
                // onMouseDown={(e) => e.stopPropagation()}
                // onMouseUp={(e) => e.stopPropagation()}
                style={{
                  backgroundColor: "#dddddd",
                  // flexGrow: 1,
                  // padding: "3px",
                  // width: "100%", // Full width of the parent
                  width: "240px",
                  border: "none",
                  borderRadius: "3px",
                  resize: "none",
                  fontSize: "9px",
                  overflow: "hidden",
                  height: "30px",
                  boxSizing: "border-box",
                  outline: "none",
                  padding: "1px",
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center", // Center horizontally
                  justifyContent: "center", // Center vertically
                  textAlign: "center", // Center text horizontally
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
          ) : (
            <>
              <div
                className="no-select"
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  className={`fade-in-description ${
                    descriptionVisible ? "show-description" : ""
                  }`}
                >
                  {description || ""}
                </span>
              </div>
              {isOwner && (
                <button
                  onClick={toggleEditMode}
                  style={{
                    position: "absolute",
                    top: "7px",
                    right: "1px",
                    padding: "0px 0px",
                    borderRadius: "4px",
                    border: "none",
                    // backgroundColor: "#C0C0C0",
                    background: "none",
                    color: "white",
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseUp={(e) => e.stopPropagation()}
                >
                  <MdOutlineModeEdit size={15} />
                </button>
              )}
            </>
          )}
        </div>
        {editMode && (
          <div
            className="button-container no-select"
            style={{
              position: "absolute",
              // top: "70px", // Position the buttons right below the textarea
              // left: "268px", // Align with the description box
              top: "134px",
              right: "-150px",
              width: "250px", // Match the width of the description box
              zIndex: 10,
            }}
          >
            <button
              className="no-select"
              onMouseDown={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
              onClick={toggleEditMode}
              style={{
                borderRadius: "2px",
                border: "none",
                paddingTop: "1px",
                backgroundColor: "#d0d0d0",
                // color: "white",
                fontSize: "9px",
                // marginTop: "5px",
                marginRight: "3px",
              }}
            >
              Cancel
            </button>
            <button
              className="no-select"
              onMouseDown={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
              onClick={handleSubmit}
              style={{
                // padding: "2px 2px",
                paddingTop: "1px",
                borderRadius: "2px",
                border: "none",
                backgroundColor: "#d0d0d0",
                // color: "white",
                fontSize: "9px",
                // marginTop: "5px",
              }}
            >
              Submit
            </button>
          </div>
        )}

        <div
          className="no-select"
          style={{
            display: "flex", // Use Flexbox
            position: "relative", // Absolutely position the container
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Full width of the parent container
            height: "0%", // Full height of the parent container (if needed)
          }}
        >
          {searchResults
            .filter((user) => {
              const result = loggedInUsername !== og_username;
              return result;
            })
            .slice(0, 1) // Limit to the first result
            .map((user, index) => (
              <div key={user.user_id}>
                {followingButtonExplore && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      marginTop: "45px",
                    }}
                  >
                    <button
                      className="no-select"
                      onMouseDown={(e) => e.stopPropagation()}
                      onMouseUp={(e) => e.stopPropagation()}
                      onClick={() => {
                        const currentStatus =
                          followStatuses[effectiveUsername] || "none";
                        let newStatus;

                        if (
                          currentStatus === "none" ||
                          currentStatus === "rejected"
                        ) {
                          newStatus = "pending";
                        } else if (currentStatus === "pending") {
                          newStatus = "none"; // Revert to no follow request. Adjust as needed.
                        } else if (currentStatus === "approved") {
                          newStatus = "none"; // Unfollow action. Adjust as needed.
                        }

                        handleFollow(effectiveUsername, newStatus);
                      }}
                      style={{
                        fontSize: "16px",
                        borderRadius: "5px",
                        border: "none",
                        backgroundColor: "#ccc", // Adjust the grey color as you like
                        marginLeft: "7px",
                        // paddingTop: "2px", //double check this and the two below
                        marginLeft: "0px",
                        marginTop: sharelinkFullname ? "-49px" : "-44px",
                        textAlign: "center",

                        transform: `translateX(0px) ${
                          expanded
                            ? "scale(0.65) translate(0px, -92px)"
                            : "scale(1.0)"
                        }`,
                        transition: "transform 0.5s ease-in-out",
                      }}
                    >
                      {(() => {
                        const followStatus =
                          followStatuses[effectiveUsername] || "none";
                        if (
                          followStatus === "none" ||
                          followStatus === "rejected"
                        ) {
                          return "Follow";
                        } else if (followStatus === "pending") {
                          return "Pending";
                        } else if (followStatus === "approved") {
                          return "Following";
                        }
                      })()}
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>

        {searchResults.length === 0 && sharelinkUsername && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              marginTop: "45px",
            }}
          >
            <button
              className="no-select"
              onMouseDown={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
              onClick={() => navigate("/")}
              style={{
                fontSize: "16px",
                borderRadius: "5px",
                border: "none",
                backgroundColor: "#ccc", // Adjust the grey color as you like
                marginLeft: "0px",
                marginTop: "-76px",
                textAlign: "center",
                transform: `translateX(0px) ${
                  expanded ? "scale(0.65) translate(0px, -78px)" : "scale(1.0)"
                }`,
                transition: "transform 0.5s ease-in-out",
              }}
            >
              Follow
            </button>
          </div>
        )}
        <div
          className="no-select"
          style={{
            marginLeft: "0.5vw",
            // marginTop: "4.5vh",
            // marginBottom: "-3.5vh",
            // marginTop: loggedInUsername !== og_username ? "4.5vh" : "1vh",
            // marginBottom: loggedInUsername !== og_username ? "-3.5vh" : "0vh",
            marginTop: followingButtonExplore
              ? loggedInUsername !== og_username
                ? "4.6vh"
                : "1vh"
              : "1vh", // Default margin when followingButtonExplore is false
            marginBottom: followingButtonExplore
              ? loggedInUsername !== og_username
                ? "-3.6vh"
                : "0vh"
              : "0vh", // Default margin when followingButtonExplore is false
          }}
        >
          <div
            className={`additional-text ${
              showAdditionalText ? "fade-in" : ""
            } no-select`}
            style={{
              userSelect: "none",
              left: "77px",
              top: "60px",
              fontSize: "12px",
              position: "absolute",
            }}
          >
            following: {followeesCount}
          </div>
          <div
            className={`additional-text ${
              showAdditionalText ? "fade-in" : ""
            }  no-select`}
            style={{
              userSelect: "none",
              left: "77px",
              top: "79px",
              fontSize: "12px",
              position: "absolute",
            }}
          >
            followers: {followersCount}
          </div>
        </div>
        {currentCollection && currentCollection.type === "artefacts" && (
          <div
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            style={{ marginTop: "-27px", zIndex: "20" }}
            className="sharelink-button-container"
          >
            {/* Share Icon */}
            {isOwner && (
              <button
                style={{
                  position: "absolute",
                  display: "inline-flex",
                  alignItems: "center",
                  cursor: "pointer",
                  userSelect: "none",
                  padding: "2px 4px",
                  right: "30px",
                  top: "74px",
                  borderRadius: "5px",
                  // background: "none",
                  border: "none",
                  opacity: showAdditionalBox ? 1 : 0,
                  visibility: visibility,
                  transition:
                    "opacity 0.3s ease-in, opacity 0.6s ease-out, transform 0.6s ease-in-out",
                  transform: `translate(0px, 0px) ${
                    showAdditionalBox ? "" : "translate(55px, 20px)"
                  }`,
                  // touchAction: "manipulation",
                  zIndex: 90,
                }}
                onClick={handleCopyShareLink}
                className="here interactive-link no-select"
              >
                <FiLink
                  className="no-select"
                  size={11}
                  style={{
                    color: "gray",
                    zIndex: 50,
                  }}
                />
                <span
                  className="no-select"
                  style={{
                    marginLeft: "3px",
                    fontSize: "10.5px",
                  }}
                >
                  Share link
                </span>
                {/* <div className="tooltip-text">
                  This sharelink allows others to view this collection and its
                  public artefacts for 48 hours.
                </div> */}
                <div className="tooltip-text">
                  {accountPublic
                    ? "This sharelink allows others to view this collection and its public artefacts for 2 months."
                    : "This sharelink allows others to view this collection and its public artefacts for 48 hours."}
                </div>
              </button>
            )}

            {/* Copied to Clipboard Message */}
            {showCopiedMessage && (
              <div
                style={{
                  position: "absolute",
                  top: "96px", // Adjust based on your icon's position and size
                  left: "165px", // Center horizontally
                  // top: "143px",
                  // left: "54px",
                  userSelect: "none",
                  transform: "translateY(0px)", // Center the element exactly
                  fontSize: "8px",
                  lineHeight: "12px",
                  marginTop: "1px",
                  color: "black",
                  backgroundColor: "#EEEEEE",
                  padding: "3px 0px",
                  borderRadius: "4px",
                  zIndex: "21",
                  width: "100px",
                  textAlign: "center", // Center the text within the div
                }}
                className="sharelink-copied-message no-select"
              >
                Copied link to clipboard
              </div>
            )}
          </div>
        )}
        <div>
          <div
            className="arrows-breadcrumb no-select"
            style={{
              width: "160px",
              // marginRight: "120px",
              // marginTop: "24px",
              position: "absolute",
              right: "118px", // Adjust as needed
              bottom: "1px", // Adjust as needed
              width: "160px",
              display: "flex",
              alignItems: "center",
              transform: expanded
                ? "scale(0.65) translate(-42px, 2px)"
                : "scale(1.05)",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                width: "100%",
                marginTop: "-24px",
                marginLeft: "5px",
              }}
            >
              <div style={{ marginTop: "0px" }}>
                <button
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseUp={(e) => e.stopPropagation()}
                  style={{
                    border: "none",
                    flexShrink: 0,
                    marginTop: "10px",
                    width: "28px",
                    color: isOnMyPage || isOnExplorePage ? "grey" : "black", // Update this line
                    cursor:
                      isOnMyPage || isOnExplorePage ? "default" : "pointer", // Update this line
                  }}
                  onClick={() => {
                    if (!isOnMyPage && !isOnExplorePage && !homePageFlag) {
                      handleBack(); // Only navigate back if not on /mypage
                    }
                  }}
                  className={`arrow-key-boxes ${
                    isOnMyPage || isOnExplorePage ? "disabled" : ""
                  }`}
                  disabled={isOnMyPage || isOnExplorePage}
                >
                  <BsArrowLeft
                    size={21}
                    className="arrow-keys"
                    style={{ marginTop: "-52px", marginLeft: "-14px" }}
                  />
                </button>
              </div>
              <div style={{ marginTop: "0px" }}>
                {console.log("currentCollection:sdf sdf", currentCollection)}
                <button
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseUp={(e) => e.stopPropagation()}
                  style={{
                    border: "none",
                    flexShrink: 0,
                    left: "0px",
                    marginLeft: "0px",
                    marginTop: "10px",
                    width: "28px",
                    color:
                      currentCollection?.type === "artefacts"
                        ? "grey"
                        : "black",
                    cursor:
                      currentCollection?.type === "artefacts"
                        ? "default"
                        : "pointer",
                  }}
                  onClick={() => {
                    if (
                      !homePageFlag &&
                      currentCollection?.type !== "artefacts"
                    ) {
                      handleForward();
                    }
                  }}
                  className={`arrow-key-boxes ${
                    currentCollection?.type === "artefacts" ? "disabled" : ""
                  }`}
                  disabled={currentCollection?.type === "artefacts"}
                >
                  <BsArrowRight
                    size={21}
                    className="arrow-keys"
                    style={{
                      marginTop: "-52px",
                      marginLeft: "-14px",
                    }}
                  />
                </button>
              </div>

              <div style={{ marginLeft: "1.1rem" }}>
                <div
                  className="breadcrumb-container no-select"
                  style={{
                    flexGrow: 0,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    marginTop: "11px",
                  }}
                >
                  <TreeBreadCrumb
                    // visitedCollections={visitedCollections}
                    expanded={expanded}
                    breadcrumbPath={breadcrumbPath}
                    currentCollection={currentCollection}
                    nestingLevel={visitedCollections.length}
                    loggedInUsername={loggedInUsername}
                    effectiveUsername={effectiveUsername}
                    og_username={og_username}
                    homePageFlag={homePageFlag}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
