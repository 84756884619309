// PieChart.jsx
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const PieChart = ({ duration = 10000, size = 50, color = "#7695FF" }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (duration === 0) {
      setProgress(1);
      return;
    }

    let start = null;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const elapsed = timestamp - start;
      const percentage = Math.min(elapsed / duration, 1);
      setProgress(percentage);
      if (elapsed < duration) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);

    return () => {
      // Cleanup if the component unmounts
    };
  }, [duration]);

  const angle = progress * 360;

  let pathData = "";
  if (angle > 0 && angle < 360) {
    const radians = (angle - 90) * (Math.PI / 180); // Start at top (12 o'clock)
    const cx = size / 2;
    const cy = size / 2;
    const r = size / 2 - 5; // Subtract stroke width
    const x = cx + r * Math.cos(radians);
    const y = cy + r * Math.sin(radians);
    const largeArcFlag = angle > 180 ? 1 : 0;

    pathData = `M${cx},${cy} L${cx},${cy - r} 
               A${r},${r} 0 ${largeArcFlag},1 ${x},${y} 
               Z`;
  } else if (angle === 360) {
    // Draw a full circle using two arcs
    const cx = size / 2;
    const cy = size / 2;
    const r = size / 2 - 5;
    pathData = `
      M ${cx},${cy - r}
      A ${r},${r} 0 1,1 ${cx},${cy + r}
      A ${r},${r} 0 1,1 ${cx},${cy - r}
      Z
    `;
  }

  return (
    <svg width={size} height={size}>
      {/* Background Circle */}
      <circle cx={size / 2} cy={size / 2} r={size / 2 - 5} fill="#fff" />
      {/* Animated Pie Slice */}
      {angle > 0 && <path d={pathData} fill={color} />}
    </svg>
  );
};

PieChart.propTypes = {
  duration: PropTypes.number, // Duration of the animation in milliseconds
  size: PropTypes.number, // Size of the SVG (width and height)
  color: PropTypes.string, // Color of the pie slice
};

export default PieChart;
