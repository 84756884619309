import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ContactUsModal from "../components/Modals/ContactUsModal";
import useMediaQuery from "@mui/material/useMediaQuery";

import logoIcon from "../logo_icon.png";
import logoShowcase from "../logo_showcase.png";
import greyLogoIcon from "../grey_logo_icon.png";
// import Home_PannableBox from "../components/Home/Home_PannableBox";
import HomeNavbar from "../components/Home_Navbar";
import NavBar from "../components/NavBar";
import Home_Collection_Page from "../components/Home_Collection_Page";
import { IoArrowUpCircle } from "react-icons/io5";
import { IoMdRefreshCircle } from "react-icons/io";
import { RiInformationFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoTiktok } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";

import "../css/Home.css";
import zIndex from "@mui/material/styles/zIndex";

const words = [
  "interests",
  "hobbies",
  "pictures",
  "ideas",
  "skills",
  "projects",
  "social media",
  "bookmarks",
  "experiences",
  "memories",
  "inspirations",
  "achievements",
  "habits",
  "writings",
  "beliefs",
  "stories",
  "dreams",
  "goals",
  "aspirations",
  "philosophies",
  "curiosities",
  "blog posts",
  "information",
  "knowledge",
  "creations",
  "creativity",
  "imagination",
  "thoughts",
  "opinions",
  "recommendations",
  "suggestions",
  "tips",
  "art",
  "designs",
  "lessons",
  "learnings",
  "discoveries",
  "explorations",
  "research",
  "studies",
  "articles",
  "facts",
  "passions",
  "books",
  "culture",
  "travel",
  "links",
  "music",
  "values",
  "life",
];

const Home = (props) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const { id } = useParams();

  // Made on 16-10-2024
  // const shareLinkIds = [
  //   "10e6df52-c9de-425f-a450-54a7a5c88e8b", // Starting link ID
  //   "e916cc16-a8d1-4e40-9ee2-28d61fdbe61c",
  //   "fdb43775-3bb4-4256-967a-250a82c9900c",
  //   "b0b765c0-a158-4b83-9db6-5195921e3047",
  // ];

  // Deloyed: made on 16-12-2024

  const shareLinkIds = [
    // "df2c67a4-6140-4633-8193-b1cf6722b6c1", // Information
    "bc0034cf-43dd-48db-99bc-8bda997a671a", //Information new
    // "fe1f96db-ecf3-4418-83ce-b4e08e8d62d0", // Design work
    "c7df6619-1b42-4db8-b5df-2d274348d306", //Design work new
    // "e043ef83-146f-45b3-93ed-0238b9b49026", // Other design work
    "2bc0029e-2439-4eb5-a0b2-1153661fd131", // Other design work new
    // "6d604ba9-03e6-40f4-964f-b76ea81db7d0", // Fixed income work
    "33785a5b-c344-4c50-9ec2-996f56352e2b", //Research
    // "01b63647-95fc-40c3-bc4d-6da00ac6b702", // Space X again
    "a8a9db47-05b3-4ae0-8138-26215c6489c3", //Space X again new

    // "63307063-76cd-4220-aedb-31f8ec8d4d30", // Architecture
    "edfcae51-69b0-4d5c-9102-fb42ce1ef753", // Architecture new
    // "b78b920e-209b-41f4-9316-0701710c4d2f", // Fashion
    "c281ef5b-1271-4b6d-a6c7-03b7b028bc4c", // Fashion new
    // "2df49172-53b6-42cb-b7af-f190f2a7e889", // Fine art
    "df7cc0b4-40ef-4aab-bcf9-d7a4d9888be9",
    // "6be4c384-2ea8-40fc-8f97-39c23f79aa08", // An artist
    "392f9932-9df2-4b14-8280-0d19cee96525", // An artist new
    // "51f98b0d-82e8-4a01-bc43-a1714d155a48", // JCal
    "64c07ded-1555-4ba5-a0a7-4fda21245e44", // JCal new
  ];

  // const [linkId, setLinkId] = useState(shareLinkIds[0]);
  // const linkId = id ? shareLinkIds[parseInt(id, 10) - 1] : shareLinkIds[0];
  const linkId = id ? shareLinkIds[parseInt(id, 10) - 1] : shareLinkIds[1]; //default to design work being first

  // const [linkId, setLinkId] = useState(() => {
  //   if (id) {
  //     const index = parseInt(id, 10) - 1;
  //     return shareLinkIds[index] || shareLinkIds[0];
  //   }
  //   return shareLinkIds[0]; // Default for "/"
  // });

  const [homePageFlag, setHomePageFlag] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // useEffect(() => {
  //   if (!isRefreshing && id) {
  //     const index = parseInt(id, 10) - 1;
  //     if (index >= 0 && index < shareLinkIds.length) {
  //       setLinkId(shareLinkIds[index]);
  //     } else {
  //       // Optionally handle invalid id by redirecting or setting a default
  //       navigate("/home-1");
  //     }
  //   } else if (!isRefreshing) {
  //     setLinkId(shareLinkIds[0]); // Default for "/"
  //   }
  // }, [id, navigate, shareLinkIds, isRefreshing]);

  // useEffect(() => {
  //   if (id) {
  //     const index = parseInt(id, 10) - 1;
  //     if (index >= 0 && index < shareLinkIds.length) {
  //       setLinkId(shareLinkIds[index]);
  //     } else {
  //       // Optionally handle invalid id by redirecting or setting a default
  //       navigate("/home-1");
  //     }
  //   } else {
  //     setLinkId(shareLinkIds[0]); // Default for "/"
  //   }
  // }, [id, navigate, shareLinkIds]);

  const [isInfoClicked, setIsInfoClicked] = useState(false);
  const [isRefreshClicked, setIsRefreshClicked] = useState(false);

  // const handleRefreshClick = () => {
  //   setIsRefreshClicked(true); // Set clicked state to true
  //   setTimeout(() => setIsRefreshClicked(false), 1000); // Reset after 1 second

  //   // Your existing logic to update the linkId
  //   const availableLinkIds = shareLinkIds.filter((id) => id !== linkId);

  //   //   // Choose a random ID from the filtered array
  //   const randomLinkId =
  //     availableLinkIds[Math.floor(Math.random() * availableLinkIds.length)];

  //   // Set the new linkId
  //   setLinkId(randomLinkId);
  // };

  // const handleRefreshClick = () => {
  //   setIsRefreshing(true); // Prevent the useEffect from resetting the linkId
  //   setIsRefreshClicked(true); // Set clicked state to true

  //   setTimeout(() => {
  //     setIsRefreshClicked(false); // Reset clicked state after 1 second
  //     setIsRefreshing(false); // Allow the useEffect to work again
  //   }, 1000);

  //   // Your existing logic to update the linkId
  //   const availableLinkIds = shareLinkIds.filter((id) => id !== linkId);

  //   // Choose a random ID from the filtered array
  //   const randomLinkId =
  //     availableLinkIds[Math.floor(Math.random() * availableLinkIds.length)];

  //   // Set the new linkId
  //   setLinkId(randomLinkId);
  // };

  const handleRefreshClick = () => {
    setIsRefreshClicked(true);
    setTimeout(() => setIsRefreshClicked(false), 1000);

    const availableLinkIds = shareLinkIds.filter((id) => id !== linkId);
    const randomLinkId =
      availableLinkIds[Math.floor(Math.random() * availableLinkIds.length)];

    const newIndex = shareLinkIds.indexOf(randomLinkId);
    navigate(`/home-${newIndex + 1}`);
  };

  const handleInfoClick = () => {
    setIsInfoClicked(true); // Set clicked state to true
    setTimeout(() => setIsInfoClicked(false), 1000);
    navigate("/home-1");
    // setLinkId(shareLinkIds[0]);
  };

  const scrollYRef = useRef(0);
  const touchStartYRef = useRef(0);

  const isPhoneMode = useMediaQuery("(max-width: 768px)");

  const [currentWord, setCurrentWord] = useState("life");
  const [fadeIn, setFadeIn] = useState(true);
  const [boxWidth, setBoxWidth] = useState(100); // Initial width of the box
  const wordRef = useRef(null); // Reference to the word span
  const [artefacts, setArtefacts] = useState([]);

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };

  const [artefactData, setArtefactData] = useState({
    artefactName: "",
    imageURL: "",
    type: "",
  });

  useEffect(() => {
    const wordInterval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentWord((prevWord) => {
          const currentIndex = words.indexOf(prevWord);
          const nextIndex = (currentIndex + 1) % words.length;
          return words[nextIndex];
        });
        setFadeIn(true);
      }, 500);
    }, 2500);

    return () => clearInterval(wordInterval);
  }, []);

  useEffect(() => {
    // Update the box width when the word changes
    if (wordRef.current) {
      setBoxWidth(wordRef.current.offsetWidth + 20); // Adding some padding to the width
    }
  }, [currentWord]);

  useEffect(() => {
    // Redirect to /mypage if logged in and currently at /
    if (props.loggedIn && location.pathname === "/") {
      navigate("/mypage");
    }
  }, [props.loggedIn, location, navigate]);

  const [scrollPosition, setScrollPosition] = useState(0);

  const [hasStartedScrolling, setHasStartedScrolling] = useState(false);

  // Update hasStartedScrolling based on scroll position
  useEffect(() => {
    if (scrollPosition >= 700) {
      setHasStartedScrolling(true);
    } else {
      setHasStartedScrolling(false);
    }
  }, [scrollPosition]);

  const [textOpacity, setTextOpacity] = useState(1);
  const [boxScale, setBoxScale] = useState(0.55);
  const [logoPosition, setLogoPosition] = useState({
    top: "270px",
    left: "50%",
  });
  const [translate, setTranslate] = useState({ x: "-50%", y: "-50%" });

  const INITIAL_TITLE_TOP = 60; // Initial top position in pixels
  const TITLE_MOVEMENT = 200; // Total movement in pixels
  const [boxTranslateY, setBoxTranslateY] = useState(0);
  const [semiCirclePosition, setSemiCirclePosition] = useState(-81);
  const [interactArrow, setInteractArrow] = useState(-70);
  const [interactArrowOpacity, setInteractArrowOpacity] = useState(1);
  // const [isMaxScrollReached, setIsMaxScrollReached] = useState(false);
  const [isMaxScroll, setIsMaxScroll] = useState(false);
  const [navbarTranslateX, setNavbarTranslateX] = useState(0);
  const [navbarScale, setNavbarScale] = useState(1);

  const [semiCircleSize, setSemiCircleSize] = useState({
    width: "1400px",
    height: "600px",
  });

  // Add a new effect for dynamic resizing
  useEffect(() => {
    const updateSemiCircleSize = () => {
      const perfectHeight = 742.4;
      const scaleFactor = window.innerHeight / perfectHeight / 0.9;
      const scaleFactorW = window.innerHeight / perfectHeight / 1.05;

      setSemiCircleSize({
        width: `${1400 * scaleFactorW}px`, // Scale the width based on the scaleFactor
        height: `${600 * scaleFactor}px`, // Scale the height based on the scaleFactor
      });
    };

    // Set the initial size based on the current screen size
    updateSemiCircleSize();

    // Add event listener for window resizing
    window.addEventListener("resize", updateSemiCircleSize);

    return () => {
      window.removeEventListener("resize", updateSemiCircleSize);
    };
  }, []);

  const [titleStyle, setTitleStyle] = useState({
    top: `${INITIAL_TITLE_TOP}px`,
    opacity: 1,
  });

  useLayoutEffect(() => {
    setLogoPosition({
      top: `${270}px`,
      left: `50%`,
      scale: 1, // Initial scale value
    });

    setTranslate({
      x: `-50%`,
      y: `-50%`,
    });

    setTitleStyle({
      top: `${INITIAL_TITLE_TOP}px`,
      opacity: 1,
    });
    setInteractArrow(-70); // Initial position
  }, []);

  const updateScroll = (deltaY) => {
    const maxScroll = 700; // Maximum scroll value
    const maxTranslateY = 223;
    const maxOpacitySteps = 500;
    const INITIAL_TITLE_TOP = 60; // Initial top position in pixels
    const TITLE_MOVEMENT = 200; // Total movement in pixels

    scrollYRef.current += deltaY;

    // Clamp scrollY between 0 and maxScroll
    scrollYRef.current = Math.max(0, Math.min(scrollYRef.current, maxScroll));

    setScrollPosition(scrollYRef.current);

    // const newBoxTranslateY = (scrollYRef.current / maxScroll) * maxTranslateY;
    // setBoxTranslateY(newBoxTranslateY);

    const newBoxTranslateY = (scrollYRef.current / maxScroll) * maxTranslateY;

    // Adjust translateY based on screen height exceeding 742.40px
    const adjustmentFactor =
      window.innerHeight > 742
        ? ((window.innerHeight - 742) / window.innerHeight) * 320
        : 0;
    const adjustedTranslateY = newBoxTranslateY + adjustmentFactor;

    setBoxTranslateY(adjustedTranslateY);

    const newSemiCirclePosition = -81 + (45 * scrollYRef.current) / maxScroll;
    setSemiCirclePosition(newSemiCirclePosition);

    // const newSemiCirclePosition = -66 + (33 * scrollYRef.current) / maxScroll;

    // // **Adjust semiCirclePosition based on screen height exceeding 742.4px**
    // const semiCircleAdjustmentFactor =
    //   window.innerHeight > 742.4
    //     ? ((window.innerHeight - 742.4) / window.innerHeight) * 1 // Adjust this multiplier as needed
    //     : 0;
    // const adjustedSemiCirclePosition =
    //   newSemiCirclePosition + semiCircleAdjustmentFactor;

    // setSemiCirclePosition(adjustedSemiCirclePosition);

    const newInteractArrow = -70 + (253 * scrollYRef.current) / maxScroll;
    setInteractArrow(newInteractArrow);

    const newArrowOpacity = Math.max(
      0,
      1 - scrollYRef.current / maxOpacitySteps
    );
    setInteractArrowOpacity(newArrowOpacity);

    setIsMaxScroll(scrollYRef.current === maxScroll);

    if (scrollYRef.current <= maxScroll) {
      // Calculate new positions based on scroll amount
      const newTop = 270 - (270 * scrollYRef.current) / maxScroll - 50;
      const newLeft =
        50 -
        (50 * scrollYRef.current) / maxScroll -
        (70 / window.innerWidth) * 100;

      // Calculate translation values
      const newTranslateX = -50 - (107 * scrollYRef.current) / maxScroll;
      const newTranslateY = -50 - (119 * scrollYRef.current) / maxScroll;

      // Calculate title position and opacity
      const newTitleTop =
        INITIAL_TITLE_TOP - (TITLE_MOVEMENT * scrollYRef.current) / maxScroll;
      const newTitleOpacity = 1 - scrollYRef.current / maxScroll;

      setLogoPosition({
        top: `${newTop}px`,
        left: `${newLeft}%`,
        scale: 1 - (scrollYRef.current / maxScroll) * 0.3,
      });

      setTranslate({
        x: `${newTranslateX}%`,
        y: `${newTranslateY}%`,
      });

      setTitleStyle((prevStyle) => ({
        ...prevStyle,
        top: `${newTitleTop}px`,
        opacity: newTitleOpacity,
      }));
      setTextOpacity(1 - scrollYRef.current / maxScroll);
      setBoxScale(0.55 + (scrollYRef.current / maxScroll) * 0.45);
    } else {
      // Fix position after maxScroll
      setLogoPosition({
        top: `20px`,
        left: `0%`,
        scale: 0.7,
      });
      setTranslate({
        x: `-50%`,
        y: `0%`,
      });
      setTextOpacity(0);
      setBoxScale(1);

      setTitleStyle((prevStyle) => ({
        ...prevStyle,
        top: `${INITIAL_TITLE_TOP - TITLE_MOVEMENT}px`,
        opacity: 0,
      }));
    }
  };

  const [lastScrollTime, setLastScrollTime] = useState(Date.now());
  const lastScrollTimeRef = useRef(Date.now());
  const isTrackpadLikely = useRef(false);

  useEffect(() => {
    // Handle scroll events for mouse scroll
    // const handleWheel = (e) => {
    //   if (e.ctrlKey) return;
    //   e.preventDefault();
    //   const deltaY = e.deltaY;
    //   updateScroll(deltaY);
    // };

    const handleWheel = (e) => {
      // Prevent default behavior to control scrolling manually
      e.preventDefault();

      // Determine if the event is from a trackpad or mouse wheel
      const isTrackpad =
        e.deltaMode === WheelEvent.DOM_DELTA_PIXEL && Math.abs(e.deltaY) < 200;
      const isMouseWheel =
        e.deltaMode === WheelEvent.DOM_DELTA_LINE ||
        e.deltaMode === WheelEvent.DOM_DELTA_PAGE;

      // If scrollPosition is at or beyond 700
      if (scrollPosition >= 700) {
        if (isTrackpad) {
          // Disable trackpad scrolling by ignoring the event
          return;
        }
        // Allow mouse wheel scrolling to enable scrolling back up
      }

      // Handle the scroll normally
      updateScroll(e.deltaY);
    };

    // Handle touch events for touch scrolling
    const handleTouchStart = (e) => {
      touchStartYRef.current = e.touches[0].clientY;
    };

    // const handleTouchMove = (e) => {
    //   e.preventDefault();

    //   // Disable scrolling if scrollPosition is at the max value
    //   if (scrollPosition === 700) return;

    //   const currentY = e.touches[0].clientY;
    //   const deltaY = touchStartYRef.current - currentY;

    //   updateScroll(deltaY);
    //   touchStartYRef.current = currentY;
    // };

    const handleTouchMove = (e) => {
      e.preventDefault();

      // Disable scrolling if scrollPosition is at the max value
      if (scrollPosition === 700) return;

      const currentY = e.touches[0].clientY;
      const deltaY = touchStartYRef.current - currentY;

      // Multiply deltaY by a factor to increase the scroll sensitivity
      const scaledDeltaY = deltaY * 5; // Increase scroll distance by a factor of 2

      updateScroll(scaledDeltaY);
      touchStartYRef.current = currentY;
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("touchstart", handleTouchStart, { passive: false });
    window.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [scrollPosition]);

  const scrollToTop = () => {
    updateScroll(-700);
  };

  const [infoIconSize, setInfoIconSize] = useState(66);
  const [refreshIconSize, setRefreshIconSize] = useState(70);

  useEffect(() => {
    // Function to update icon sizes based on screen width
    const updateIconSizes = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setInfoIconSize(80); // Increase info icon size in phone view
        setRefreshIconSize(84); // Increase refresh icon size in phone view
      } else {
        setInfoIconSize(66); // Default size for info icon
        setRefreshIconSize(70); // Default size for refresh icon
      }
    };

    // Initial check
    updateIconSizes();

    // Add event listener to handle screen resize
    window.addEventListener("resize", updateIconSizes);

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", updateIconSizes);
  }, []);

  return (
    <div
    //  style={{ height: "2000px" }}
    >
      <div
        className="home-page"
        style={{
          overflow: "hidden",
          position: "relative",
          width: "100%",
          height: "100vh",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="social-media-sidebar"
          style={{
            opacity: titleStyle.opacity,
            transition: "opacity 0.9s ease",
            pointerEvents: titleStyle.opacity === 0 ? "none" : "auto",
          }}
        >
          <a
            href="https://www.instagram.com/showcaseappco" // Replace with your Instagram URL
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
            aria-label="Instagram"
          >
            <FaInstagram size={24} />
          </a>
          <a
            href="https://x.com/ShowcaseAppCo" // Replace with your Twitter URL
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
            aria-label="Twitter"
          >
            <FaXTwitter size={24} />
          </a>
          <a
            href="https://www.tiktok.com/@showcaseappco?_t=8qtVNZDCagK&_r=1" // Replace with your TikTok URL
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
            aria-label="TikTok"
          >
            <IoLogoTiktok size={24} />
          </a>
          {/* <a
            href="https://www.tiktok.com/@showcaseappco?_t=8qtVNZDCagK&_r=1" // Replace with your TikTok URL
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
            aria-label="TikTok"
            style={{ marginLeft: "-1px" }}
          >
            <MdOutlineEmail size={25} />
          </a> */}
          <button
            className="social-icon"
            aria-label="Contact Us"
            style={{
              marginLeft: "-2px",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              padding: 0,
              marginTop: "2px",
            }}
            onClick={handleOpenContactModal}
          >
            <MdOutlineEmail size={25} />
          </button>
        </div>
        <div
          className="blue-semicircle no-select"
          style={{
            position: "fixed",
            // bottom: "-66%",
            bottom: `${semiCirclePosition}%`, // Use dynamic bottom position
            //  bottom: `calc(10vh + 60px - ${(window.innerHeight - 742.4) / 2}px)`,
            // left: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // transform: `scale(${boxScale * 3}) translate(-50%, -50%)`, // Updated transform

            // width: "1200px",
            // // width: "77.5vw",

            // // height: "600px",
            // height: "39.1vw",
            width: semiCircleSize.width, // Apply dynamic width
            height: semiCircleSize.height,
            backgroundColor: "#79b8ff",
            // borderRadius: "600px 600px 0 0",
            // borderRadius: "semiCircleSize.height semiCircleSize.height 0 0",
            borderRadius: `${semiCircleSize.height} ${semiCircleSize.height} 0 0`,

            opacity: 0.8,
            maskImage: `linear-gradient(to top, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 190%)`,
            WebkitMaskImage: `linear-gradient(to top, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 190%)`,
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
            zIndex: 0,
            transition: "bottom 0.3s ease-in-out",
            userSelect: "none",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        >
          <img
            src="/Gradient2.png"
            alt="Background Gradient"
            style={{
              width: "105%",
              height: "80%",
              marginTop: "-40vh",
              objectFit: "cover",
              left: "50%",
              opacity: 0.4,
              zIndex: "-1",
              transform: "translate(0%, 50%)", // Center the box

              maskImage: `
        linear-gradient(to bottom, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 30%),
        radial-gradient(circle, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)
      `,
              WebkitMaskImage: `
        -webkit-linear-gradient(to bottom, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 30%),
        -webkit-radial-gradient(circle, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)
      `,
            }}
          />
        </div>
        <div
          className="whole-area no-select"
          style={{
            opacity: 1,
            marginTop: "60vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // transform: `scale(${boxScale})`, // Updated transform
            transform: `scale(${boxScale}) translateY(-${boxTranslateY}px)`, // Updated transform
            boxShadow: "5px 5px 14px 5px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            backgroundColor: "#fff",
            zIndex: 1,
            transition: "transform 0.3s ease-in-out",
            userSelect: "none",
          }}
        >
          <div
            className={`info-button no-select icon-button ${
              isInfoClicked ? "clicked" : ""
            }`}
            style={{
              pointerEvents: "auto",
              position: "fixed",
              // bottom: "20px",
              // right: "65px",
              top: "10px",
              left: "-76px",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              outline: "none",
              zIndex: 1000,
              opacity: titleStyle.opacity,
              transition: "opacity 0.9s ease",
              // opacity: 0.8,
            }}
          >
            <RiInformationFill
              className="info-icon"
              // size={66}
              size={infoIconSize}
              // color="#79b8ff"
              color="#abd2ff"
              onClick={handleInfoClick}
              onTouchStart={handleInfoClick}
            />
            <div className="tooltip-text">Switch to Information Collection</div>
          </div>
          <div
            className={`refresh-but no-select icon-button ${
              isRefreshClicked ? "clicked" : ""
            }`}
            style={{
              pointerEvents: "auto",
              position: "fixed",
              // bottom: "20px",
              // right: "20px",
              top: "84px",
              left: "-78px",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              outline: "none",
              zIndex: 1000,
              opacity: titleStyle.opacity,
              transition: "opacity 0.9s ease",
              // opacity: 0.8,
            }}
          >
            <IoMdRefreshCircle
              // size={70}
              size={refreshIconSize}
              // color="#79b8ff"
              color="#abd2ff"
              onClick={handleRefreshClick}
              onTouchStart={handleRefreshClick}
            />
            <div className="tooltip-text">Switch to a random Collection</div>
          </div>
          <Home_Collection_Page
            artefacts={artefacts}
            artefactData={artefactData}
            setArtefactData={setArtefactData}
            setSharelinkUsername={props.setSharelinkUsername}
            submittedForm={props.submittedForm}
            setSubmittedForm={props.setSubmittedForm}
            placingMode={props.placingMode}
            setPlacingMode={props.setPlacingMode}
            currentCollection={props.currentCollection}
            handleCreateButtonClick={props.handleCreateButtonClick}
            placeArtefactsTrigger={props.placeArtefactsTrigger}
            setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
            centerScreen={props.centerScreen}
            setCenterScreen={props.setCenterScreen}
            isScreenCentered={props.isScreenCentered}
            setIsScreenCentered={props.setIsScreenCentered}
            centerScreenHandler={props.centerScreenHandler}
            og_username={props.og_username}
            filter={props.filter}
            setFilter={props.setFilter}
            isPublicView={props.isPublicView}
            setIsPublicView={props.setIsPublicView}
            loggedInFullname={props.loggedInFullname}
            setLoggedInFullname={props.setLoggedInFullname}
            isInteractable={isMaxScroll}
            linkId={linkId}
            // setLinkId={setLinkId}
            isPhoneMode={isPhoneMode}
            homePageFlag={homePageFlag}
            setHomePageFlag={setHomePageFlag}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "50px", // Adjust based on desired fade length
              // background:
              //   "linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))",
              pointerEvents: "none",
              zIndex: "20",
            }}
          />
        </div>
        <div
          style={{
            position: "fixed",
            top: 0,
            // transform: `translateX(${navbarTranslateX}px)`,
            transform: `translateX(${navbarTranslateX}px) scale(${navbarScale})`,
            zIndex: 20,
            width: "100%",
            userSelect: "none",
          }}
        >
          <HomeNavbar
            homePageFlag={homePageFlag}
            setHomePageFlag={setHomePageFlag}
          />
          <div
            className="center-button-container"
            style={{
              position: "absolute", // Changed from 'fixed' to 'absolute'
              // left: "calc(50% + 208px)", // Align it exactly 10px to the right of the navbar
              left: "calc(50% + 158px)", // Align it exactly 10px to the right of the navbar

              // top: "30px", // Align with the navbar's top position
              top: "29px", // Align with the navbar's top position

              // pointerEvents: "auto",
              // pointerEvents: props.isScreenCentered ? "none" : "auto",
              pointerEvents:
                props.isScreenCentered || 1 - titleStyle.opacity === 0
                  ? "none"
                  : "auto",
              opacity: props.isScreenCentered ? 0 : 1 && 1 - titleStyle.opacity,

              transition: "opacity 0.5s ease-in-out",
              zIndex: 11, // Ensure it is above other elements
            }}
          >
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                outline: "none",
              }}
              onClick={props.centerScreenHandler}
            >
              <MdOutlineFilterCenterFocus size={30} color="#9D9D9D" />
            </button>
          </div>
        </div>
        {isPhoneMode && (
          <div
            className="logo-container-phone no-select"
            style={{
              display: "flex",
              flexDirection: "column", // Stack the content vertically
              alignItems: "center", // Center horizontally
              position: "fixed", // Fixes the logo to the bottom of the page
              bottom: "4px", // Adjust positioning from the bottom
              left: "50%", // Horizontally center
              transform: "translateX(-50%)", // Ensure it's centered properly
              zIndex: 1000, // Ensure it stays on top of other content
              pointerEvents: "none", // Prevent interaction with the logo
              opacity: 1 - titleStyle.opacity, // Apply opposite opacity of titleStyle.opacity
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logoIcon}
                style={{
                  height: "43px",
                  marginTop: "11px",
                  marginLeft: "2px",
                }}
                alt="Logo Icon"
                className="logo-icon-phone"
              />
              <img
                src={logoShowcase}
                style={{
                  height: "65px",
                  marginTop: "0px",
                  marginLeft: "7px",
                }}
                alt="Logo Showcase"
                className="logo-showcase-phone"
              />
            </div>

            <div
              className="url-text-phone"
              style={{
                marginTop: "-10px",
                fontSize: "14px",
                color: "#000",
                textAlign: "center",
                pointerEvents: "auto",
                fontWeight: "300",
              }}
            >
              showcase-app.co
            </div>
          </div>
        )}

        <div
          className="centered-box no-select"
          style={{
            position: "absolute",
            // top: logoPosition.top,
            // left: logoPosition.left,
            // top: "20%",
            // top: "160px",
            top: hasStartedScrolling ? "150px" : "20%",

            left: "50%",
            // transform: `translate(${translate.x}, ${translate.y})`,
            // padding: "20px",
            // height: "400px",
            // width: "640px",
            // borderRadius: "8px",
            zIndex: 7,
            // transition: "top 0.3s ease-in-out, left 0.3s ease-in-out",
            // userSelect: "none",
            // opacity: textOpacity, // Added opacity
            userSelect: "none",
            // pointerEvents: titleStyle.opacity === 0 ? "none" : "auto",
            pointerEvents: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              // left: "20%",
              // top: "20%",
              top: logoPosition.top,
              left: logoPosition.left,
              // transform: `translate(${translate.x}, ${translate.y})`,
              transform: `translate(${translate.x}, ${translate.y}) scale(${logoPosition.scale})`,
              userSelect: "none",
              // transition: "transform 0.5s ease-in-out",
              transition: "transform 0.3s ease-in-out",
              opacity: isPhoneMode ? titleStyle.opacity : 1,
            }}
            className="logo-container-home no-select"
          >
            <img
              className="logo-icon-home"
              src={logoIcon}
              alt="Logo Icon"
              style={{
                height: "67px",
                marginRight: "11px",
                pointerEvents: "none",
                userSelect: "none",
              }}
            />
            <img
              className="logo-showcase-home"
              src={logoShowcase}
              alt="Logo Showcase"
              style={{
                height: "100px",
                pointerEvents: "none",
                userSelect: "none",
              }}
            />
          </div>
          <h2
            className="curate-text no-select"
            style={{
              marginTop: "11px",
              fontSize: "1.5rem",
              color: "#333",
              // marginTop: "-15px",
              // marginBottom: "-10px",

              textAlign: "center",
              // top: "270px",
              // left: "50%",
              top: titleStyle.top,
              // left: "-35%",
              // left: "50%",
              transform: "translateX(-50%)",
              // transform: "translateX(-150%)",
              // transform: `translate(${translate.x}, ${translate.y}) scale(${logoPosition.scale})`,
              opacity: titleStyle.opacity,
              pointerEvents: titleStyle.opacity === 0 ? "none" : "auto",
              // transition: "top 0.3s ease, opacity 0.3s ease",
              // position: "fixed",
              position: "absolute",

              whiteSpace: "nowrap",
              userSelect: "none",
              // transition: "transform 0.5s ease-in-out",
              transition: "top 0.3s ease-in-out",
            }}
          >
            <span
              className="no-select"
              style={{ fontSize: "30px", fontFamily: "raleway" }}
            >
              Curate your{" "}
            </span>
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#e4e4e4",
                borderRadius: "5px",
                padding: "4px 10px 3px 10px",
                marginLeft: "5px",
                transition: "width 0.5s ease", // Smooth width transition
                width: `${boxWidth}px`, // Dynamic width based on word length
                textAlign: "center",
                whiteSpace: "nowrap", // Prevent text from wrapping
                fontSize: "30px",
                userSelect: "none",
                pointerEvents: titleStyle.opacity === 0 ? "none" : "auto",
                fontFamily: "raleway",
                // backdropFilter: "blur(1px)", // Applies a Gaussian blur with a radius of 10px
                // opacity: 0.9,
                // WebkitBackdropFilter: "blur(1px)", // Ensures compatibility with Safari
                // transition: "transform 0.5s ease-in-out",
              }}
            >
              <span
                ref={wordRef} // Reference to the word span
                style={{
                  display: "inline-block",
                  transition: "opacity 0.5s ease-in-out",
                  opacity: fadeIn ? 1 : 0,
                  whiteSpace: "nowrap",
                  pointerEvents: titleStyle.opacity === 0 ? "none" : "auto",
                }}
              >
                {currentWord}
              </span>
            </span>
          </h2>
          <div className="text-div">
            <ul
              className="home-features-ul"
              style={{
                marginTop: "30px",
                fontSize: "1rem",
                color: "#555",
                listStyleType: "disc",
                // paddingLeft: "20px",
                position: "relative",
                textAlign: "center",
                alignItems: "center",
                lineHeight: "1.1",
                fontFamily: "raleway",
                fontSize: "20px",
              }}
            >
              <div
                className="home-features no-select"
                style={{
                  borderRadius: "8px",
                  width: "100%",
                  height: "130px",
                  padding: "0px 0px 0px 0px",
                  display: "flex", // Use flexbox to arrange items in a single line
                  justifyContent: "center", // Center items horizontally
                  alignItems: "center", // Center items vertically
                  marginTop: "-110px",
                  // marginTop: "-55px",
                  top: titleStyle.top,
                  // left: "50%",
                  // transform: `translateX(-50%)`,
                  left: "-50%",
                  // transform: `translate(${translate.x}, ${translate.y}) scale(${logoPosition.scale})`,
                  opacity: titleStyle.opacity,
                  pointerEvents: titleStyle.opacity === 0 ? "none" : "auto",
                  // transition: "top 0.3s ease, opacity 0.3s ease",
                  position: "absolute",
                  userSelect: "none",
                  zIndex: "-1",
                  // transition: "top 0.05s ease-in-out",
                  transition: "top 0.3s ease-in-out",
                }}
              >
                <span
                  className="store-text"
                  style={{ marginRight: "30px", whiteSpace: "nowrap" }}
                >
                  Store and organise media
                </span>

                <span
                  className="share-text"
                  style={{ marginRight: "30px", whiteSpace: "nowrap" }}
                >
                  Selectively share content
                </span>
                <span
                  className="discover-text"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Discover new interests
                </span>
              </div>
            </ul>
            <span
              // className="animated-gradient-text"
              className="scroll-text no-select"
              style={{
                whiteSpace: "nowrap",
                marginTop: "100px",
                fontFamily: "raleway",
                // top: titleStyle.top,
                bottom: `${interactArrow}%`, // Use dynamic bottom position
                // left: "50%",
                left: "-20.5%",
                opacity: interactArrowOpacity,
                position: "absolute",
                color: "#9f9f9f",
                userSelect: "none",
                transition: "bottom 0.3s ease-in-out",
              }}
            >
              <span
                // className="animated-gradient-text"
                className="no-select"
                style={{
                  whiteSpace: "nowrap",
                  height: "2px",
                  width: "4px",
                  fontSize: "12px",
                  padding: "0px 3px 10px 0px",
                  marginTop: "-3px",
                  marginBottom: "10px",
                  position: "relative", // Use relative positioning
                  top: "-2px", // Move the element up by 3px
                  letterSpacing: "1px",
                  userSelect: "none",
                }}
              >
                ▼
              </span>
              Scroll down to interact
            </span>
          </div>
        </div>
        <button
          onClick={scrollToTop}
          className="scroll-to-top"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            // marginRight: "20px",
            background: "transparent",
            border: "none",
            cursor: "pointer",
            outline: "none",
            zIndex: 1000,
            transition: "opacity 0.9s ease",
            opacity: scrollYRef.current > 300 ? 1 : 0, // Show button after scrolling down 100px
            pointerEvents: scrollYRef.current > 300 ? "auto" : "none",
          }}
          aria-label="Scroll to Top"
        >
          <IoArrowUpCircle size={48} color="#79b8ff" />
        </button>
        <ContactUsModal
          show={isContactModalOpen}
          handleClose={handleCloseContactModal}
        />
      </div>
    </div>
  );
};

export default Home;
