import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SessionManager = ({ children, setLoggedIn, setLoggedInUsername }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSessionStatus = async () => {
      const { pathname } = window.location;

      // Define a list of exact paths to skip session check
      const skipPaths = [
        "/signin",
        "/signup",
        "/Signup",
        "/",
        "/terms-conditions",
        "/privacy-policy",
        "/verify-signup",
      ];

      // Skip session check for specific paths and patterns
      const homePattern = /^\/home-\d+$/i;

      if (
        skipPaths.includes(pathname) || // Check for exact paths
        pathname.startsWith("/share/") || // Handle dynamic path like /share/:linkId
        pathname === "/analytics" || // Handle analytics page
        homePattern.test(pathname)
      ) {
        return; // Avoid checking session for these paths
      }

      try {
        const response = await fetch("/api/check_signin", {
          method: "GET",
          credentials: "same-origin",
        });
        if (response.status === 401) {
          setLoggedIn(false);
          setLoggedInUsername(null);
          navigate("/signin");
        }
      } catch (error) {
        console.error("Failed to check session status:", error);
      }
    };

    const intervalId = setInterval(checkSessionStatus, 5 * 60 * 1000); // Run session check every 5 mins
    checkSessionStatus();

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [navigate, setLoggedIn, setLoggedInUsername]);

  return <>{children}</>; // Render the children (your app's components)
};

export default SessionManager;
