import React, { useRef, useState, useEffect } from "react";
import CollectionsList from "./CollectionsList";
import ArtefactsList from "./ArtefactsList";
import ProfileBox from "./ProfileBox";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import { createArtefact } from "../api/createArtefact";
import { createCollection } from "../api/createCollection";
import { moveArtefact } from "../api/moveArtefact";
import { moveCollection } from "../api/moveCollection";
import "../css/PannableBox.css";
import PreviewItems from "./PreviewItems";
import CreateArtefactModal from "./Modals/CreateArtefactModal";
import { updateCollectionName } from "../api/updateCollectionName";
import { moveCollectionBothCoords } from "../api/moveCollectionBothCoords";

export default function PannableBox({
  collections,
  placingMode,
  setPlacingMode,
  getter,
  setter,
  currentCollection,
  collectionData,
  setCollectionData,
  artefactData,
  artefacts,
  setArtefacts,
  setCollections,
  placeArtefactsTrigger,
  setPlaceArtefactsTrigger,
  centerScreen,
  setCenterScreen,
  setIsScreenCentered,
  loggedInUsername,
  isPublicView,
  otherUser,
  setArtefactData,
  handleCreateButtonClick,
  zoomedInFlag,
  setZoomedInFlag,
  isOwner,
  og_username,
  droppedImageFiles,
  setDroppedImageFiles,
  sharelinkUsername,
  setSharelinkUsername,
  sharelinkFullname,
  setSharelinkFullname,
  searchResults,
  setSearchResults,
  loggedInFullname,
  setCollectionRefresh,
  setArtefactRefresh,
  moveUpZoomSlider,
  isFetching,
  homePageFlag,
  artefactsToPlace,
  setArtefactsToPlace,
  privacySettingsVersion,
}) {
  const outerDivRef = useRef(null);
  const innerDivRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [isDragging, setIsDragging] = useState(false);
  const [velocityX, setVelocityX] = useState(0);
  const [velocityY, setVelocityY] = useState(0);
  const [lastTouchTime, setLastTouchTime] = useState(null);
  const [artefactClicked, setArtefactClicked] = useState(false);
  const [isPlacementCooldown, setIsPlacementCooldown] = useState(false);

  // const [showFirstCreationMessage, setShowFirstCreationMessage] =
  //   useState(false);
  // const [isFirstArtefact, setIsFirstArtefact] = useState(true);

  const [coordinates, setCoordinates] = useState(null);

  const cellSize = 20;
  const pannableBoxHeight = 2500;
  const pannableBoxWidth = 3500;

  const halfBoxHeight = pannableBoxHeight / 2;
  const halfBoxWidth = pannableBoxWidth / 2;

  const [innerDivTop, setInnerDivTop] = useState(
    -halfBoxHeight + window.innerHeight / 2
  );

  const [innerDivLeft, setInnerDivLeft] = useState(
    -halfBoxWidth + window.innerWidth / 2
  );

  const backgroundSize = `${cellSize}px ${cellSize}px`;

  const [correctionVelocityX, setCorrectionVelocityX] = useState(0);
  const [correctionVelocityY, setCorrectionVelocityY] = useState(0);

  const [previewArtefact, setPreviewArtefact] = useState(null);
  const [previewCollection, setPreviewCollection] = useState(null);

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const [imageDimensions, setImageDimensions] = useState(null);

  const [mouseDownPos, setMouseDownPos] = useState(null);
  const [touchStartPos, setTouchStartPos] = useState(null);

  const [followingButtonExplore, setFollowingButtonExplore] = useState(true);
  const [selectedType, setSelectedType] = useState(null);

  const [editingArtefactId, setEditingArtefactId] = useState(null);
  const [editingCollectionId, setEditingCollectionId] = useState(null);

  const [collectionCreated, setCollectionCreated] = useState(false);

  const addedPixelScreenCentre = 2; //fixes the screen being slightly off centre

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  console.log("newnewb", artefactData);
  const [artefactsQueue, setArtefactsQueue] = useState([]);
  const [currentArtefact, setCurrentArtefact] = useState(null);

  useEffect(() => {
    console.log("newnew update multi 9", artefactsQueue);

    if (placeArtefactsTrigger && artefactsToPlace.length > 0) {
      setArtefactsQueue(artefactsToPlace);
      setCurrentArtefact(artefactsToPlace[0]);
      setTotalArtefactsToPlace(artefactsToPlace.length);
      setArtefactsToPlace([]); // Clear the array after queuing
      setPlacingMode(true); // Enter placing mode
    }
  }, [
    placeArtefactsTrigger,
    artefactsToPlace,
    setArtefactsToPlace,
    setPlacingMode,
  ]);

  const [zoomLevel, setZoomLevel] = useState(1);
  const [showSlider, setShowSlider] = useState(false);
  // const zoomLevels = [0.5, 0.75, 1.0, 1.25, 1.5];
  const zoomLevels = [0.6, 0.8, 1.0, 1.2, 1.4];

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const sliderPositionClass =
    screenWidth < 1210 ? "bottom-center" : "top-right";

  const getClosestZoomLevel = (currentZoom) => {
    return zoomLevels.reduce((prev, curr) =>
      Math.abs(curr - currentZoom) < Math.abs(prev - currentZoom) ? curr : prev
    );
  };

  const handleZoom = (event) => {
    event.preventDefault();

    const zoomStep = 0.2; // Adjust the zoom step to fit new zoom levels
    let newZoomLevel = zoomLevel;

    if (event.deltaY < 0) {
      newZoomLevel = Math.min(zoomLevel + zoomStep, 1.4); // Max zoom level
    } else {
      newZoomLevel = Math.max(zoomLevel - zoomStep, 0.6); // Min zoom level
    }

    // Get the cursor position relative to the viewport
    const rect = outerDivRef.current.getBoundingClientRect();
    const cursorX = event.clientX - rect.left; // Mouse X relative to the viewport
    const cursorY = event.clientY - rect.top; // Mouse Y relative to the viewport

    // Calculate the current cursor position in the zoomed content
    const currentZoomOriginX = (cursorX - innerDivLeft) / zoomLevel;
    const currentZoomOriginY = (cursorY - innerDivTop) / zoomLevel;

    // Calculate the new inner div's left and top positions to maintain the zoom focus
    const newLeft = cursorX - currentZoomOriginX * newZoomLevel;
    const newTop = cursorY - currentZoomOriginY * newZoomLevel;

    setZoomLevel(newZoomLevel);
    setInnerDivLeft(newLeft);
    setInnerDivTop(newTop);

    triggerSliderVisibility();
  };

  // const handleSliderChange = (event) => {
  //   const value = parseFloat(event.target.value);

  //   // Snap to the nearest zoom level
  //   const snappedZoomLevel = getClosestZoomLevel(value);

  //   setZoomLevel(snappedZoomLevel);
  //   triggerSliderVisibility();
  // };

  const handleSliderChange = (event) => {
    const value = parseFloat(event.target.value);

    // Snap to the nearest zoom level
    const snappedZoomLevel = getClosestZoomLevel(value);

    // Get the cursor position relative to the viewport (for zoom center)
    const rect = outerDivRef.current.getBoundingClientRect();
    const viewportCenterX = window.innerWidth / 2; // Zoom from the center of the viewport
    const viewportCenterY = window.innerHeight / 2;

    // Calculate the current center position in the zoomed content
    const currentZoomOriginX = (viewportCenterX - innerDivLeft) / zoomLevel;
    const currentZoomOriginY = (viewportCenterY - innerDivTop) / zoomLevel;

    // Calculate the new inner div's left and top positions to maintain the zoom focus
    const newLeft = viewportCenterX - currentZoomOriginX * snappedZoomLevel;
    const newTop = viewportCenterY - currentZoomOriginY * snappedZoomLevel;

    setZoomLevel(snappedZoomLevel);
    setInnerDivLeft(newLeft);
    setInnerDivTop(newTop);

    triggerSliderVisibility(); // Show the zoom slider
  };

  const triggerSliderVisibility = () => {
    setShowSlider(true);

    // Clear any existing timeout to avoid multiple timers
    if (window.sliderTimeout) {
      clearTimeout(window.sliderTimeout);
    }

    // Set timeout to hide the slider after 3 seconds
    window.sliderTimeout = setTimeout(() => {
      setShowSlider(false);
    }, 2200);
  };

  const handleWheelEvent = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
      // Handle your custom zoom functionality here
      handleZoom(event);
    } else {
      // For other wheel events, such as trackpad swipes
      handleTrackpadSwipe(event);
    }
  };

  useEffect(() => {
    const preventDefaultZoom = (e) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
      }
    };

    window.addEventListener("wheel", preventDefaultZoom, { passive: false });

    return () => {
      window.removeEventListener("wheel", preventDefaultZoom);
    };
  }, []);

  useEffect(() => {
    // Disable browser back/forward navigation on trackpad swipe
    const preventNavigationGestures = (e) => {
      // Check for trackpad gesture by looking at the event properties
      if (e.ctrlKey || e.metaKey || e.deltaX !== 0) {
        e.preventDefault();
      }
    };

    // Prevent trackpad navigation on wheel event
    window.addEventListener("wheel", preventNavigationGestures, {
      passive: false,
    });

    // Prevent touch gestures like swipe navigation
    const preventTouchNavigation = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    window.addEventListener("touchmove", preventTouchNavigation, {
      passive: false,
    });

    return () => {
      // Cleanup event listeners when the component unmounts
      window.removeEventListener("wheel", preventNavigationGestures);
      window.removeEventListener("touchmove", preventTouchNavigation);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Detect if the Ctrl or Meta (Command) key is pressed along with '+' or '-'
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "=" || event.key === "-" || event.key === "+")
      ) {
        event.preventDefault(); // Prevent the default browser zoom behavior

        const zoomStep = 0.2; // Adjust the zoom step to fit your zoom levels
        let newZoomLevel = zoomLevel;

        if (event.key === "=" || event.key === "+") {
          // Zoom in
          newZoomLevel = Math.min(zoomLevel + zoomStep, 1.4); // Max zoom level
        } else if (event.key === "-") {
          // Zoom out
          newZoomLevel = Math.max(zoomLevel - zoomStep, 0.6); // Min zoom level
        }

        // Get the current center of the viewport for zoom focus
        const viewportCenterX = window.innerWidth / 2;
        const viewportCenterY = window.innerHeight / 2;

        // Calculate the current zoom origin in the zoomed content
        const currentZoomOriginX = (viewportCenterX - innerDivLeft) / zoomLevel;
        const currentZoomOriginY = (viewportCenterY - innerDivTop) / zoomLevel;

        // Adjust the inner div's position to maintain the zoom focus
        const newLeft = viewportCenterX - currentZoomOriginX * newZoomLevel;
        const newTop = viewportCenterY - currentZoomOriginY * newZoomLevel;

        // Update zoom level and inner div's position
        setZoomLevel(newZoomLevel);
        setInnerDivLeft(newLeft);
        setInnerDivTop(newTop);

        triggerSliderVisibility(); // Show the zoom slider
      }
    };

    // Add keydown event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [zoomLevel, innerDivLeft, innerDivTop]);

  useEffect(() => {
    // This effect runs whenever the location changes
    if (placingMode || placeArtefactsTrigger) {
      handleCancelAllPlacements();
      setTimeout(() => {
        setPreviewArtefact(null);
        setPreviewCollection(null);
      }, 0);
    }
  }, [location]);

  const calculateElasticOffset = (outerDivDim, innerDivDim, currentOffset) => {
    let offsetCorrection = 0;
    if (currentOffset > 0) {
      offsetCorrection = -currentOffset;
    } else if (currentOffset + innerDivDim < outerDivDim) {
      offsetCorrection = outerDivDim - (currentOffset + innerDivDim);
    }
    return offsetCorrection;
  };

  useEffect(() => {
    if (placeArtefactsTrigger) {
      let preview = null;

      if (currentArtefact) {
        // When moving an artefact
        preview = {
          name: currentArtefact.artefactName,
          coordinates: { x: 0, y: 0 },
          imageURL:
            currentArtefact.preemptive_upload || currentArtefact.imageURL,
          type: currentArtefact.type,
          content: currentArtefact.content,
        };
        setPreviewArtefact(preview);
      } else {
        // When uploading a new artefact
        console.log("currentArtefactbb4", artefactData);
        preview = {
          name: artefactData.artefactName,
          coordinates: { x: 0, y: 0 },
          imageURL: artefactData.preemptive_upload || artefactData.imageURL,
          type: artefactData.type,
          content: artefactData.bodyText,
        };
        setPreviewArtefact(preview);
      }
    }
  }, [placeArtefactsTrigger, currentArtefact, artefactData]);

  const handleCancelCurrentPlacement = () => {
    setArtefactsQueue((prevQueue) => {
      const newQueue = prevQueue.slice(1); // Remove the current artefact
      if (newQueue.length > 0) {
        // Set the next artefact as current and continue placement
        setCurrentArtefact(newQueue[0]);
        setPlaceArtefactsTrigger(true);
        handleCreateButtonClick();
      } else {
        // If it was the last artefact in the queue
        resetPlacementStates(); // Reset all states associated with placement
      }
      return newQueue;
    });
  };

  const resetPlacementStates = () => {
    setPlacingMode(false);
    setPlaceArtefactsTrigger(false);
    setPreviewArtefact(null);
    setPreviewCollection(null);
    setCollectionRefresh(true);
    setArtefactRefresh(true);
    setTotalArtefactsToPlace(0);
    setCurrentArtefact(null);
  };

  // Function to cancel all artefact placements
  const handleCancelAllPlacements = () => {
    setArtefactsQueue([]);
    setCurrentArtefact(null);
    setPlacingMode(false);
    setPlaceArtefactsTrigger(false);
    setPreviewArtefact(null);
    setPreviewCollection(null);
    setCollectionRefresh(true);
    setArtefactRefresh(true);
    setPendingParentChange({
      isPending: false,
      contextMenuStateCollectionId: null,
      newParentId: null,
    });
  };

  useEffect(() => {
    console.log("MULALAHAHAHA", placingMode, placeArtefactsTrigger);

    if (placingMode && !placeArtefactsTrigger) {
      console.log("MULALAHAHAHA 2", placingMode, placeArtefactsTrigger);

      const preview = {
        name: collectionData.collectionName,
        coordinates: { x: 0, y: 0 },
      };
      setPreviewCollection(preview);
    }
  }, [placingMode, collectionData.collectionName]);

  useEffect(() => {
    const updateCursorPosition = (event) => {
      const rect = innerDivRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCursorPosition({ x, y });
    };

    window.addEventListener("mousemove", updateCursorPosition);

    return () => {
      window.removeEventListener("mousemove", updateCursorPosition);
    };
  }, []);

  const [currentTransform, setCurrentTransform] = useState({ top: 0, left: 0 });
  const [isCentering, setIsCentering] = useState(false);
  const [isPanningDisabled, setIsPanningDisabled] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const centeringTimeoutRef = useRef(null);

  const handleArtefactClick = (x, y, height, width) => {
    // if (isCentering) return;

    setArtefactClicked(true);

    // Reset artefactClicked to false after a short delay
    setTimeout(() => {
      setArtefactClicked(false);
    }, 300);

    if (centeringTimeoutRef.current) {
      clearTimeout(centeringTimeoutRef.current);
      centeringTimeoutRef.current = null;
    }

    innerDivRef.current.style.transition = "";

    // Reset velocities to prevent interference
    setVelocityX(0);
    setVelocityY(0);
    setCorrectionVelocityX(0);
    setCorrectionVelocityY(0);

    const artefactCenterY =
      (y + height / 1114.5 / 2 + halfBoxHeight) * zoomLevel;
    const artefactCenterX = (x + width / 1114.5 / 2 + halfBoxWidth) * zoomLevel;

    const newLeft = window.innerWidth / 2 - artefactCenterX;
    const newTop = window.innerHeight / 2 - artefactCenterY;

    const animationDuration = 0.8;
    innerDivRef.current.style.transition = `transform ${animationDuration}s ease-out`;

    setIsCentering(true);

    setInnerDivTop(newTop);
    setInnerDivLeft(newLeft);

    centeringTimeoutRef.current = setTimeout(() => {
      innerDivRef.current.style.transition = ""; // Reset the transition property
      setIsCentering(false);
      centeringTimeoutRef.current = null;
    }, animationDuration * 1000);
  };

  const handleTrackpadSwipe = (e) => {
    // if (isCentering) return;
    if (isCentering || isPanningDisabled) return;

    e.preventDefault(); // Prevent the page from scrolling

    if (typeof homePageFlag !== "undefined" && homePageFlag) {
      const isMouseWheelEvent =
        e.deltaMode === 1 || // DOM_DELTA_LINE, typical for mouse wheel
        Math.abs(e.deltaY) >= 100; // Large deltaY values are typical for mouse wheel

      if (isMouseWheelEvent) {
        // Ignore mouse wheel events
        return;
      }
    }

    const sensitivity = 1;

    const deltaX = e.deltaX * sensitivity;
    const deltaY = e.deltaY * sensitivity;

    setInnerDivLeft((prevLeft) => prevLeft - deltaX);
    setInnerDivTop((prevTop) => prevTop - deltaY);

    applyBoundaryCorrection();
    setIsScreenCentered(false);
  };

  const applyBoundaryCorrection = () => {
    if (isCentering) return;
    if (!outerDivRef.current || !innerDivRef.current) return;

    const outerDivBounds = outerDivRef.current.getBoundingClientRect();
    const innerDivBounds = innerDivRef.current.getBoundingClientRect();

    const offsetXCorrection = calculateElasticOffset(
      outerDivBounds.width,
      innerDivBounds.width,
      innerDivBounds.left - outerDivBounds.left
    );
    const offsetYCorrection = calculateElasticOffset(
      outerDivBounds.height,
      innerDivBounds.height,
      innerDivBounds.top - outerDivBounds.top
    );

    if (Math.abs(offsetXCorrection) > 0 || Math.abs(offsetYCorrection) > 0) {
      requestAnimationFrame(animateCorrection);
    }
  };

  const animateCorrection = () => {
    if (!outerDivRef.current || !innerDivRef.current) return;

    const outerDivBounds = outerDivRef.current.getBoundingClientRect();
    const innerDivBounds = innerDivRef.current.getBoundingClientRect();

    let offsetXCorrection = calculateElasticOffset(
      outerDivBounds.width,
      innerDivBounds.width,
      innerDivBounds.left - outerDivBounds.left
    );
    let offsetYCorrection = calculateElasticOffset(
      outerDivBounds.height,
      innerDivBounds.height,
      innerDivBounds.top - outerDivBounds.top
    );

    setInnerDivLeft((prevLeft) => prevLeft + offsetXCorrection * 0.002);
    setInnerDivTop((prevTop) => prevTop + offsetYCorrection * 0.002);

    if (Math.abs(offsetXCorrection) > 1 || Math.abs(offsetYCorrection) > 1) {
      requestAnimationFrame(animateCorrection);
    }
  };

  const [rootCollectionId, setRootCollectionId] = useState(null);

  const [pendingParentChange, setPendingParentChange] = useState({
    isPending: false, // Boolean flag to indicate if a parent change is pending
    contextMenuStateCollectionId: null, // The ID of the collection whose parent is being changed
    newParentId: null, // The ID of the new parent collection
  });

  useEffect(() => {
    console.log("Pending parent change:", pendingParentChange);
    if (pendingParentChange.isPending) {
      setPlacingMode(true);

      console.log(
        "collectionData.collection_name, collectionData.collectionId",
        collectionData.collection_name
      );

      const preview = {
        name: collectionData.collection_name,
        coordinates: { x: 0, y: 0 },
      };
      setPreviewCollection(preview);

      console.log("pendingParentChange", pendingParentChange);

      setPendingParentChange({
        isPending: false,
        contextMenuStateCollectionId: null,
        newParentId: null,
      });
    }
  }, [placeArtefactsTrigger, pendingParentChange, collectionData.collectionId]);

  const changeCollection = async (collectionId, newParentCollectionId) => {
    try {
      if (!collectionId) {
        console.error("Invalid collection.");
        return;
      }

      console.log("newParentCollectionId: ", newParentCollectionId);
      const parentId =
        newParentCollectionId === "root" ? null : newParentCollectionId;

      console.log("newParentCollectionId: ", newParentCollectionId, parentId);
      const response = await fetch(
        `/api/user/${loggedInUsername}/collection/${collectionId}/change_parent`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            new_parent_id: parentId,
          }),
        }
      );

      if (response.ok) {
        console.log(
          `Collection ${collectionId} moved to ${
            newParentCollectionId === "root" ? "root" : newParentCollectionId
          }`
        );
        // Update collections state here
        setCollections((prevCollections) => {
          return prevCollections.map((col) => {
            if (col.collection_id === collectionId) {
              return {
                ...col,
                parent_id: parentId, // Update the parent ID
              };
            } else if (col.collection_id === newParentCollectionId) {
              return col.type !== "collections"
                ? { ...col, type: "collections" }
                : col;
            }
            return col;
          });
        });
      } else {
        const errorData = await response.json();
        console.error("Failed to move collection:", errorData.error);
        alert(errorData.error); // Display error message to the user
      }
    } catch (error) {
      console.error("Error in moving collection:", error);
    }
  };

  const placement_leeway = 10; // Allow a 50-pixel leeway for the second tap

  const handleMouseDown = async (e) => {
    console.log("newnew1", artefactData);
    if (isCentering) return;

    setIsCentering(false);

    e.preventDefault();
    const rect = innerDivRef.current.getBoundingClientRect();
    const offsetX = (e.clientX - rect.left) / zoomLevel;
    const offsetY = (e.clientY - rect.top) / zoomLevel;

    // Adjust for the center-based coordinate system
    const gridX = Math.floor((offsetX - halfBoxWidth) / cellSize) * cellSize;
    const gridY = Math.floor((offsetY - halfBoxHeight) / cellSize) * cellSize;

    // If this is a touch event, get the touch coordinates
    const touchX = e.touches ? e.touches[0].clientX : e.clientX;
    const touchY = e.touches ? e.touches[0].clientY : e.clientY;

    // Check if the second tap is within the leeway area of the first tap
    const isWithinLeeway = (x1, y1, x2, y2, leeway) => {
      return Math.abs(x1 - x2) <= leeway && Math.abs(y1 - y2) <= leeway;
    };

    if (mouseDownPos) {
      const isExactPosition =
        e.screenX === mouseDownPos.x && e.screenY === mouseDownPos.y;
      const isWithinLeewayDistance = isWithinLeeway(
        touchX,
        touchY,
        mouseDownPos.x,
        mouseDownPos.y,
        placement_leeway
      );
      console.log("newnew2", artefactData);
      console.log("currentArtefact", currentArtefact);
      if (isExactPosition || isWithinLeewayDistance) {
        console.log("currentArtefact2", currentArtefact, artefactData);
        console.log("Placement triggered", placingMode, placeArtefactsTrigger);

        if (placingMode) {
          setPlacingMode(false);
          var coordinates = { x: gridX, y: gridY };
          setCoordinates({ x: gridX, y: gridY });

          setPreviewArtefact(null);
          setPreviewCollection(null);

          // Your existing artefact placement logic
          if (placeArtefactsTrigger) {
            setPlaceArtefactsTrigger(false);

            // const artefactType = currentArtefact.type;
            console.log("newnew13", currentArtefact, artefactData);
            const artefactToUse = currentArtefact || artefactData;

            if (!artefactToUse) {
              console.error("No artefact data available for placement.");
              return;
            }

            const artefactType = artefactToUse.type;

            let content = null;

            switch (artefactType) {
              case "image":
                content = artefactToUse.imageURL;
                break;
              case "website":
                content = artefactToUse.websiteURL;
                break;
              case "text":
                content = JSON.stringify({
                  heading: artefactToUse.heading,
                  bodyText: artefactToUse.bodyText,
                });
                break;
              case "pdf":
                content = artefactToUse.pdfURL;
                break;
              default:
                console.warn("Unknown artefact type");
                return;
            }
            console.log("newnew3", artefactData);
            let status;
            if (artefactData.artefactId) {
              status = await moveArtefact({
                loggedInUsername,
                artefactId: artefactData.artefactId,
                isPublicView: isPublicView,
                coordx_private: isPublicView ? undefined : coordinates.x,
                coordy_private: isPublicView ? undefined : coordinates.y,
                coordx_public: isPublicView ? coordinates.x : undefined,
                coordy_public: isPublicView ? coordinates.y : undefined,
                is_public: artefactData.is_public,
                content,
                type: artefactType,
                sizing_choice: artefactData.sizing_choice,
                currentCollection,
              });
            } else {
              console.log("newnew update multi 1");
              status = await createArtefact({
                loggedInUsername,
                currentCollection,
                nameArtef: currentArtefact.artefactName,
                coordinates,
                content,
                is_public: currentArtefact.is_public,
                type: artefactType,
                width: currentArtefact.image_width,
                height: currentArtefact.image_height,
                sizing_choice: currentArtefact.sizing_choice,
              });
            }

            if (status === 200 || status === 201) {
              setArtefactRefresh(true);
              setter(!getter);

              localStorage.setItem("doubleClickText", "false");

              console.log("newnew update multi 2", artefactsQueue);

              setArtefactsQueue((prevQueue) => {
                const newQueue = prevQueue.slice(1);
                if (newQueue.length > 0) {
                  setCurrentArtefact(newQueue[0]);
                  setPlaceArtefactsTrigger(true);
                  handleCreateButtonClick();
                } else {
                  setPlacingMode(false);
                  setCurrentArtefact(null);
                  setPreviewArtefact(null);
                  setPlaceArtefactsTrigger(false);
                  // setArtefactsQueue([]);
                  setTotalArtefactsToPlace(0);
                  // handleCreateButtonClick(); // Exit placing mode
                }
                return newQueue;
              });
              // setCurrentArtefact(null);
              // setArtefactsQueue([]);
              // setTotalArtefactsToPlace(0);

              return;
            }
          } else {
            let status;
            console.log("newnew col col ");
            let changeCollectionCalled = false;
            if (collectionData.collectionId) {
              console.log("pendingParentChange2", pendingParentChange);

              if (
                currentCollection &&
                currentCollection.collection_id &&
                collectionData.dummyFlag
              ) {
                console.log("pendingParentChange3", pendingParentChange);
                // First attempt: Try moving to the current collection
                await changeCollection(
                  collectionData.collectionId,
                  currentCollection.collection_id
                );
                changeCollectionCalled = true;
              } else if (collectionData.dummyFlag) {
                console.log("pendingParentChange4", pendingParentChange);
                await changeCollection(
                  collectionData.collectionId,
                  rootCollectionId
                );
                changeCollectionCalled = true;
              }

              console.log("pendingParentChange6", collectionData);

              console.log("pendingParentChange7", collectionData);

              if (changeCollectionCalled) {
                status = await moveCollectionBothCoords({
                  loggedInUsername,
                  collectionId: collectionData.collectionId,
                  coordx_private: !isPublicView ? coordinates.x : undefined,
                  coordy_private: !isPublicView ? coordinates.y : undefined,
                  coordx_public: !isPublicView ? coordinates.x : undefined,
                  coordy_public: !isPublicView ? coordinates.y : undefined,
                  isPublicView,
                  collectionName: collectionData.collectionName,
                });
              } else {
                status = await moveCollection({
                  loggedInUsername,
                  collectionId: collectionData.collectionId,
                  coordx_private: !isPublicView ? coordinates.x : undefined,
                  coordy_private: !isPublicView ? coordinates.y : undefined,
                  coordx_public: isPublicView ? coordinates.x : undefined,
                  coordy_public: isPublicView ? coordinates.y : undefined,
                  isPublicView,
                  collectionName: collectionData.collectionName,
                });
              }
              console.log("pendingParentChange8", collectionData);
            } else {
              status = await createCollection({
                loggedInUsername,
                currentCollection,
                nameCol: collectionData.collectionName,
                coordinates,
              });
            }
            if (status === 200 || status === 201) {
              setCollectionRefresh(true);
              setter(!getter);
              setCollectionCreated(true);

              return;
            }
          }
          setIsPlacementCooldown(true);
          setTimeout(() => {
            setIsPlacementCooldown(false);
          }, 500);
        }
      }
    }

    // Update the mouseDownPos with the current tap position
    setMouseDownPos({
      x: touchX,
      y: touchY,
    });

    const initialTouchPos = { x: e.screenX, y: e.screenY };

    const initialX = e.clientX;
    const initialY = e.clientY;
    let currentX = initialX;
    let currentY = initialY;
    let initialTop = innerDivTop;
    let initialLeft = innerDivLeft;

    innerDivRef.current.style.transition = "";

    setIsDragging(true);
    const handleMouseMove = (e) => {
      e.preventDefault();
      if (!isDragging) {
        currentX = e.clientX;
        currentY = e.clientY;
        const diffX = currentX - initialX;
        const diffY = currentY - initialY;
        setInnerDivTop(initialTop + diffY);
        setInnerDivLeft(initialLeft + diffX);
      }
    };
    const handleMouseUp = async (e) => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      setIsDragging(false);
      setVelocityX(currentX - initialX);
      setVelocityY(currentY - initialY);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  useEffect(() => {
    if (artefactsQueue.length === 0 && !placingMode) {
      setPreviewArtefact(null);
      setPreviewCollection(null);
      setPlaceArtefactsTrigger(false);
      setShowCancelPlacement(true);
    }
  }, [artefactsQueue.length, placingMode]);

  const [initialPinchDistance, setInitialPinchDistance] = useState(null);
  const [initialZoomLevel, setInitialZoomLevel] = useState(zoomLevel);
  const [isPinching, setIsPinching] = useState(false);

  const handleTouchStart = (e) => {
    // if (isCentering) return;
    if (
      isCentering ||
      isPlacementCooldown
      // placingMode ||
      // placeArtefactsTrigger
    ) {
      return; // Prevent centering if in placement cooldown or currently centering
    }

    // Handle pinch-to-zoom
    if (e.touches.length === 2) {
      setIsPinching(true);
      // Get the initial distance between the two touches
      const [touch1, touch2] = e.touches;
      const initialDistance = Math.sqrt(
        (touch2.clientX - touch1.clientX) ** 2 +
          (touch2.clientY - touch1.clientY) ** 2
      );
      setInitialPinchDistance(initialDistance);
      setInitialZoomLevel(zoomLevel); // Store the current zoom level
    } else if (e.touches.length === 1 && !isPinching) {
      // Only handle single touch if not in a pinch gesture
      setIsPinching(false);

      // Handle double-tap logic here
      const currentTime = Date.now();
      const tapGap = currentTime - lastTapTime;
      const tapX = e.touches[0].clientX;
      const tapY = e.touches[0].clientY;

      const isWithinLeeway = (x1, y1, x2, y2, leeway) => {
        return Math.abs(x1 - x2) <= leeway && Math.abs(y1 - y2) <= leeway;
      };

      // Check if the second tap is within the leeway area of the first tap
      const isValidDoubleTap =
        tapGap < 220 && // Check if the time between taps is short enough
        tapGap > 0 && // Avoid accidental taps
        isWithinLeeway(
          tapX,
          tapY,
          lastTapPosition.x,
          lastTapPosition.y,
          LEAWAY_DISTANCE
        );

      if (
        isValidDoubleTap &&
        !isPinching &&
        !placingMode &&
        !placeArtefactsTrigger &&
        !isPlacementCooldown &&
        !editingArtefactId &&
        !editingCollectionId
      ) {
        // Double-tap detected
        animateToCenter(); // Use the animation function to center the screen
        e.preventDefault();
      }

      // Update the last tap time and position
      setLastTapTime(currentTime);
      setLastTapPosition({ x: tapX, y: tapY });

      // Existing logic for handling single touch for panning
      const touch = e.touches[0];
      const initialX = touch.clientX;
      const initialY = touch.clientY;

      setTouchStartPos({
        x: initialX,
        y: initialY,
        top: innerDivTop,
        left: innerDivLeft,
      });

      setLastTouchTime(Date.now());
      setIsDragging(true);
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2) {
      // Handle pinch-to-zoom
      setIsPinching(true); // Make sure to set isPinching to true
      const [touch1, touch2] = e.touches;

      // Calculate the current pinch center point
      const pinchCenterX = (touch1.clientX + touch2.clientX) / 2;
      const pinchCenterY = (touch1.clientY + touch2.clientY) / 2;

      const currentDistance = Math.sqrt(
        (touch2.clientX - touch1.clientX) ** 2 +
          (touch2.clientY - touch1.clientY) ** 2
      );

      // Calculate the scale factor
      const scaleFactor = currentDistance / initialPinchDistance;

      let newZoomLevel = Math.max(
        0.6,
        Math.min(initialZoomLevel * scaleFactor, 1.4)
      ); // Clamp zoom level between 0.6 and 1.4

      // Snap to the nearest zoom level
      newZoomLevel = getClosestZoomLevel(newZoomLevel);

      // Adjust the inner div's position based on the zooming action
      // Determine the new top-left corner of the inner div
      const rect = outerDivRef.current.getBoundingClientRect();

      // Convert pinch center to inner div's scale and coordinate system
      const zoomOriginX = (pinchCenterX - rect.left - innerDivLeft) / zoomLevel;
      const zoomOriginY = (pinchCenterY - rect.top - innerDivTop) / zoomLevel;

      // Calculate the new inner div's left and top positions to maintain the zoom focus
      const newLeft = pinchCenterX - (zoomOriginX * newZoomLevel + rect.left);
      const newTop = pinchCenterY - (zoomOriginY * newZoomLevel + rect.top);

      setZoomLevel(newZoomLevel);
      setInnerDivLeft(newLeft);
      setInnerDivTop(newTop);

      triggerSliderVisibility(); // Show the slider to indicate zooming
    } else if (e.touches.length === 1 && !isPinching && isDragging) {
      // Handle panning only when not pinching
      const touch = e.touches[0];
      const currentX = touch.clientX;
      const currentY = touch.clientY;
      const diffX = currentX - touchStartPos.x;
      const diffY = currentY - touchStartPos.y;

      setInnerDivTop(touchStartPos.top + diffY);
      setInnerDivLeft(touchStartPos.left + diffX);
    }
  };

  const handleTouchEnd = (e) => {
    if (isCentering) return;

    if (e.touches.length < 2) {
      setIsPinching(false);
      // If fewer than 2 touches remain, reset pinch state
      setInitialPinchDistance(null);
      setInitialZoomLevel(null);

      setVelocityX(0);
      setVelocityY(0);
    }

    const touchEndTime = Date.now(); // Track the time of touch end
    const timeDiff = touchEndTime - lastTouchTime; // Calculate the time difference

    if (timeDiff > 0 && touchStartPos) {
      const touch = e.changedTouches[0];
      const endX = touch.clientX;
      const endY = touch.clientY;

      const velocityX = (endX - touchStartPos.x) / timeDiff;
      const velocityY = (endY - touchStartPos.y) / timeDiff;

      setVelocityX(velocityX * 100); // Scale velocity for momentum
      setVelocityY(velocityY * 100); // Scale velocity for momentum
    }

    setIsDragging(false);
    setTouchStartPos(null);

    if (!isPinching) {
      applyBoundaryCorrection(); // Apply boundary correction after touch ends
    } // Apply boundary correction after touch ends
  };

  // useEffect(() => {
  //   if (!isPinching) {
  //     // Stop any ongoing movement right after zooming
  //     setVelocityX(0);
  //     setVelocityY(0);
  //   }
  // }, [isPinching]);

  const [lastTapTime, setLastTapTime] = useState(0);

  const animateToCenter = () => {
    if (placingMode || placeArtefactsTrigger || isPlacementCooldown) {
      console.log("Centering prevented due to placement cooldown.");
      return; // Prevent centering if placement is on cooldown
    }
    // Get the viewport's width and height
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate the target center positions considering the current zoom level
    const centerX = viewportWidth / 2 - (pannableBoxWidth / 2) * zoomLevel;
    const centerY = viewportHeight / 2 - (pannableBoxHeight / 2) * zoomLevel;

    const startTop = innerDivTop;
    const startLeft = innerDivLeft;

    // Calculate the deltas for the animation
    const deltaX = centerX - startLeft;
    const deltaY = centerY - startTop;

    const duration = 800; // duration in milliseconds
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1); // progress between 0 and 1

      // Ease-in-out effect
      const easeInOut =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      // Adjust the top and left positions using the easing function
      setInnerDivTop(startTop + deltaY * easeInOut);
      setInnerDivLeft(startLeft + deltaX * easeInOut);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        // Set the final transform position to ensure accuracy
        setInnerDivTop(centerY);
        setInnerDivLeft(centerX);
        setCurrentTransform({ top: centerY, left: centerX });
        setIsScreenCentered(true);
        setIsPanningDisabled(false); // Re-enable panning after centering
      }
    };

    setIsPanningDisabled(true); // Disable panning during centering
    requestAnimationFrame(animate);
  };

  const [isParentChange, setIsParentChange] = useState(false);

  const [lastTapPosition, setLastTapPosition] = useState({ x: 0, y: 0 });

  const LEAWAY_DISTANCE = 50; // Allow a 200-pixel leeway for the second tap

  const VELOCITY_THRESHOLD = 0.3;
  useEffect(() => {
    if (innerDivRef.current) {
      innerDivRef.current.style.transform = `translate(${innerDivLeft}px, ${innerDivTop}px) scale(${zoomLevel})`;
      innerDivRef.current.style.transformOrigin = "0 0";
    }

    if (
      centerScreen ||
      placingMode ||
      placeArtefactsTrigger ||
      isPlacementCooldown
    ) {
      animateToCenter(); // Use the extracted animation function
      setCenterScreen(false); // Reset the centerScreen state
    }

    if (!isDragging && outerDivRef.current) {
      const outerDivBounds = outerDivRef.current.getBoundingClientRect();
      const innerDivBounds = innerDivRef.current.getBoundingClientRect();

      const offsetXCorrection = calculateElasticOffset(
        outerDivBounds.width,
        innerDivBounds.width,
        innerDivBounds.left - outerDivBounds.left
      );
      const offsetYCorrection = calculateElasticOffset(
        outerDivBounds.height,
        innerDivBounds.height,
        innerDivBounds.top - outerDivBounds.top
      );

      setCorrectionVelocityX(offsetXCorrection);
      setCorrectionVelocityY(offsetYCorrection);
    }

    if (!isDragging) {
      if (
        Math.abs(velocityX) < VELOCITY_THRESHOLD &&
        Math.abs(velocityY) < VELOCITY_THRESHOLD &&
        Math.abs(correctionVelocityX) < VELOCITY_THRESHOLD &&
        Math.abs(correctionVelocityY) < VELOCITY_THRESHOLD
      ) {
        setVelocityX(0);
        setVelocityY(0);
        setCorrectionVelocityX(0);
        setCorrectionVelocityY(0);

        return;
      }
      const animationFrame = requestAnimationFrame(() => {
        setInnerDivTop(
          (prevTop) => prevTop + velocityY * 0.1 + correctionVelocityY * 0.1
        ); // Adjust the correctionVelocity * 0.1 values only for the amount of elasticity
        setInnerDivLeft(
          (prevLeft) => prevLeft + velocityX * 0.1 + correctionVelocityX * 0.1
        );
        setVelocityX((prevVelocityX) => prevVelocityX * 0.9);
        setVelocityY((prevVelocityY) => prevVelocityY * 0.9);
        setCorrectionVelocityX(
          (prevCorrectionVelocityX) => prevCorrectionVelocityX * 0.9
        );
        setCorrectionVelocityY(
          (prevCorrectionVelocityY) => prevCorrectionVelocityY * 0.9
        );
      });
      setIsScreenCentered(false); // This makes the center screen button initially not visible, and only once you pan, then becomes visible. Must be placed here.
      return () => cancelAnimationFrame(animationFrame);
    }
  }, [
    innerDivTop,
    innerDivLeft,
    velocityX,
    velocityY,
    isDragging,
    centerScreen,
    zoomLevel,
  ]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!currentCollection || currentCollection.type === "collections") {
      return;
    }

    const files = Array.from(e.dataTransfer.files);
    // if (files.length === 0) return;

    const imageFiles = files.filter((file) => file.type.startsWith("image/"));
    const pdfFiles = files.filter((file) => file.type === "application/pdf");
    // const url = e.dataTransfer.getData("text/uri-list");

    // console.log("imagesFiles jkl", imageFiles, url);

    // Handle Image Files
    if (imageFiles.length > 0) {
      setSelectedType("Image");
      setDroppedImageFiles(imageFiles); // Update to handle multiple files
      setShowModal(true);
      console.log("Dropped image files:", imageFiles);
    }

    if (files && files[0]?.type === "application/pdf") {
      console.log("aaaak File Name:", files[0].name);
      console.log("File Type:", files[0].type);
      console.log("File Size (in bytes):", files[0].size);
      console.log("Last Modified:", new Date(files[0].lastModified));
      setSelectedType("PDF");
      setDroppedImageFiles(files[0]);
      setShowModal(true);
    }

    const url = e.dataTransfer.getData("text/uri-list");
    if (url) {
      setSelectedType("Website");

      setArtefactData((prevData) => ({
        ...prevData,
        artefactName: "", // You can set a default name or let the user input one later
        websiteURL: url,
        type: "website",
      }));
      setPlaceArtefactsTrigger(false);
      setShowModal(true); // You may want to prompt the user to input a name or confirm the URL
    }
  };

  const gridCoordinates = [];
  let innerDivWidth;
  let innerDivHeight;

  if (innerDivRef.current) {
    innerDivWidth = innerDivRef.current.clientWidth;
    innerDivHeight = innerDivRef.current.clientHeight;
  }

  for (
    let row = -innerDivHeight / 2;
    row < innerDivHeight / 2;
    row += cellSize
  ) {
    for (
      let col = -innerDivWidth / 2;
      col < innerDivWidth / 2;
      col += cellSize
    ) {
      gridCoordinates.push({ x: col, y: row });
    }
  }

  const [showCancelPlacement, setShowCancelPlacement] = useState(false);
  const [totalArtefactsToPlace, setTotalArtefactsToPlace] = useState(0);
  const currentArtefactNumber =
    totalArtefactsToPlace - artefactsQueue.length + 1;

  const [visible, setVisible] = useState(false);

  const [showDoubleClickText, setShowDoubleClickText] = useState(false);

  // useEffect(() => {
  //   setShowDoubleClickText(true);

  //   const doubleClickText = localStorage.getItem("doubleClickText") === "true";
  //   if (doubleClickText) {
  //     // Optionally, remove the flag if you want the message to show only once
  //     localStorage.setItem("doubleClickText", "false");
  //     setShowDoubleClickText(false);
  //   }
  // }, []);

  useEffect(() => {
    const doubleClickText = localStorage.getItem("doubleClickText") === "true";
    setShowDoubleClickText(doubleClickText);
  }, []);

  // useEffect(() => {
  //   if (showFirstCreationMessage) {
  //     const timer = setTimeout(() => {
  //       setShowFirstCreationMessage(false);
  //     }, 3000); // Hide after 3 seconds

  //     return () => clearTimeout(timer); // Cleanup on unmount or when the effect re-runs
  //   }
  // }, [showFirstCreationMessage]);

  // Effect to handle the show/hide logic
  useEffect(() => {
    console.log("Placing Mode:", placingMode);
    console.log("Place Artefacts Trigger:", placeArtefactsTrigger);
    console.log("Show Cancel Placement:", showCancelPlacement);
    console.log("Visible:", visible);

    if (placingMode || placeArtefactsTrigger) {
      setVisible(true); // Ensure the button is in the DOM
      setTimeout(() => setShowCancelPlacement(true), 10); // Trigger the fade-in effect
    } else {
      setShowCancelPlacement(false); // Start the fade-out effect
      // Wait for the transition to complete before setting visible to false
      const timeoutId = setTimeout(() => setVisible(false), 800); // Ensure this matches the CSS transition duration
      return () => clearTimeout(timeoutId); // Cleanup timeout to prevent memory leaks
    }
  }, [placingMode, placeArtefactsTrigger]);

  return (
    <div
      className="no-select"
      ref={outerDivRef}
      onWheel={handleWheelEvent}
      // onWheel={handleTrackpadSwipe}
      onContextMenu={handleContextMenu}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
        touchAction: "none",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "90px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1111150,
          display: "flex",
          gap: "10px",
        }}
      >
        <button
          className={`cancel-placement-btn ${
            artefactsQueue.length > 0 || placingMode ? "show" : ""
          }`}
          onClick={handleCancelCurrentPlacement}
          style={{
            background: "#DC6F6B",
            width: "170px",
            padding: "9px 5px",
            outline: "none",
            border: "none",
          }}
        >
          Cancel Placement
        </button>

        <button
          className={`cancel-all-placements-btn ${
            artefactsQueue.length > 1 && showCancelPlacement ? "show" : ""
          }`}
          onClick={handleCancelAllPlacements}
          style={{
            background: "#e69794",
            width: "100px",
            padding: "3px 0px",
            outline: "none",
            border: "none",
          }}
        >
          Cancel All
        </button>
      </div>

      {/* {showCancelPlacement && totalArtefactsToPlace > 0 && (
        <div
          className={`placing-artefact-number ${
            showCancelPlacement ? "show" : ""
          }`}
          style={{
            position: "fixed",
            marginTop: "145px",
            textAlign: "center",
            fontFamily: "Raleway, sans-serif",
            color: "#333",
            fontSize: "16px",
            marginLeft: "13px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1111150,
          }}
        >
          Placing artefact {currentArtefactNumber} out of{" "}
          {totalArtefactsToPlace}
          {console.log("showDoubleClickText", showDoubleClickText)}
        </div>
      )}

      {console.log(
        "showDoubleClickText, showcancelPlacement",
        showDoubleClickText,
        showCancelPlacement
      )}
      {showCancelPlacement && showDoubleClickText && (
        <div
          // className="first-creation-message"
          className={`first-creation-message ${
            showCancelPlacement ? "show" : ""
          }`}
          style={{
            position: "fixed",
            // marginTop: "145px",
            marginTop: `${totalArtefactsToPlace > 0 ? 175 : 145}px`,
            textAlign: "center",
            fontFamily: "Raleway, sans-serif",
            color: "blue",
            opacity: 0.7,
            fontSize: "16px",
            marginLeft: "13px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 10000,
          }}
        >
          Note: Double-click to place an artefact or collection
        </div>
      )} */}

      {showCancelPlacement && (
        <>
          {totalArtefactsToPlace > 0 && (
            <div
              className={`placing-artefact-number ${
                showCancelPlacement ? "show" : ""
              }`}
              style={{
                position: "fixed",
                marginTop: "145px",
                textAlign: "center",
                fontFamily: "Raleway, sans-serif",
                color: "#333",
                fontSize: "16px",
                marginLeft: "13px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1111150,
              }}
            >
              Placing artefact {currentArtefactNumber} out of{" "}
              {totalArtefactsToPlace}
            </div>
          )}

          {(totalArtefactsToPlace > 0 || placingMode) &&
            showDoubleClickText && (
              <div
                className={`first-creation-message ${
                  showCancelPlacement ? "show" : ""
                }`}
                style={{
                  position: "fixed",
                  marginTop: `${totalArtefactsToPlace > 0 ? 175 : 145}px`,
                  textAlign: "center",
                  fontFamily: "Raleway, sans-serif",
                  color: "blue",
                  opacity: 0.7,
                  fontSize: "16px",
                  marginLeft: "13px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 10000,
                }}
              >
                Note: Double-click to place an artefact or collection
              </div>
            )}
        </>
      )}

      <div
        // className={`zoom-slider-container ${sliderPositionClass} ${
        //   showSlider ? "visible" : ""
        // }`}
        // className={`zoom-slider-container ${sliderPositionClass} ${
        //   showSlider ? "visible" : ""
        // } ${moveUpZoomSlider ? "move-up" : ""}`}
        className={`zoom-slider-container ${sliderPositionClass} ${
          showSlider ? "visible" : ""
        } ${moveUpZoomSlider ? "move-up" : ""} ${
          homePageFlag ? "home-page-move-up" : ""
        }`}
        style={{
          position: "fixed",
          // top: "50%", // Center vertically
          // left: "50%", // Center horizontally
          top: "37px", // Distance from the top of the screen
          right: "30px", // Distance from the right of the screen
          transform: "translate(-50%, -50%)", // Offset to center
          zIndex: 1000, // Ensure it's on top
          // background: "#C7E1FF",
          display: "flex",
          // display: showSlider ? "flex" : "none",
          flexDirection: "column",
          alignItems: "center",
          // opacity: showSlider ? 1 : 0, // Fade effect
          // transition: "opacity 0.5s ease", // Fade in and out transition
          opacity: showSlider ? 1 : 0, // Fade effect
          transition: "opacity 0.7s ease", // Fade in and out transition
          pointerEvents: showSlider ? "auto" : "none",
        }}
      >
        {/* Slider input */}
        <input
          type="range"
          min={zoomLevels[0]}
          max={zoomLevels[zoomLevels.length - 1]}
          step="0.01"
          value={zoomLevel}
          onChange={handleSliderChange}
          // style={{ width: "150px" }}
          style={{
            width: "150px",
            // Custom styles for the slider
            WebkitAppearance: "none", // Removes default appearance
            appearance: "none", // Removes default appearance
            height: "10px", // Makes the slider thicker
            background: "#B3D6FF", // Changes the track color
            outline: "none", // Removes outline
            borderRadius: "5px", // Rounds the edges of the track
            // Add other styles as needed
          }}
        />

        <style>
          {`
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px; /* Width of the thumb */
            height: 16px; /* Height of the thumb */
            // background: #C7E1FF; /* Thumb color */
            background: #D6E9FF; /* Thumb color */
            // background: #9ECCFF; /* Thumb color */

            cursor: pointer; /* Cursor on hover */
            border-radius: 50%; /* Make the thumb circular */
          }
          input[type="range"]::-moz-range-thumb {
            width: 16px; /* Width of the thumb */
            height: 16px; /* Height of the thumb */
            // background: #C7E1FF; /* Thumb color */
            cursor: pointer; /* Cursor on hover */
            border-radius: 50%; /* Make the thumb circular */
          }
          input[type="range"]::-ms-thumb {
            width: 16px; /* Width of the thumb */
            height: 16px; /* Height of the thumb */
            // background: #C7E1FF; /* Thumb color */
            cursor: pointer; /* Cursor on hover */
            border-radius: 50%; /* Make the thumb circular */
          }
        `}
        </style>

        {/* Markers container */}
        <div
          style={{ width: "150px", position: "relative", marginTop: "-17px" }}
        >
          {zoomLevels.map((level, index) => (
            <div
              key={level}
              style={{
                position: "absolute",
                left: `${(index / (zoomLevels.length - 1)) * 100}%`,
                transform: "translateX(-50%)", // Center the marker horizontally
                top: index % 2 === 0 ? "-20px" : "20px", // Alternate above and below the slider
              }}
            >
              <span style={{ fontSize: "14px" }}>{level}x</span>
            </div>
          ))}
        </div>
      </div>
      <div
        className="moveUpZoomSlider-div"
        style={{
          // Move up if moveUpZoomSlider is true
          transform: moveUpZoomSlider ? "translateY(-50px)" : "translateY(0)",
          transition: "transform 0.3s ease",
        }}
      >
        {/* Zoom slider content */}
      </div>
      <div
        className="panning-box no-select"
        ref={innerDivRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          width: `${pannableBoxWidth}px`,
          height: `${pannableBoxHeight}px`,
          // background: "red",
          translateY: `${innerDivTop}px`,
          translateX: `${innerDivLeft}px`,
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, 100px)",
          gridTemplateRows: "repeat(auto-fill, 100px)",
          backgroundSize: backgroundSize,
          // backgroundImage: `radial-gradient(circle, #D3D3D3 1px, rgba(0, 0, 0, 0) 1px)`,
          // backgroundImage: `radial-gradient(circle, #D3D3D3 1px, rgba(0, 0, 0, 0) 0.08vw)`,
          backgroundImage: `radial-gradient(circle, #D3D3D3 1px, rgba(0, 0, 0, 0) 1.2px)`,
          // backgroundImage: `radial-gradient(circle, #D3D3D3 1px, rgba(0, 0, 0, 0) ${radialSize})`,

          boxShadow: "0 0 100px 40px white inset",
        }}
      >
        <ArtefactsList
          loggedInUsername={loggedInUsername}
          artefacts={artefacts}
          coordinates={coordinates}
          cellSize={cellSize}
          setArtefacts={setArtefacts}
          isPublicView={isPublicView}
          setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
          setPlacingMode={setPlacingMode}
          setArtefactData={setArtefactData}
          handleCreateButtonClick={handleCreateButtonClick}
          zoomedInFlag={zoomedInFlag}
          setZoomedInFlag={setZoomedInFlag}
          innerDivRef={innerDivRef}
          outerDivRef={outerDivRef}
          handleArtefactClick={handleArtefactClick}
          isOwner={isOwner}
          pannableBoxHeight={pannableBoxHeight}
          pannableBoxWidth={pannableBoxWidth}
          zoomLevel={zoomLevel}
          placingMode={placingMode}
          isFetching={isFetching}
          editingArtefactId={editingArtefactId}
          setEditingArtefactId={setEditingArtefactId}
        />
        <CollectionsList
          loggedInUsername={loggedInUsername}
          collections={collections}
          allCollections={collections}
          coordinates={coordinates}
          cellSize={cellSize}
          setCollections={setCollections}
          otherUser={otherUser}
          setCollectionData={setCollectionData}
          handleCreateButtonClick={handleCreateButtonClick}
          setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
          setPlacingMode={setPlacingMode}
          isPublicView={isPublicView}
          isOwner={isOwner}
          pannableBoxHeight={pannableBoxHeight}
          pannableBoxWidth={pannableBoxWidth}
          cursorPosition={cursorPosition}
          innerDivRef={innerDivRef}
          setPreviewCollection={setPreviewCollection}
          isParentChange={isParentChange}
          setIsParentChange={setIsParentChange}
          pendingParentChange={pendingParentChange}
          setPendingParentChange={setPendingParentChange}
          rootCollectionId={rootCollectionId}
          setRootCollectionId={setRootCollectionId}
          zoomLevel={zoomLevel}
          isPlacementCooldown={isPlacementCooldown}
          isFetching={isFetching}
          editingCollectionId={editingCollectionId}
          setEditingCollectionId={setEditingCollectionId}
        />

        {/* <div className="profile-box-container" style={{ zIndex: 5 }}> */}
        <ProfileBox
          loggedInUsername={loggedInUsername}
          currentCollection={currentCollection}
          collections={collections}
          isOwner={isOwner}
          og_username={og_username}
          followingButtonExplore={followingButtonExplore}
          sharelinkUsername={sharelinkUsername}
          setSharelinkUsername={setSharelinkUsername}
          loggedInFullname={loggedInFullname}
          sharelinkFullname={sharelinkFullname}
          setSharelinkFullname={setSharelinkFullname}
          pannableBoxHeight={pannableBoxHeight}
          pannableBoxWidth={pannableBoxWidth}
          homePageFlag={homePageFlag}
          privacySettingsVersion={privacySettingsVersion}
        />
        {/* </div> */}
        <PreviewItems
          previewArtefact={previewArtefact}
          previewCollection={previewCollection}
          cursorPosition={cursorPosition}
          imageDimensions={imageDimensions}
          setImageDimensions={setImageDimensions}
          zoomLevel={zoomLevel}
        />
      </div>

      <CreateArtefactModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setDroppedImageFiles([]);
        }}
        currentCollection={currentCollection}
        artefactData={artefactData}
        setArtefactData={setArtefactData}
        handleCreateButtonClick={handleCreateButtonClick}
        placeArtefactsTrigger={placeArtefactsTrigger}
        setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        droppedImageFiles={droppedImageFiles}
        setDroppedImageFiles={setDroppedImageFiles}
        websiteURL={artefactData.websiteURL}
        setArtefactsToPlace={setArtefactsToPlace}
      />
    </div>
  );
}

export { PannableBox };
