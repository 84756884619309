import React, { useState, useEffect } from "react";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate, useParams } from "react-router-dom";
import FollowButton from "./FollowButton";
import { MdClose } from "react-icons/md";
import "../css/ExplorePageSearch.css";

function ExplorePageSearch({ loggedInUsername }) {
  const { searchTerm: urlSearchTerm } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState(urlSearchTerm || "");
  const [searchResults, setSearchResults] = useState([]);

  const [followStatuses, setFollowStatuses] = useState({});

  console.log(
    "searchResultslkjh ",
    searchResults.username,
    searchResults?.username,
    searchResults.user_id,
    searchResults[0],
    searchResults,
    loggedInUsername
  );

  const handleFollow = (username) => {
    const currentStatus = followStatuses[username];

    if (currentStatus === "pending") {
      // Cancel follow request
      fetch(`/api/user/${username}/cancel_follow`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            // Remove follow status and revert to "follow"
            setFollowStatuses((prevStatuses) => ({
              ...prevStatuses,
              [username]: "none",
            }));
          } else {
            console.error("Failed to cancel follow request");
          }
        })
        .catch((error) => {
          console.error(
            "An error occurred while canceling the follow request:",
            error
          );
        });
    } else {
      // Regular follow logic
      fetch(`/api/user/${username}/follow`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            // Fetch the updated follow status
            fetch(`/api/user/${username}/follow_status`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "same-origin",
            })
              .then((res) => res.json())
              .then((followData) => {
                setFollowStatuses((prevStatuses) => ({
                  ...prevStatuses,
                  [username]: followData.status,
                }));
              });
          } else {
            console.error("Failed to follow user");
          }
        })
        .catch((error) => {
          console.error("An error occurred while following the user:", error);
        });
    }
  };

  useEffect(() => {
    // if (searchTerm) {
    if (searchTerm.length >= 2) {
      fetch(`/api/search/${searchTerm.toLowerCase()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())

        .then((data) => {
          setSearchResults(data.results); // Access the 'results' field

          data.results.forEach((user) => {
            fetch(`/api/user/${user.username}/follow_status`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "same-origin",
            })
              .then((response) => response.json())
              .then((followData) => {
                // Update the follow status for each user
                setFollowStatuses((prev) => ({
                  ...prev,
                  [user.username]: followData.status,
                }));
              });
          });
        })
        .catch((error) => {
          console.error("An error occurred while fetching data: ", error);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  return (
    <div>
      <div className="explore-page-container no-select" style={{ zIndex: 999 }}>
        <div
          className="search-box-wrapper no-select"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            userSelect: "none",
          }}
        >
          <div
            className="search-box no-select"
            style={{
              width: "30%",
              marginTop: "100px",
              marginLeft: "0px",
              userSelect: "none",
            }}
          >
            {/* <InputGroup className="mb-3">
              <Form.Control
                placeholder="Search for friends"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.96)", // Add this line
                }}
              />
            </InputGroup> */}
            <InputGroup className="mb-3" style={{ position: "relative" }}>
              <Form.Control
                placeholder="Search for friends"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.96)", // Existing style
                }}
              />
              {searchTerm && (
                <MdClose
                  className="clear-search-button"
                  onClick={() => setSearchTerm("")}
                  aria-label="Clear search"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 99999,
                    cursor: "pointer",
                    boxShadow: "none",
                    outline: "none",
                    color: "#ababab",
                    height: "70px",
                  }}
                />
              )}
            </InputGroup>

            <div className="search-results">
              {searchResults
                .filter(
                  (user) =>
                    loggedInUsername && user.username !== loggedInUsername
                )
                .map((user) => (
                  <div
                    className="no-select"
                    key={user.user_id}
                    style={{
                      padding: "7px",
                      borderRadius: "5px",
                      backgroundColor: "#DDDDDD",
                      // overflowY: "auto",
                      overflow: "hidden",
                      userSelect: "none",
                      marginBottom: "5px",
                    }}
                  >
                    <span
                      style={{
                        cursor: "pointer",
                        padding: "10px 0px 10px 5px",
                      }}
                      onClick={() => navigate(`/user/${user.username}`)}
                      onTouchStart={() => navigate(`/user/${user.username}`)}
                    >
                      {user.username}
                    </span>

                    <FollowButton
                      userId={user.username}
                      followStatus={followStatuses[user.username] || "none"}
                      handleFollow={() => handleFollow(user.username)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExplorePageSearch;
