import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import greyLogoIcon from "../grey_logo_icon.png"; // Adjust the path as needed
import ContactUsModal from "./Modals/ContactUsModal";
import "../css/HomeNavbar.css";

const HomeNavbar = (props) => {
  const navigate = useNavigate();

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };
  //Hello

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };

  const buttonStyle = {
    borderRadius: "5px",
    color: "#777",
    padding: "4px 12px 1px 12px",
    backgroundColor: "transparent",
    transition: "color 0.3s ease, background-color 0.3s ease",
    border: "1px solid transparent",
    textAlign: "center",
    cursor: "pointer",
    noWrap: "true",
    overflow: "hidden",
    userSelect: "none",
  };

  return (
    <div className="home-navbar-container">
      <div
        className="home-navbar-nav no-select"
        style={{
          position: "absolute",
          top: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#f1f1f1",
          padding: "8px 20px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          zIndex: 100,
          display: "flex",
          alignItems: "center",
          gap: "20px",
          fontFamily: "raleway",
          fontSize: "17px",
          flexWrap: "nowrap",
        }}
        draggable="false"
        onDragStart={(e) => e.preventDefault()}
      >
        <img
          src={greyLogoIcon}
          alt="Logo Icon"
          style={{
            height: "26px",
            pointerEvents: "auto",
            draggable: "false",
            userSelect: "none",
            cursor: "pointer",
            WebkitUserDrag: "none",
            marginLeft: "5px",
          }}
          onClick={() => navigate("/")}
        />

        {/* {!props.homePageFlag && (
          <button
            className="no-select"
            style={{ ...buttonStyle, marginLeft: "2px" }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#dddddd";
              e.target.style.color = "#777777"; // Change font color on hover
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "transparent";
              e.target.style.color = "#888"; // Reset font color on mouse leave
            }}
            // onClick={() => navigate("/contact")}
            onClick={handleOpenContactModal}
          >
            Contact Us
          </button>
        )} */}
        {/* {props.homePageFlag && <div style={{ width: "5px" }}></div>} */}
        <div style={{ width: "4px" }}></div>
        {/* <div
          style={{
            width: "0.5px",
            height: "30px",
            marginLeft: "5px",
            marginRight: "-8px",
            backgroundColor: "#bbb",
          }}
        ></div> */}
        <button
          className="no-select"
          style={buttonStyle}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#dddddd";
            e.target.style.color = "#667";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "transparent";
            e.target.style.color = "#777";
          }}
          onClick={() => navigate("/signin")}
          draggable="false"
          onDragStart={(e) => e.preventDefault()}
        >
          Sign In
        </button>
        <button
          className="no-select"
          // style={buttonStyle}
          style={{ ...buttonStyle, marginRight: "-5px" }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#dddddd";
            e.target.style.color = "#667";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "transparent";
            e.target.style.color = "#777";
          }}
          onClick={() => navigate("/signup")}
        >
          Sign Up
        </button>
      </div>
      <ContactUsModal
        show={isContactModalOpen}
        handleClose={handleCloseContactModal}
      />
    </div>
  );
};

export default HomeNavbar;
