// import React from "react";
// import IconButton from "@mui/material/IconButton";
// import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
// import { styled } from "@mui/material/styles";

// const ArrowIcon = styled(IconButton)(({ theme }) => ({
//   cursor: "pointer",
//   fontSize: "2em",
//   margin: "-7px 30px",
//   userSelect: "none",
//   backgroundColor: "#ccc",
//   borderRadius: "5px",
//   width: "45px",
//   height: "37px",
//   color: "#696969",

//   "&:disabled": {
//     color: "#aaa",
//     cursor: "default",
//     backgroundColor: "#ccc",
//   },

//   [theme.breakpoints.down("sm")]: {
//     width: "60px",
//     height: "160px",
//     fontSize: "2.5em",
//     margin: "0 20px",
//     zIndex: 1000,
//   },
// }));

// function ArrowIconButton({ direction, disabled, onClick }) {
//   const Icon = direction === "left" ? BsArrowLeft : BsArrowRight;

//   return (
//     <ArrowIcon
//       onClick={onClick}
//       disabled={disabled}
//       aria-label={`${direction} Slide`}
//     >
//       <Icon />
//     </ArrowIcon>
//   );
// }

// export default ArrowIconButton;

import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import PieChart from "./PieChart";
import "../css/ArrowIconButton.css";

const ArrowIcon = styled(IconButton)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "2em",
  margin: "-7px 30px",
  userSelect: "none",
  backgroundColor: "#ccc",
  borderRadius: "5px",
  width: "45px",
  height: "37px",
  color: "#696969",

  "&:disabled": {
    color: "#aaa",
    cursor: "default",
    backgroundColor: "#ccc",
  },

  // [theme.breakpoints.down("sm")]: {
  //   width: "60px",
  //   // height: "160px",
  //   height: "120px", // Capped height for mobile
  //   maxHeight: "37px",
  //   maxWidth: "45px",
  //   fontSize: "2.5em",
  //   color: "#696969",
  //   margin: "0 20px",
  //   zIndex: 1000,
  // },
  [theme.breakpoints.down("sm")]: {
    width: "60px",
    // height: "160px",
    height: "120px", // Capped height for mobile
    maxHeight: "37px",
    maxWidth: "45px",
    fontSize: "2.5em",
    color: "#696969",
    margin: "0 20px",
    zIndex: 1000,

    // Override interaction styles for mobile to prevent darkening
    "&:hover": {
      backgroundColor: "#ccc",
      // color: "#696969",
    },
    // "&:active": {
    //   backgroundColor: "#ccc",
    //   color: "#696969",
    // },
    // "&:focus": {
    //   backgroundColor: "#ccc",
    //   color: "#696969",
    // },
  },
}));

function ArrowIconButton({
  direction,
  disabled,
  onClick,
  currentSlide,
  isFromSignup,
  slidesLength,
  visitedSlidesRef,
}) {
  const [canNavigate, setCanNavigate] = useState(!isFromSignup);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    let timer;

    // Check if the slide is not the last and is from signup and hasn't been visited
    if (
      isFromSignup &&
      currentSlide !== slidesLength - 1 &&
      !visitedSlidesRef.current.has(currentSlide)
    ) {
      // Disable navigation and show progress circle
      setCanNavigate(false);
      setShowProgress(true);

      // Start a 6-second timer
      timer = setTimeout(() => {
        setCanNavigate(true);
        setShowProgress(false);
        visitedSlidesRef.current.add(currentSlide);
      }, 6000);
    } else {
      // For revisited slides or non-signup flows, enable navigation immediately
      setCanNavigate(true);
      setShowProgress(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [currentSlide, isFromSignup, slidesLength, visitedSlidesRef]);

  const Icon = direction === "left" ? BsArrowLeft : BsArrowRight;

  return (
    <React.Fragment>
      <ArrowIcon
        className="arrow-icon-component"
        onClick={onClick}
        disabled={disabled || !canNavigate}
        aria-label={`${direction} Slide`}
      >
        <Icon />
      </ArrowIcon>

      {isFromSignup && (
        <div
          className="pie-progress-circle"
          style={{
            marginTop: "-5px",
            position: "absolute",
            zIndex: 20,
            borderRadius: "50%",
          }}
        >
          {/* <PieChart
            key={currentSlide}
            duration={6000}
            size={30}
            color="#7695FF"
          /> */}
          {showProgress ? (
            <PieChart
              key={currentSlide} // Reset PieChart on slide change
              duration={6000} // 6 seconds
              size={30}
              color="#7695FF"
            />
          ) : (
            // Render a filled circle when not showing progress
            <PieChart
              duration={0} // No animation
              size={30}
              color="#7695FF"
              completed={true} // Assuming your PieChart can handle a 'completed' prop
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default ArrowIconButton;
