export const createArtefact = async ({
  loggedInUsername,
  currentCollection,
  nameArtef,
  coordinates,
  content,
  is_public,
  type,
  width,
  height,
  sizing_choice,
}) => {
  console.log("1111111111111111111111111111111111 newnew 1");

  const response = await fetch(
    `/api/user/${loggedInUsername}/collection/${currentCollection.collection_id}/create_artefact`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({
        artefact_name: nameArtef,
        parent_id: currentCollection ? currentCollection.collection_id : null,
        coordx_private: coordinates.x,
        coordy_private: coordinates.y,
        type,
        content: content,
        is_public: is_public,
        image_width: width,
        image_height: height,
        sizing_choice: sizing_choice,
      }),
    }
  );
  return response.status;
};

// In your client-side API function (e.g., api/createArtefact.js)
// In api/createArtefact.js or Collection.js
// export const createArtefact = async (artefactDetails) => {
//   const response = await fetch(
//     `/api/user/${artefactDetails.loggedInUsername}/collection/${artefactDetails.currentCollection.collection_id}/create_artefact`,
//     {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       credentials: "same-origin",
//       body: JSON.stringify({
//         artefact_name: artefactDetails.nameArtef,
//         parent_id: artefactDetails.currentCollection
//           ? artefactDetails.currentCollection.collection_id
//           : null,
//         coordx_private: artefactDetails.coordinates.x,
//         coordy_private: artefactDetails.coordinates.y,
//         type: artefactDetails.type,
//         content: artefactDetails.content,
//         is_public: artefactDetails.is_public,
//         image_width: artefactDetails.width,
//         image_height: artefactDetails.height,
//         sizing_choice: artefactDetails.sizing_choice,
//       }),
//     }
//   );

//   if (response.status === 201) {
//     const data = await response.json();
//     return data.artefact; // Return the artefact object, including artefact_id
//   } else {
//     const errorData = await response.json();
//     console.error("Failed to create artefact:", errorData);
//     throw new Error(errorData.message || "Failed to create artefact");
//   }
// };
