export async function uploadArtefact(formData) {
  try {
    const response = await fetch("/api/upload", {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    console.log("data in uploadartefact, ", data);
    return data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}

// export async function uploadArtefact(formData) {
//   try {
//     const response = await fetch("/api/upload", {
//       method: "POST",
//       body: formData,
//     });
//     const data = await response.json();
//     // Ensure `data` contains all necessary fields like `url`, `width`, `height`
//     return {
//       url: data.url,
//       width: data.width,
//       height: data.height,
//     };
//   } catch (error) {
//     console.error("Error uploading file:", error);
//     throw error;
//   }
// }
