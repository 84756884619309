import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import logoIcon from "../logo_icon.png";
import logoShowcase from "../logo_showcase.png";
import "../css/VerifyEmail.css";

const Verification = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const emailFromSignup = location.state?.email || "";

  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [resendMsg, setResendMsg] = useState("");
  const [isResending, setIsResending] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");

  // Refs for each input to handle focus
  const inputsRef = useRef([]);

  // useEffect(() => {
  //   console.log("loggedIn state changed lkjhh2:", props.loggedIn); // Debugging
  //   if (props.loggedIn) {
  //     navigate("/mypage");
  //   }
  // }, [props.loggedIn]);

  useEffect(() => {
    if (!emailFromSignup) {
      if (props.loggedIn) {
        navigate("/mypage");
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [emailFromSignup, navigate]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (!/^\d?$/.test(value)) {
      // Only allow digits
      return;
    }

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Clear the error message when the user modifies any input
    setErrorMsg("");
    setResendMsg("");

    if (value && index < 5) {
      // Move focus to the next input
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!code[index] && index > 0) {
        const newCode = [...code];
        newCode[index - 1] = "";
        setCode(newCode);
        inputsRef.current[index - 1].focus();
        e.preventDefault();
      }
    } else if (e.key === "ArrowLeft") {
      if (index > 0) {
        inputsRef.current[index - 1].focus();
        e.preventDefault();
      }
    } else if (e.key === "ArrowRight") {
      if (index < 5) {
        inputsRef.current[index + 1].focus();
        e.preventDefault();
      }
    }
  };

  const [cooldown, setCooldown] = useState(0);

  useEffect(() => {
    if (emailFromSignup) {
      const lastResend = localStorage.getItem(`lastResend_${emailFromSignup}`);
      if (lastResend) {
        const elapsed = Math.floor(
          (Date.now() - parseInt(lastResend, 10)) / 1000
        );
        const remaining = 60 - elapsed;
        if (remaining > 0) {
          setCooldown(remaining);
        } else {
          localStorage.removeItem(`lastResend_${emailFromSignup}`);
        }
      }
    }
  }, [emailFromSignup]);

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [cooldown]);

  // Update handleResendCode to set cooldown
  const handleResendCode = async () => {
    if (cooldown > 0) return; // Prevent resend if cooldown is active

    setIsResending(true);
    setResendMsg("");
    setErrorMsg("");

    try {
      const response = await fetch("/api/resend_code", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify({ email: emailFromSignup }),
      });

      const data = await response.json();

      if (response.status === 200) {
        setResendMsg(
          data.message || "Verification code resent. Please check your email."
        );
        setCooldown(60); // Set cooldown to 60 seconds
        localStorage.setItem(
          `lastResend_${emailFromSignup}`,
          Date.now().toString()
        );
      } else {
        setErrorMsg(data.error || "Failed to resend verification code.");
      }
    } catch (error) {
      console.error("Resend code failed:", error);
      setErrorMsg("Network error. Please try again later.");
    } finally {
      setIsResending(false);
    }
  };

  // Add useEffect to handle cooldown timer
  useEffect(() => {
    if (cooldown > 0) {
      const timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [cooldown]);

  const handleVerification = async (e) => {
    e.preventDefault();

    const verificationCode = code.join("");

    // Basic validation
    if (!verificationCode) {
      setErrorMsg("Verification code is required.");
      return;
    }

    if (!/^\d{6}$/.test(verificationCode)) {
      setErrorMsg("Verification code must be a 6-digit number.");
      return;
    }

    if (!emailFromSignup) {
      setErrorMsg("No email provided for verification.");
      return;
    }

    setIsSubmitting(true);
    setErrorMsg("");

    try {
      const response = await fetch("/api/signup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify({
          email: emailFromSignup,
          code: verificationCode,
        }),
      });

      if (response.status === 201) {
        // Signup successful, extract user data from response
        console.log("props.loggedIn lkjhhb", props.loggedIn);

        const data = await response.json();

        // Set logged-in state in your app
        props.setLoggedInUsername(data.username);
        props.setLoggedIn(true);
        props.setOg_username(data.username);
        props.setLoggedInFullname(data.full_name);

        // Optionally store username in localStorage
        localStorage.setItem("username", data.username);

        // Set a flag to indicate the tutorial should be shown
        localStorage.setItem("showTutorial", "true");
        localStorage.setItem("tutorialSource", "verifyEmail");
        localStorage.setItem("doubleClickText", "true");

        // Redirect to user page or wherever you want
        // navigate("/mypage");

        // setTimeout(() => {
        //   navigate("/mypage");
        // }, 0);

        setSuccessMsg("Success. Redirecting...");
        setTimeout(() => {
          navigate("/mypage");
        }, 1500);

        console.log("props.loggedIn lkjhha", props.loggedIn);
      } else {
        const j = await response.json();
        setErrorMsg(j.error || "Verification failed.");
      }
    } catch (error) {
      console.error("Verification failed:", error);
      setErrorMsg("Network error. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatCooldown = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins > 0 ? `${mins}:` : ""}${secs < 10 ? "0" : ""}${secs}s`;
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("Text").trim();
    if (!/^\d{6}$/.test(pasteData)) {
      setErrorMsg("Please paste a valid 6-digit code.");
      return;
    }

    const pasteCode = pasteData.split("");
    setCode(pasteCode);
    setErrorMsg("");

    // Optionally, automatically submit the form after pasting
    // handleVerification(e); // Uncomment if you want to auto-submit
    // Move focus to the last input
    inputsRef.current[5].focus();
  };

  return (
    <div
      className="verify-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "20px",
        marginTop: "-30px",
        position: "relative",

        // Preserve the initial background by not overriding it.
        // If you need to set a specific background color, uncomment the line below and set your desired color.
        // backgroundColor: "#f8f9fa",
      }}
    >
      {/* Logo Showcase */}
      <div
        className="verify-email-showcase-logo"
        style={{
          textAlign: "center",
          marginTop: "-100px",
          height: "100px",
          marginBottom: "30px",
        }}
      >
        <img
          src={logoIcon}
          alt="Logo Icon"
          className="logo-icon"
          style={{ height: "71px", marginRight: "10px" }}
        />
        <img
          src={logoShowcase}
          alt="Logo Showcase"
          className="logo-showcase"
          style={{ height: "109px" }}
        />
      </div>

      {/* Verification Form */}
      <div
        className="verify-email-form no-select"
        style={{
          width: "100%",
          maxWidth: "500px",
          backgroundColor: "#eee",
          opacity: "0.9",
          padding: "40px",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          height: "440px", // Adjusted height to accommodate inputs
          userSelect: "none",
        }}
      >
        <h2
          className="verify-email-title"
          style={{
            textAlign: "center",
            marginBottom: "23px",
            marginTop: "-10px",
            fontSize: "38px",
            fontFamily: "raleway",
          }}
        >
          Verify Your Email
        </h2>

        <p
          className="verify-email-subtitle"
          style={{
            color: "#555",
            marginTop: "-7px",
            fontSize: "18px",
            fontFamily: "raleway",
          }}
        >
          Let's get your data backed-up to an email.
        </p>
        <p
          className="verify-email-subtitle"
          style={{
            color: "#555",
            marginTop: "-5px",
            fontSize: "18px",
            marginBottom: "35px",
            fontFamily: "raleway",
          }}
        >
          Input the 6-digit code sent to your email address.
        </p>

        <Form onSubmit={handleVerification}>
          {/* <div
            className="code-inputs"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            {code.map((digit, index) => (
              <Form.Control
                key={index}
                type="text"
                inputMode="numeric"
                pattern="\d*"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputsRef.current[index] = el)}
                style={{
                  width: "57px",
                  height: "70px",
                  textAlign: "center",
                  fontSize: "29px",
                  fontFamily: "raleway",
                }}
              />
            ))}
          </div> */}

          <div
            className="code-inputs"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            {code.map((digit, index) => (
              <Form.Control
                key={index}
                type="text"
                inputMode="numeric"
                pattern="\d*"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={index === 0 ? handlePaste : undefined}
                ref={(el) => (inputsRef.current[index] = el)}
                className={digit === "6" ? "digit-six" : ""}
                style={{
                  width: "57px",
                  height: "70px",
                  textAlign: "center",
                  fontSize: "29px",
                  fontFamily: "Raleway, sans-serif",
                  position: "relative",
                }}
              />
            ))}
          </div>

          <div
            style={{
              color: "#dc3545",
              textAlign: "center",
              height: "20px", // Fixed height
              marginBottom:
                errorMsg.startsWith("Too many") ||
                errorMsg.startsWith("Resending code")
                  ? "31px"
                  : "23px", // Adjust margin
              fontSize: "17px",
              marginTop:
                errorMsg.startsWith("Too many") ||
                errorMsg.startsWith("Resending code")
                  ? "-8px"
                  : "0px", // Adjust margin
              lineHeight:
                errorMsg.startsWith("Too many") ||
                errorMsg.startsWith("Resending code")
                  ? "1.2"
                  : "1.5", // Adjust margin
            }}
          >
            {errorMsg || "\u00A0"}
            {successMsg && (
              <div
                style={{
                  color: "green",
                  height: "20px",
                  textAlign: "center",
                  marginTop: "-23px",
                  fontSize: "17px",
                  lineHeight: "1.5",
                }}
              >
                {successMsg}
              </div>
            )}
            {resendMsg && (
              <div
                style={{
                  color: "#777",
                  textAlign: "center",
                  marginTop: "-24px",
                  fontSize: "16px",
                }}
              >
                {resendMsg}
              </div>
            )}
          </div>

          <div style={{ marginBottom: "15px" }}>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              style={{ width: "100%", fontSize: "20px", fontFamily: "raleway" }}
            >
              {isSubmitting ? "Verifying..." : "Verify"}
            </Button>
          </div>

          {/* <Form.Text
            muted
            style={{
              display: "block",
              textAlign: "center",
              fontSize: "16px",
              userSelect: "none",
            }}
          >
            Didn't receive the code?{" "}
            <Link to="/request-resend-code">Resend Code</Link>
          </Form.Text> */}
          <Form.Text
            muted
            className="resend-code-text"
            style={{
              display: "block",
              textAlign: "center",
              fontSize: "17px",
              userSelect: "none",
              fontFamily: "raleway",
            }}
          >
            Didn't receive the code?{" "}
            <button
              type="button"
              onClick={handleResendCode || cooldown > 0}
              disabled={isResending}
              style={{
                background: "none",
                border: "none",
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
                padding: 0,
                font: "inherit",
              }}
            >
              {"Resend Code"}
            </button>
          </Form.Text>
          <Form.Text
            className="any-issues-text"
            muted
            style={{
              display: "block",
              textAlign: "center",
              fontSize: "14px",
              marginTop: "10px",
              userSelect: "none",
              fontFamily: "raleway",
            }}
          >
            Any issues, email us at{" "}
            <a
              href="mailto:contact@showcase-app.co"
              style={{
                color: "#777",
                textDecoration: "underline",
              }}
            >
              contact@showcase-app.co
            </a>
          </Form.Text>

          {/* Display Resend Message */}
        </Form>
      </div>
      <Form.Text
        muted
        style={{
          display: "block",
          textAlign: "center",
          fontSize: "14px",
          userSelect: "none",
          marginTop: "15px",
          fontFamily: "raleway",
        }}
      >
        Back to <Link to="/signup">Signup</Link>
      </Form.Text>
    </div>
  );
};

export default Verification;
