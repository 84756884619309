import React, { useEffect, useState, useRef, useMemo } from "react";
import { Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { useMediaQuery } from "@mui/material";

import StepLabel from "@mui/material/StepLabel";
import StepConnector from "@mui/material/StepConnector"; // Import StepConnector to customize it
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import logoIcon from "../../logo_icon.png";
import logoShowcase from "../../logo_showcase.png";
import CircularProgress from "@mui/material/CircularProgress";
import "../../css/TutorialModal.css";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PieChart from "../PieChart";
import zIndex from "@mui/material/styles/zIndex";
import ArrowIconButton from "../ArrowIconButton";

function TutorialModal({ show, handleClose, isFromSignup }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [canNavigate, setCanNavigate] = useState(!isFromSignup);
  const [showProgress, setShowProgress] = useState(false);
  // const [visitedSlides, setVisitedSlides] = useState(new Set());
  const visitedSlidesRef = useRef(new Set());

  const isPhoneView = useMediaQuery("(max-width: 768px)");

  const videoRefs = useRef([]);

  const slides = useMemo(
    () => [
      {
        content: (
          <div className="no-select">
            <h4
              className="no-select"
              style={{
                // marginTop: "-130px",
                marginTop: isPhoneView ? "-105px" : "-130px",

                marginBottom: "25px",
              }}
            >
              Welcome to Showcase!
            </h4>
            <p style={{ marginBottom: "45px" }}>
              Showcase offers a space to curate, visualise, and share your
              interests.
            </p>
            <p style={{ marginBottom: "45px" }}>
              Store images, websites, social media posts, and more. Keep content{" "}
              <br></br>
              organised in one place rather than scattered everywhere.
            </p>
            {/* <p style={{ marginBottom: "45px" }}>
              Main thing to remember: double click to place an item.
            </p> */}

            <p>
              {" "}
              Here is a quick tour to get you familiar with using the platform.
            </p>
          </div>
        ),
        label: "Welcome",
      },
      {
        content: (
          <div className="no-select">
            <h4 style={{ marginTop: isPhoneView ? "20px" : "0px" }}>
              Collections
            </h4>
            <p> Collections have a folder-like function.</p>
            <p> Your homepage displays collections only.</p>

            {/* <p> Press the "+" to add one.</p> */}
            <p>
              Press the
              <img
                src="/Plus-button-icon.png"
                alt="Add Collection"
                style={{
                  width: "36px",
                  height: "28px",
                  margin: "0 9px 4px 9px",
                  verticalAlign: "middle",
                }}
              />
              button to add one.
            </p>
            <p>Click and pan to position, double-click to drop.</p>
            <p>Click to enter and navigate with the arrows.</p>

            <p style={{ marginTop: "0px", color: "#4B81D7" }}>
              Note: Collections can hold other collections or artefacts, but not
              both.
            </p>
          </div>
        ),
        label: "Collections",
        video: (
          <div
            className="no-select"
            style={{
              width: "100%",
              // height: "100%",
              height: isPhoneView ? "160%" : "100%", // Adjust height for phone view
              borderRadius: "8px",
              overflow: "hidden",
              position: "relative", // Ensure positioning for video adjustment
            }}
          >
            <video
              ref={(el) => (videoRefs.current[1] = el)}
              src="/Tutorial_1.mp4"
              autoPlay
              muted
              loop
              playsInline
              controls={false}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "0px", // Match the container's border radius
                objectFit: "cover", // Covers the entire area, good for clipped videos
                objectPosition: "center top", // Adjusts the position post-clipping
                clipPath: "inset(14.3% 0px 2px 0px)", // Crop 61px from the top
                pointerEvents: "none", // Keeps the video non-interactive
                // marginTop: "-60px", // Adjust this value to reposition the video
                marginTop: isPhoneView ? "-45px" : "-60px",
              }}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        ),
      },
      {
        content: (
          <div className="no-select">
            <h4 style={{ marginTop: isPhoneView ? "20px" : "0px" }}>
              Artefacts
            </h4>
            <p>
              Add artefacts like images, websites, text, pdf's or social media
              posts.
            </p>
            {/* <p>Click "+" to add or drag and drop onto the canvas.</p> */}
            <p>
              Click
              <img
                src="/Plus-button-icon.png"
                alt="Add Collection"
                style={{
                  width: "36px",
                  height: "28px",
                  margin: "0 9px 4px 9px",
                  verticalAlign: "middle",
                }}
              />
              to add, or drag and drop onto the canvas.
            </p>
            {/* <p>Name the artefact, and double click to place.</p> */}
            <p>
              Name the artefact, and{" "}
              <span style={{ fontWeight: 500 }}>double click</span> to place.
            </p>
            <p>Right-click to reposition and edit artefacts as needed.</p>
            <p style={{ marginTop: "0px", color: "#4B81D7" }}>
              Note: Social media posts should be from public accounts.
            </p>
          </div>
        ),
        label: "Artefacts",
        video: (
          <div
            className="no-select"
            style={{
              width: "100%",
              // height: "100%",
              height: isPhoneView ? "130%" : "100%",
              borderRadius: "8px",
              overflow: "hidden",
              position: "relative", // Ensure positioning for video adjustment
            }}
          >
            <video
              ref={(el) => (videoRefs.current[2] = el)}
              src="/Tutorial_2.mp4"
              autoPlay
              muted
              loop
              playsInline
              controls={false}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "0px", // Match the container's border radius
                objectFit: "cover", // Covers the entire area, good for clipped videos
                objectPosition: "center top", // Adjusts the position post-clipping
                clipPath: "inset(14.3% 0px 2px 0px)", // Crop 61px from the top
                pointerEvents: "none", // Keeps the video non-interactive
                // marginTop: "-60px", // Adjust this value to reposition the video
                marginTop: isPhoneView ? "-45px" : "-60px",
              }}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        ),
      },
      {
        content: (
          <div className="no-select">
            <h4 style={{ marginTop: isPhoneView ? "20px" : "0px" }}>
              Viewing options
            </h4>
            <p>
              There are two viewing options for each artefact: public and
              private.
            </p>
            <p>
              The private artefacts are only visible to you, and the public
              artefacts are what your followers can see.
            </p>
            {/* <p>Artefacts are private by default (lock icon).</p> */}
            <p>
              Artefacts are private by default (
              <img
                src="/Lock-icon_3.png"
                alt="Lock icon"
                style={{
                  width: "9px",
                  height: "12px",
                  margin: "0 2px 4px 3px",
                  verticalAlign: "middle",
                }}
              />
              ), right-click to toggle public/private.
            </p>

            <p>
              Check the public view to see what your followers will see, and
              organise each view independently.
            </p>
            <p style={{ marginTop: "0px", color: "#4B81D7" }}>
              Note: Artefact publicity cascades up collections.
            </p>
          </div>
        ),
        label: "Viewing options",
        video: (
          <div
            className="no-select"
            style={{
              width: "100%",
              // height: "100%",
              height: isPhoneView ? "160%" : "100%",
              borderRadius: "8px",
              overflow: "hidden",
              position: "relative", // Ensure positioning for video adjustment
            }}
          >
            <video
              ref={(el) => (videoRefs.current[3] = el)}
              src="/Tutorial_3.mp4"
              autoPlay
              muted
              loop
              playsInline
              controls={false}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "0px", // Match the container's border radius
                objectFit: "cover", // Covers the entire area, good for clipped videos
                objectPosition: "center top", // Adjusts the position post-clipping
                clipPath: "inset(14.3% 0px 2px 0px)", // Crop 61px from the top
                pointerEvents: "none", // Keeps the video non-interactive
                // marginTop: "-60px", // Adjust this value to reposition the video
                marginTop: isPhoneView ? "-45px" : "-60px",
              }}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        ),
      },
      {
        content: (
          <div className="no-select">
            <h4 style={{ marginTop: isPhoneView ? "20px" : "0px" }}>
              Features
            </h4>
            <p>
              Share artefact-collections via sharelinks, to those without
              Showcase.
            </p>
            <p>Manage followers in the Friends tab.</p>
            <p>View others' pages.</p>
            <p>Explore public artefacts and search users.</p>
            <p style={{ marginTop: "0px", color: "#4B81D7" }}>
              Note: Re-access this tutorial through the Accounts tab.
            </p>
          </div>
        ),
        label: "Features",
        video: (
          <div
            className="no-select"
            style={{
              width: "100%",
              // height: "100%",
              height: isPhoneView ? "133%" : "100%",
              borderRadius: "8px",
              overflow: "hidden",
              position: "relative", // Ensure positioning for video adjustment
            }}
          >
            <video
              ref={(el) => (videoRefs.current[4] = el)}
              src="/Tutorial_4_updated.mp4"
              autoPlay
              muted
              loop
              playsInline
              controls={false}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "0px", // Match the container's border radius
                objectFit: "cover", // Covers the entire area, good for clipped videos
                objectPosition: "center top", // Adjusts the position post-clipping
                clipPath: "inset(14.3% 0px 2px 0px)", // Crop 61px from the top
                pointerEvents: "none", // Keeps the video non-interactive
                // marginTop: "-60px", // Adjust this value to reposition the video
                marginTop: isPhoneView ? "-45px" : "-60px",
              }}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        ),
      },
      {
        content: (
          <div className="no-select">
            <h4
              style={{
                // marginTop: "-130px",
                marginTop: isPhoneView ? "-105px" : "-130px",

                marginBottom: "15px",
              }}
            >
              Done
            </h4>
            <p>You are now ready to use Showcase!</p>
          </div>
        ),
        label: "Done",
      },
    ],
    [currentSlide]
  );

  const isDoneSlide = currentSlide === slides.length - 1;

  useEffect(() => {
    const videoElement = videoRefs.current[currentSlide];
    if (videoElement) {
      videoElement.play().catch((error) => {
        console.error("Video play failed:", error);
      });
    }
  }, [currentSlide]);

  const handleNext = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevSlide) => prevSlide - 1);
    }
  };

  // useEffect(() => {
  //   let timer;

  //   // Check if the slide is not the first or last and is from signup
  //   if (
  //     isFromSignup &&
  //     // currentSlide !== 0 &&
  //     currentSlide !== slides.length - 1 &&
  //     // !visitedSlides.has(currentSlide) // Only if not visited before
  //     !visitedSlidesRef.current.has(currentSlide)
  //   ) {
  //     // Disable navigation and show progress circle
  //     setCanNavigate(false);
  //     setShowProgress(true);

  //     // Start a 6-second timer
  //     timer = setTimeout(() => {
  //       setCanNavigate(true); // Enable navigation after 6 seconds
  //       setShowProgress(false); // Hide the progress circle
  //       // setVisitedSlides((prev) => new Set(prev).add(currentSlide)); // Mark as visited
  //       visitedSlidesRef.current.add(currentSlide);
  //     }, 6000); // 6,000 milliseconds = 6 seconds
  //   } else {
  //     // For revisited slides or non-signup flows, enable navigation immediately
  //     setCanNavigate(true);
  //     setShowProgress(false);
  //   }

  //   // Cleanup the timer when the component unmounts or dependencies change
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [
  //   currentSlide,
  //   isFromSignup,
  //   // visitedSlides,
  //   slides.length,
  // ]);

  const modalDialogStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "95vw",
    width: "88vw",
    // height: "95vh",
    height: isPhoneView ? "83vh" : "95vh",
    backgroundColor: "white",
    // overflowY: "hidden",
    borderRadius: "8px",
    padding: "20px",

    "@media (max-width: 768px)": {
      width: "100vw", // Increase width to 98% of the viewport width in phone view
      height: "75vh !important",
      padding: "0", // Remove any padding
      left: "0",
      transform: "translate(0, -50%)",
      borderRadius: "0",
      margin: "0", // Remove any margin
      // overflowY: "hidden",
      overflowX: "hidden",
      overflow: "hidden",
    },
  };

  const BodyContainer = styled("div")(({ theme }) => ({
    height: "75%",
    width: "97%",
    backgroundColor: "#d4d4d4",
    display: "flex",
    flexDirection: "row",
    marginBottom: "120px",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100vw", // Increase width to 100% of the viewport width in phone view
      height: "100%", // Decrease height for phone view
      padding: "0", // Remove any padding
      borderRadius: "0", // Remove border radius
      marginBottom: "0px", // Remove bottom margin for phone view
      padding: "0", // Remove padding
      backgroundColor: "transparent", // Remove the background color
      // marginTop: "-180px", // Remove top margin for phone view
      overflow: "visible",
      // marginTop: "-180px", // Remove top margin for phone view
    },
  }));

  const LeftColumn = styled("div")(({ theme }) => ({
    flex: 1,
    padding: "10px",
    textAlign: "left",
    lineHeight: "1.5",
    marginLeft: "15px",
    overflowWrap: "break-word",

    [theme.breakpoints.down("sm")]: {
      //   marginLeft: "0px",
      //   marginTop: "-190px",
      //   textAlign: "center",
      //   order: 2,
      //   width: "100%", // Increase width to 100% in phone view
      //   padding: "0", // Remove padding
      // },
      marginLeft: "0px",
      // marginTop: "-190px",
      textAlign: "center",
      order: 2,
      width: "78%", // Increase width to 100% in phone view
      padding: "0", // Remove padding
      fontSize: "14px", // Decrease font size for smaller screens
      lineHeight: "1.2", // Adjust line height for smaller screens
      overflow: "visible", // Prevent overflow in phone view
      marginBottom: "50px", // Add margin at the bottom for better spacing
      wordWrap: "break-word",
    },
  }));

  const RightColumn = styled("div")(({ theme }) => ({
    flex: 2,
    maxWidth: "100%",
    maxHeight: "100%",
    width: "90%",
    padding: "10px 10px",
    backgroundColor: "#b0b0b0",
    borderRadius: "8px",
    overflow: "hidden",
    marginRight: "20px",

    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      width: "80vw",
      // height: "80%",
      maxHeight: "70%",
      // marginTop: "-140px",
      order: 1,
      padding: "0",
      backgroundColor: "transparent",
      // marginBottom: "170px",
      // marginTop: "-180px",
    },
  }));

  const centeredContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "100%",
    width: "100%",
  };

  const centeredContentStyle = {
    textAlign: "center",
  };

  // const boxStyle = {
  //   width: "900px",
  //   backgroundColor: "#e4e4e4",
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   borderRadius: "8px",
  //   color: "white",
  //   fontWeight: "bold",
  //   position: "absolute",
  //   bottom: "15px",
  //   left: "50%",
  //   transform: "translateX(-50%)",
  //   zIndex: 50,
  //   padding: "10px 10px 10px 10px", // Adjusted padding to accommodate arrows
  //   userSelect: "none",
  // };
  const boxStyle = {
    width: "900px",
    backgroundColor: isPhoneView ? "transparent" : "#e4e4e4", // Transparent background for phone view
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: isPhoneView ? "0px" : "8px", // Remove border-radius for phone view
    color: "white",
    fontWeight: "bold",
    position: "absolute",
    bottom: isPhoneView ? "25px" : "15px", // Adjust bottom position for phone view
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 50,
    padding: isPhoneView ? "0px" : "10px 10px 10px 10px", // Remove padding for phone view
    userSelect: "none",
  };

  const arrowContainerStyle = {
    display: "flex",
    justifyContent: "center", // Center the arrows
    width: "100%",
    marginBottom: "20px", // Space between arrows and stepper
    userSelect: "none",
    marginTop: "10px",

    // Apply changes for small screens (phone view)
    "@media (max-width: 768px)": {
      marginTop: "15px", // Adjust margin for small screens
      padding: "10px 0", // Add padding to increase clickable area
      zIndex: 1000, // Ensure the arrows are clickable
      height: "160px", // Increase height for better touch area
    },
  };

  const arrowStyle = {
    cursor: "pointer",
    fontSize: "2em", // Adjust the size as necessary
    margin: "-7px 30px", // Ensure space between the two arrows
    userSelect: "none",
    backgroundColor: "#ccc",
    borderRadius: "5px",
    width: "45px",
    height: "37px",
    color: "#696969",

    "@media (max-width: 768px)": {
      width: "60px", // Increase width for better touch area
      height: "160px", // Increase height
      fontSize: "2.5em", // Increase icon size for visibility
      margin: "0 20px", // Adjust margin
      zIndex: 1000, // Ensure the arrows are clickable
    },
  };

  const disabledArrowStyle = {
    color: "#aaa",
    cursor: "default",
  };

  const headerStyle = {
    height: "10px",
    display: "flex",
    alignItems: "center",
    padding: "5px 20px 0px 20px",
    borderBottom: "none",
    userSelect: "none",
  };

  // Custom StepConnector with highlighted completed lines
  const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    [`& .MuiStepConnector-line`]: {
      borderColor: "#ccc",
      borderTopWidth: 2,
    },
    [`&.Mui-active .MuiStepConnector-line`]: {
      borderColor: "#7695FF", // Color of the line when the step is active
    },
    [`&.Mui-completed .MuiStepConnector-line`]: {
      borderColor: "#7695FF", // Color of the line when the step is completed
    },
  }));

  return (
    <Modal
      show={show}
      // onHide={handleClose}
      // onHide={isFromSignup ? handleClose : () => {}}
      onHide={!isFromSignup || isDoneSlide ? handleClose : () => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="custom-modal"
      // backdrop="static" // Prevent closing by clicking outside
      // keyboard={false} // Prevent closing with the "Escape" key
      // backdrop={isFromSignup ? "static" : true} // Prevent closing by clicking outside if not from verification
      // keyboard={isFromSignup}
      backdrop={isFromSignup || isDoneSlide ? "static" : true} // Prevent closing by clicking outside unless on "Done" slide
      keyboard={isFromSignup || isDoneSlide}
      style={{
        overflowY: "initial !important",
        fontFamily: "Raleway, sans-serif",
        userSelect: "none",
        outline: "none",
      }}
      className="no-select"
    >
      <div style={modalDialogStyle} className="no-select">
        <Modal.Header
          // closeButton
          // closeButton={isFromSignup}
          // closeButton={isFromSignup || isDoneSlide}
          closeButton={!isFromSignup || isDoneSlide}
          style={headerStyle}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "30px" }}
            className="no-select"
          >
            Quick tour
          </Modal.Title>

          <div
            className="logo-container no-select"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: isPhoneView ? "absolute" : "absolute", // Keep 'absolute' for both but adjust positions
              top: isPhoneView ? "auto" : "0px", // Apply 'top' only when not in phone view
              bottom: isPhoneView ? "-12px" : "auto", // Set 'bottom' when in phone view
              left: isPhoneView ? "49.5%" : "48.5%",
              // left: "48.5%",
              transform: "translateX(-50%)", // Center horizontally
              zIndex: 10,
              marginTop: isPhoneView ? "0" : "6px", // Adjust 'marginTop' for phone view
            }}
          >
            <img
              src={logoIcon}
              style={{
                height: isPhoneView ? "29px" : "34px",
                marginTop: "-3px",
              }}
              alt="Logo Icon"
              className="logo-icon"
            />
            <img
              src={logoShowcase}
              style={{
                height: isPhoneView ? "35px" : "50px",
                marginTop: "-3px",
                marginLeft: "-4px",
              }}
              alt="Logo Showcase"
              className="logo-showcase"
            />
          </div>
        </Modal.Header>

        <Modal.Body
          style={{
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {currentSlide === 0 || currentSlide === slides.length - 1 ? (
            <div style={centeredContainerStyle}>
              <div style={centeredContentStyle}>
                {slides[currentSlide].content}
              </div>
            </div>
          ) : (
            <BodyContainer>
              <LeftColumn>{slides[currentSlide].content}</LeftColumn>
              <RightColumn>
                {slides[currentSlide].video ? slides[currentSlide].video : null}
              </RightColumn>
            </BodyContainer>
          )}
        </Modal.Body>

        <div style={boxStyle}>
          {/* <div style={arrowContainerStyle}>
           
            <IconButton
              onClick={handlePrevious}
              disabled={currentSlide === 0 || !canNavigate}
              aria-label="Previous Slide"
              style={
                currentSlide === 0 || !canNavigate
                  ? { ...arrowStyle, ...disabledArrowStyle }
                  : arrowStyle
              }
            >
              <BsArrowLeft />
            </IconButton>

            {isFromSignup && (
              <div
                style={{
                  marginTop: "-5px",
                  position: "absolute",
                  zIndex: "20",
                  borderRadius: "50%",
                }}
              >
                {showProgress ? (
                  <PieChart
                    key={currentSlide} // Reset PieChart on slide change
                    duration={6000} // 6 seconds
                    size={30}
                    color="#7695FF"
                  />
                ) : (
                  // Render a filled circle when not showing progress
                  <PieChart
                    duration={0} // No animation
                    size={30}
                    color="#7695FF"
                    completed={true} // Assuming your PieChart can handle a 'completed' prop
                  />
                )}
              </div>
            )}

          
            <IconButton
              onClick={handleNext}
              disabled={currentSlide === slides.length - 1 || !canNavigate}
              aria-label="Next Slide"
              style={
                currentSlide === slides.length - 1 || !canNavigate
                  ? { ...arrowStyle, ...disabledArrowStyle }
                  : arrowStyle
              }
            >
              <BsArrowRight />
            </IconButton>
          </div> */}
          <div className="arrow-styling" style={arrowContainerStyle}>
            {/* <ArrowIconButton
              direction="left"
              onClick={handlePrevious}
              disabled={currentSlide === 0 || !canNavigate}
            />

            {isFromSignup && (
              <div
                style={{
                  marginTop: "-5px",
                  position: "absolute",
                  zIndex: "20",
                  borderRadius: "50%",
                }}
              >
           
                {showProgress ? (
                  <PieChart
                    key={currentSlide} // Reset PieChart on slide change
                    duration={6000} // 6 seconds
                    size={30}
                    color="#7695FF"
                  />
                ) : (
                  // Render a filled circle when not showing progress
                  <PieChart
                    duration={0} // No animation
                    size={30}
                    color="#7695FF"
                    completed={true} // Assuming your PieChart can handle a 'completed' prop
                  />
                )}
              </div>
            )}

        
            <ArrowIconButton
              direction="right"
              onClick={handleNext}
              disabled={currentSlide === slides.length - 1 || !canNavigate}
            /> */}
            <ArrowIconButton
              direction="left"
              onClick={handlePrevious}
              disabled={currentSlide === 0}
              currentSlide={currentSlide}
              isFromSignup={isFromSignup}
              slidesLength={slides.length}
              visitedSlidesRef={visitedSlidesRef}
            />

            <ArrowIconButton
              direction="right"
              onClick={handleNext}
              disabled={currentSlide === slides.length - 1}
              currentSlide={currentSlide}
              isFromSignup={isFromSignup}
              slidesLength={slides.length}
              visitedSlidesRef={visitedSlidesRef}
            />
          </div>
          <div style={{ marginBottom: "10px" }}></div>
          <Box sx={{ width: "100%" }}>
            {!isPhoneView && (
              <Stepper
                activeStep={currentSlide}
                alternativeLabel
                connector={<CustomStepConnector />}
              >
                {slides.map((slide, index) => (
                  <Step key={index}>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: index <= currentSlide ? "#7695FF" : "gray", // Customize the icon color
                        },
                      }}
                      sx={{
                        "& .MuiStepLabel-label": {
                          color: index === currentSlide ? "black" : "gray", // Customize the text color
                          fontWeight:
                            index === currentSlide ? "bold" : "normal", // Optional: Make the active step label bold
                          fontFamily: "Raleway, sans-serif", // Add this line
                        },
                        "& .MuiStepLabel-labelContainer": {
                          marginLeft: "4px", // Adjust this value to reduce the gap
                          marginRight: "4px", // Adjust this value to reduce the gap
                          marginTop: "-11px", // Adjust this value to reduce the gap
                        },
                      }}
                    >
                      {slide.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
          </Box>
        </div>
      </div>
    </Modal>
  );
}

export default TutorialModal;
