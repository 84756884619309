import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import "../css/OtherUserPage.css"; // You might want to create a separate CSS for this if needed
import PannableBox from "../components/PannableBox";
import NavBar_2 from "../components/NavBar_2";

const OtherUserPage = (props) => {
  const navigate = useNavigate();
  const { urlUsername } = useParams(); // Extract username from the route params
  const [collections, setCollections] = useState([]);
  const [artefacts, setArtefacts] = useState([]);

  // Separate states for low connection indicators
  const [isLowConnectionCollections, setIsLowConnectionCollections] =
    useState(false);
  const [isLowConnectionUserDetails, setIsLowConnectionUserDetails] =
    useState(false);

  const [userFullName, setUserFullName] = useState(""); // Add this state

  const [artefactData, setArtefactData] = useState({
    // Add this state
    artefactName: "",
    imageURL: "",
    type: "",
    // websiteURL: "",
  });

  function ConnectionIndicator({ type }) {
    const isCollections = type === "collections";
    const isUserDetails = type === "userDetails";
    // Only show if the specific connection is low
    if (isCollections && !isLowConnectionCollections) return null;
    if (isUserDetails && !isLowConnectionUserDetails) return null;

    return (
      <div
        className="low-connection"
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          color: "orange",
          marginLeft: "15px",
          marginTop: "15px",
          zIndex: "1000",
        }}
      >
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            backgroundColor: "orange",
            marginRight: "3px",
            marginTop: "-1px",
          }}
        ></div>
        <span>Low connection</span>
      </div>
    );
  }

  const fetchCollections = async (retries = 3, delay = 1000) => {
    let attempts = 0;
    let hasShownLowConnection = false;

    // Set a timer to trigger the low connection indicator if fetching takes longer than 5 seconds
    const lowConnectionTimer = setTimeout(() => {
      setIsLowConnectionCollections(true);
      hasShownLowConnection = true;
    }, 5000);

    while (attempts < retries) {
      try {
        const response = await fetch(
          `/api/user/${urlUsername}/collection/root`,
          {
            method: "GET",
            credentials: "same-origin",
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            console.error(
              "Collection not found (possibly private), navigating back."
            );
            navigate("/");
            clearTimeout(lowConnectionTimer);
            setIsLowConnectionCollections(false); // Ensure low connection is off
            return;
          }
          throw new Error("Network response was not ok");
        }

        const j = await response.json();
        setCollections(j.data);
        setArtefacts([]);

        clearTimeout(lowConnectionTimer); // Clear the timer if fetch completes within 5 seconds
        setIsLowConnectionCollections(false); // Ensure low connection is turned off when data is successfully loaded
        return; // exit function
      } catch (error) {
        attempts++;
        console.error(
          `Error fetching collections, attempt ${attempts}:`,
          error
        );

        if (attempts === 1 && !hasShownLowConnection) {
          setIsLowConnectionCollections(true);
          hasShownLowConnection = true;
        }

        if (attempts >= retries) {
          console.error("Max retries reached for collections. Skipping.");
          clearTimeout(lowConnectionTimer);
          setIsLowConnectionCollections(false); // Ensure low connection is off after max retries
          return;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  const fetchUserDetails = async (retries = 3, delay = 1000) => {
    let attempts = 0;
    let hasShownLowConnection = false;

    // Set a timer to trigger the low connection indicator if fetching takes longer than 5 seconds
    const lowConnectionTimer = setTimeout(() => {
      setIsLowConnectionUserDetails(true);
      hasShownLowConnection = true;
    }, 5000);

    while (attempts < retries) {
      try {
        const response = await fetch(`/api/user/${urlUsername}/details`, {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        });

        if (!response.ok) {
          if (response.status === 404) {
            console.error("User details not found, navigating back.");
            navigate("/");
            clearTimeout(lowConnectionTimer);
            setIsLowConnectionUserDetails(false); // Ensure low connection is off
            return;
          }
          throw new Error("Failed to fetch user details.");
        }

        const data = await response.json();
        console.log("Fetched user details:", data);
        setUserFullName(data.full_name);
        props.setLoggedInFullname(data.full_name);

        clearTimeout(lowConnectionTimer);
        setIsLowConnectionUserDetails(false);
        return; // exit function
      } catch (error) {
        attempts++;
        console.error(
          `Error fetching user details, attempt ${attempts}:`,
          error
        );

        if (attempts === 1 && !hasShownLowConnection) {
          setIsLowConnectionUserDetails(true);
          hasShownLowConnection = true;
        }

        if (attempts >= retries) {
          console.error("Max retries reached for user details. Skipping.");
          clearTimeout(lowConnectionTimer);
          setIsLowConnectionUserDetails(false);
          return;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (props.loggedIn === false) {
      navigate("/");
    } else {
      fetchCollections();
      fetchUserDetails();
    }

    return () => {
      isMounted = false;
      // Optionally, you can clear any pending timers here if you keep references to them
    };
  }, [props.loggedIn, urlUsername]); // Dependency array includes username

  return (
    <div className="mypage-contents">
      {/* Show connection indicators separately */}
      <ConnectionIndicator type="collections" />
      <ConnectionIndicator type="userDetails" />

      <div style={{ position: "relative", zIndex: 2 }}>
        <NavBar_2
          currentCollection={props.currentCollection}
          setLoggedIn={props.setLoggedIn}
          setLoggedInUsername={props.setLoggedInUsername}
          handleCreateButtonClick={props.handleCreateButtonClick}
          collectionData={null}
          setCollectionData={null}
          artefactData={null}
          setArtefactData={null}
          placeArtefactsTrigger={props.placeArtefactsTrigger}
          setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
          isScreenCentered={props.isScreenCentered}
          centerScreenHandler={props.centerScreenHandler}
          og_username={props.og_username}
          setOg_username={props.setOg_username}
          loggedInFullname={props.loggedInFullname}
          setLoggedInFullname={props.setLoggedInFullname}
        />
      </div>
      <div style={{ position: "relative", zIndex: 1 }}>
        <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
          <PannableBox
            collections={collections}
            placingMode={props.placingMode}
            setPlacingMode={props.setPlacingMode}
            getter={props.submittedForm}
            setter={props.setSubmittedForm}
            currentCollection={props.currentCollection}
            collectionData={{ collectionName: "", collectionId: "" }}
            artefactData={
              artefactData || {
                artefactName: "",
                imageURL: "",
                type: "",
                websiteURL: "",
              }
            }
            setArtefactData={setArtefactData}
            artefacts={artefacts}
            setArtefacts={setArtefacts}
            setCollections={setCollections}
            placeArtefactsTrigger={props.placeArtefactsTrigger}
            setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
            centerScreen={props.centerScreen}
            setCenterScreen={props.setCenterScreen}
            setIsScreenCentered={props.setIsScreenCentered}
            loggedInUsername={urlUsername}
            otherUser={urlUsername}
            og_username={props.og_username}
            setDroppedImageFile={null}
            loggedInFullname={userFullName}
            setLoggedInFullname={props.setLoggedInFullname}
            sharelinkFullname={null}
            setSharelinkFullname={null}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherUserPage;
